import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import {
  createMigrate,
  persistCombineReducers,
  persistStore,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";
import { setStore } from "../services/BaseApi";
import { documentSliceApi } from "./Documents/documentSlice";
import { candidateMeetingApi } from "./Hire/candidateMeetingApi";
import { hireApi } from "./Hire/hireApi";
import { interviewQuestionsApi } from "./Hire/interviewQuestionSlice";
import { jobRequestApi } from "./Hire/jobRequestApi";
import { mandatoryQuestionsApi } from "./Hire/mandatoryQuestionsSlice";
import migrations from "./migrations";
import reducers from "./reducers";
import rootSaga from "./sagas";
import { TaskApi } from "./Task/TaskApi";
import { userApi } from "./User/userApi";

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: "root",
  storage: storage,
  version: 2,
  migrate: createMigrate(migrations, { debug: false }),
  blacklist: [
    TaskApi.reducerPath,
    userApi.reducerPath,
    jobRequestApi.reducerPath,
    hireApi.reducerPath,
    documentSliceApi.reducerPath,
  ],
};
const persistedReducer = persistCombineReducers(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware()
      .concat(interviewQuestionsApi.middleware)
      .concat(mandatoryQuestionsApi.middleware)
      .concat(documentSliceApi.middleware)
      .concat(hireApi.middleware)
      .concat(userApi.middleware)
      .concat(TaskApi.middleware)
      .concat(candidateMeetingApi.middleware)
      .concat(jobRequestApi.middleware)
      .concat(sagaMiddleware);
  },
});

const persistedStore = persistStore(store);
setupListeners(store.dispatch);
setStore(store);
sagaMiddleware.run(rootSaga);

// Get the type of our store variable
export type AppStore = typeof store;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppStore["getState"]>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = AppStore["dispatch"];

export { persistedStore, store };
