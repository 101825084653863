import { JobRequest } from "../../../redux/Hire/jobRequestApi";
import AccordionTable from "../../../shared/components/AccordionTable";
import { getHeadersFromStage } from "../constants";
import JobRequestAccordionDetails from "./JobRequestAccordionDetails";
import JobRequestSummary from "./JobRequestSummary";

const JobRequestTable: React.FC<{
  jobRequestList: JobRequest[];
  pagination?: {
    pageSize: number;
    pageNumber: number;
    totalRowCount: number;
    handlePageChange: (param: number) => void;
    handlePageSizeChange: (param: number) => void;
  };
  isLoading: boolean;
  requestType: number;
}> = ({ jobRequestList, pagination, isLoading, requestType }) => {
  return (
    <AccordionTable
      headers={getHeadersFromStage[requestType]}
      dataRow={jobRequestList}
      rowDetail={(data) => <JobRequestSummary jobRequest={data} />}
      rowDetailExpand={(data) => (
        <JobRequestAccordionDetails jobRequest={data} />
      )}
      pagination={pagination}
      isLoading={isLoading}
    />
  );
};

export default JobRequestTable;
