import { Box, Button, Collapse, Grid, Typography } from "@mui/material";
import { uuid4 } from "@sentry/core";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useGetInterviewQuestionsQuery } from "../../../redux/Hire/hireApi";
import FormikInput from "../../../shared/components/FormikInput";
import { JobReqQuestionSkeleton } from "./JobReqSkeleton";
import { QuestionCategories } from "./types";
const Question = ({ question, number }) => {
  const [showAnswer, setShowAnswer] = useState(false);
  return (
    <Box sx={{ borderBottom: 1, borderColor: "#CCD3D5", py: 1 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {number}. {question}{" "}
        <Button onClick={() => setShowAnswer(!showAnswer)}>
          {!showAnswer ? "Hint Answer" : "Close Answer"}
        </Button>
      </Box>
      <Collapse in={showAnswer}>
        <Box sx={{ px: 2, py: 1, my: 1 }}>Answer</Box>
      </Collapse>
    </Box>
  );
};

const DetailInterviewQuestionPage = ({ internalCode }) => {
  const [questionCategory, setQuestionCategory] = useState<QuestionCategories>(
    QuestionCategories.TechnicalSkills,
  );
  const { data, isLoading } = useGetInterviewQuestionsQuery({
    internalCode: internalCode,
  });
  const questions = data?.data;

  if (isLoading) return <JobReqQuestionSkeleton />;

  if (!questions)
    return (
      <Grid px={2}>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            justifyContent: "space-around",
            alignItems: "center",
            width: "100%",
            borderBottom: 1,
            paddingBottom: 1,
          }}
        >
          <Typography variant={"subtitle1"} flexGrow={1}>
            Required interview questions
          </Typography>
        </Box>
        <Grid width={"100%"} py={2}>
          <Typography variant={"caption"}>No Data Available</Typography>
        </Grid>
      </Grid>
    );

  return (
    <Box sx={{ p: 2 }}>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          justifyContent: "space-around",
          alignItems: "center",
          width: "100%",
          borderBottom: 1,
          paddingBottom: 1,
        }}
      >
        <Box sx={{ flexGrow: 1 }}>Required interview questions</Box>
        {Object.values(QuestionCategories).map((category) => (
          <Button
            variant="text"
            sx={{ flexGrow: 1, color: "#000" }}
            key={category}
            disabled={questionCategory === category}
            onClick={() => setQuestionCategory(category)}
          >
            {category}
          </Button>
        ))}
      </Box>
      {questions.map((question, index) => (
        <Question
          question={question.question}
          number={index + 1}
          key={uuid4()}
        />
      ))}
      <Formik
        initialValues={{
          feedbackSummary: "",
        }}
        onSubmit={(values) => {
          alert(JSON.stringify(values, null, 2));
        }}
      >
        {(formikProps) => (
          <Form>
            <FormikInput
              label="Feedback summary"
              name="feedbackSummary"
              formikProps={formikProps}
              options={undefined}
              multiline
              fullWidth
            />
            <Box sx={{ display: "flex", justifyContent: "end" }}>
              <Button variant="contained" type="submit">
                Save
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default DetailInterviewQuestionPage;
