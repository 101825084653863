import { Box, Skeleton } from "@mui/material";

const AccordionTableSkeleton = () => {
  return (
    <Box>
      <Skeleton
        animation="wave"
        variant="rounded"
        height={104}
        sx={{ my: 2 }}
      />
      <Skeleton
        animation="wave"
        variant="rounded"
        height={104}
        sx={{ my: 2 }}
      />
      <Skeleton
        animation="wave"
        variant="rounded"
        height={104}
        sx={{ my: 2 }}
      />
      <Skeleton
        animation="wave"
        variant="rounded"
        height={104}
        sx={{ my: 2 }}
      />
    </Box>
  );
};

export default AccordionTableSkeleton;
