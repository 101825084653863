import { Box } from "@mui/material";
import { FC, PropsWithChildren } from "react";
import TimezoneLine from "./TimezoneLine";

type Props = {};

const TimeslotGutterWrapper: FC<PropsWithChildren<Props>> = ({ children }) => {
  return (
    <Box
      display={"flex"}
      sx={{
        height: "100%",
      }}
    >
      <TimezoneLine unavailabilityHour={4.5} uncertainHour={2} zone="IST" />
      <TimezoneLine unavailabilityHour={2} uncertainHour={2} zone="PHT" />
      {children}
    </Box>
  );
};

export default TimeslotGutterWrapper;
