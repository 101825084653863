import React, { useEffect } from "react";
// import { useLocation, useNavigate } from "react-router-dom";
import { useStyles } from "./styles";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { patonaAdmin } from "../../../Constants";
import { useGetDocumentsQuery } from "../../../redux/Documents/documentSlice";
import TextSkeleton from "../../../shared/components/Skeletons/TextSkeleton";
import { STRINGS } from "../../../Strings";
import { userAccess } from "../../../utils/CommonFunction";
import { usePermissions } from "../../../utils/permission";
import UploadDocument from "../../HireV3/UploadDocument";
import { getList } from "../../Users/User.helper";
import { getCompanyDetails } from "./Profile.helper";

type ProfileDetailsProps = {
  editCompany?: (boolean) => void;
};

const ProfileDetails: React.FunctionComponent<ProfileDetailsProps> = (
  props,
) => {
  const { editCompany = () => null } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const { Profile_EditProfile } = usePermissions();
  // const navigate = useNavigate();
  // const location = useLocation();

  const { isUserListLoading, userList } = useSelector(
    (state) =>
      //@ts-ignore
      state.user,
  );

  useEffect(() => {
    getList(dispatch, "getActiveUsers", 1, patonaAdmin, 50);
    getCompanyDetails(dispatch);
  }, []);

  const { isLoading, company } = useSelector(
    //@ts-ignore
    (state) => state.company,
  );

  const {
    data,
    isLoading: isLoadingDoc,
    isSuccess,
  } = useGetDocumentsQuery(
    {
      ownerId: company.id,
      title: "Policy",
    },
    {
      skip: !company.id,
    },
  );
  const accountManager = userList.find(
    (user) => user.userId === company?.accountManagerId,
  );
  const { isTeamMember } = userAccess();

  return (
    <>
      <Box className={classes.display_card}>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={6}>
            <Typography className={classes.company_title_text}>
              {STRINGS.CompanyName}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {isLoading ? (
              <TextSkeleton />
            ) : (
              <Typography variant="body2"> {company?.name}</Typography>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={6}>
            <Typography className={classes.company_title_text}>
              {STRINGS.CompanyAddress}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {isLoading ? (
              <TextSkeleton />
            ) : (
              <Typography variant="body2">{company?.address}</Typography>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={6}>
            <Typography className={classes.company_title_text}>
              {STRINGS.Website}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {isLoading ? (
              <TextSkeleton />
            ) : (
              <Typography variant="body2">{company?.webSite}</Typography>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={6}>
            <Typography className={classes.company_title_text}>
              {STRINGS.ACN}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {isLoading ? (
              <TextSkeleton />
            ) : (
              <Typography variant="body2">{company?.acn}</Typography>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={6}>
            <Typography className={classes.company_title_text}>
              Account Manager
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {isUserListLoading || isLoading ? (
              <TextSkeleton />
            ) : (
              <Typography variant="body2">
                {accountManager
                  ? `${accountManager?.userName} (${accountManager?.email})`
                  : null}
              </Typography>
            )}
          </Grid>
        </Grid>

        <Grid container spacing={2} mb={4}>
          <Grid item xs={12}>
            <Typography className={classes.company_title_text}>
              {STRINGS.AboutCompany}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {isLoading ? (
              <TextSkeleton />
            ) : (
              <Typography variant="body2">{company?.description}</Typography>
            )}
          </Grid>
        </Grid>
        {
          <Grid item xs={6}>
            {Profile_EditProfile && (
              <UploadDocument
                documentName={"Company Policy"}
                type={"client"}
                url={data?.data[0]?.url}
                isInline
                userName={company?.name}
                sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                }}
                id={company.id}
                roleCanView="TMF_Admin,Teamified_Admin,Employer,Employer_Hiring_Manager,Employer_Finance,Client_Admin,Patona_Admin,Team_Member"
                roleCanEdit="TMF_Admin"
                roleCanDelete="TMF_Admin,Patona_Admin,Teamified_Admin,Client_Admin,Employer"
              ></UploadDocument>
            )}
          </Grid>
        }
        <Box display="flex" justifyContent="flex-end">
          {Profile_EditProfile && (
            <Button
              type="submit"
              variant="outlined"
              onClick={() => editCompany(true)}
              sx={{ mt: 2 }}
            >
              {STRINGS.Edit}
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ProfileDetails;
