import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Button, Collapse, Grid, Skeleton, Typography } from "@mui/material";
import { memo, useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { useGetJobRequestByIdQuery } from "../../redux/Hire/jobRequestApi";
import { STRINGS } from "../../Strings";
import AddNewCandidateForm from "./AddNewCandidateForm";
import { styles } from "./HireStyle";
import JobDetails from "./JobDetails";

const CandidateListHeader = ({ jobId }) => {
  const [openDetails, setOpenDetails] = useState(false);
  const [showAddCandidateForm, setShowAddCandidateForm] = useState(false);

  const { data, isLoading, isFetching } = useGetJobRequestByIdQuery({
    jobRequestId: parseInt(jobId, 10),
  });

  const classes = styles();

  const closeAddCandidateForm = useCallback(() => {
    setShowAddCandidateForm(false);
  }, []);
  const openAddCandidateForm = useCallback(() => {
    setShowAddCandidateForm(true);
  }, []);

  return (
    <>
      <JobDetails
        hireJobsDetail={data?.data}
        showModal={openDetails}
        closeModal={() => setOpenDetails(false)}
        isHireDetailLoading={isLoading || isFetching}
      />
      <Grid
        container
        xs={12}
        md={12}
        item
        direction={"row"}
        alignItems={"center"}
        mb={2}
      >
        <Grid md={8} container item direction={"row"} alignItems={"center"}>
          <Link className={classes.back_icon2} to="/job-request">
            <ArrowBackIosNewIcon height={20} />
          </Link>

          {isLoading || isFetching ? (
            <Skeleton
              variant="text"
              width={"150px"}
              sx={{ fontSize: "1rem" }}
            ></Skeleton>
          ) : (
            <>
              <Typography
                variant="body1"
                component="div"
                sx={{
                  display: {
                    xs: "none",
                    md: "block",
                  },
                }}
              >
                {data?.data.title}
              </Typography>
              <Typography
                variant="body2"
                component="div"
                sx={{
                  ml: "1rem",
                  display: {
                    xs: "none",
                    md: "block",
                  },
                }}
              >
                {`(${STRINGS.RequestNumber.params({
                  number: data?.data.workableInternalCode || "",
                })})`}
              </Typography>
            </>
          )}
        </Grid>
        <Grid
          container
          item
          md={4}
          justifyContent={"flex-end"}
          flex={1}
          gap={2}
        >
          {isLoading || isFetching ? (
            <Skeleton
              variant="text"
              width={"150px"}
              sx={{ fontSize: "1rem" }}
            ></Skeleton>
          ) : (
            <>
              <Button
                variant="text"
                className="header-button"
                onClick={() => {
                  setOpenDetails(true);
                }}
              >
                Request Details
              </Button>
              <Button
                variant="outlined"
                onClick={openAddCandidateForm}
                disabled={showAddCandidateForm}
              >
                Add new candidate
              </Button>
            </>
          )}
        </Grid>
      </Grid>
      <Collapse
        sx={{
          width: "100%",
          mb: 2,
        }}
        in={showAddCandidateForm}
        easing={{ enter: "ease-in" }}
      >
        <AddNewCandidateForm
          closeForm={closeAddCandidateForm}
          jobId={data?.data.jobId.toString() || ""}
          clientName={data?.data.client || ""}
          jobTitle={data?.data.title || ""}
          workableInternalCode={data?.data.workableInternalCode || ""}
        />
      </Collapse>
    </>
  );
};

export default memo(CandidateListHeader);
