import { FileUploadOutlined } from "@mui/icons-material";
import { Box, CircularProgress, IconButton, TextField } from "@mui/material";
import {
  ChangeEvent,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  useGenerateUploadLinkMutation,
  useUploadToAzureMutation,
} from "../../redux/Documents/documentSlice";
import { ClearInputHandle } from "../HireV3/AddNewCandidateForm";

const UploadResume = forwardRef<ClearInputHandle, any>(
  ({ id, documentName = "Resume", setTextCVLink }, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [getLink, { data, isSuccess, isLoading }] =
      useGenerateUploadLinkMutation();
    const [files, setFiles] = useState<File | null>(null);
    const [
      uploadFile,
      { isLoading: uploadIsLoading, isSuccess: isUploadSuccess, reset },
    ] = useUploadToAzureMutation();

    const clearInput = useCallback(() => {
      setFiles(null);
      if (inputRef && inputRef.current && inputRef.current.value)
        inputRef.current.value = "";
      setTextCVLink("", "");
    }, []);

    useImperativeHandle(ref, () => ({
      clearInput,
    }));

    const generateLink = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files?.length) {
          getLink({
            fileName: e.target.files[0].name,
            id,
          });
          setFiles(e.target.files[0]);
        }
      },
      [getLink, id],
    );

    const uploadToAzure = useCallback(
      (link: string) => {
        if (files) {
          uploadFile({
            link,
            file: files,
            fileName: documentName,
            id: id,
          });
        }
      },
      [documentName, files, id, uploadFile],
    );

    useEffect(() => {
      if (isSuccess && data) {
        uploadToAzure(data.link);
      }
    }, [isSuccess, data, uploadToAzure]);

    useEffect(() => {
      if (isUploadSuccess && data) {
        setTextCVLink(data.link, files?.type);
        reset();
      }
    }, [data, files?.type, isUploadSuccess, reset, setTextCVLink]);

    return (
      <TextField
        variant="outlined"
        type="text"
        label={files ? "" : "Upload Resume*"}
        fullWidth
        value={files ? files.name : ""}
        disabled
        InputProps={{
          endAdornment:
            uploadIsLoading || isLoading ? (
              <Box width={24} height={24}>
                <CircularProgress size={24} color="primary" />
              </Box>
            ) : (
              <IconButton component="label">
                <FileUploadOutlined />
                <input
                  accept=".docx,.doc,.txt,application/msword,text/plain, application/pdf"
                  style={{ display: "none" }}
                  type="file"
                  hidden
                  onChange={generateLink}
                />
              </IconButton>
            ),
        }}
      />
    );
  },
);

export default memo(UploadResume);
