import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import {
  Box,
  Button,
  Grid,
  Modal,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import { chatGptJobError } from "../../../redux/Hire/hireSlice";
import FormikInput from "../../../shared/components/FormikInput";
import TableSkeleton from "../../../shared/components/Skeletons/TableSkeleton";
import { generateChatGptJobDescription } from "../Hire.helper";

const GenerateJobDescriptionModal = ({
  formikProps,
  showAiGenerateJob,
  setShowAiGenerateJob,
}) => {
  const isMobile = useMediaQuery(`(max-width: 760px)`);
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "70%" : "50%",
    bgcolor: "background.paper",
    border: "0px solid #000",
    p: 4,
    boxShadow: "1px 4px 20px rgba(132, 132, 132, 0.2)",
    borderRadius: "10px",
  };

  const dispatch = useDispatch();

  const [inputSkills, setInputSkills] = React.useState<any>("");

  const [selectedAiIndex, setSelectedAiIndex] = React.useState<number>(0);

  const { chatGptJobPending, chatGptJobData } = useSelector(
    //@ts-ignore
    (state) => state.hire,
  );

  return (
    <Modal
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      open={showAiGenerateJob}
      onClose={() => {
        setShowAiGenerateJob(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h5"
          component="h2"
          mb={1}
          sx={{ textAlign: "center" }}
        >
          Job description
        </Typography>
        <Typography
          id="modal-modal-title"
          variant="body2"
          component="h2"
          sx={{ textAlign: "center", mb: 4 }}
        >
          We have crafted job description templates as per provided information.
          Select any from many and even you can add any more specification in
          the requirement box .
        </Typography>
        {chatGptJobPending && <TableSkeleton rowCount={[1, 2, 3, 4, 5]} />}

        {!chatGptJobPending && (
          <Grid container xs={12}>
            <Grid
              container
              xs={12}
              spacing={2}
              sx={{ backgroundColor: "#f7f9ff", ml: 1 }}
            >
              <Grid item xs={12} sx={{ mb: 2 }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Grid item xs={10}>
                    <Typography variant="body2">
                      {"Job describe template"}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={1.5}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignContent: "center",
                      alignItems: "center",
                      pr: 1,
                    }}
                  >
                    <Typography variant="body2">
                      {`${selectedAiIndex + 1}/${chatGptJobData?.length}`}
                    </Typography>{" "}
                    <ArrowBackIosOutlinedIcon
                      onClick={() => {
                        if (selectedAiIndex > 0) {
                          setSelectedAiIndex(
                            (selectedAiIndex) => selectedAiIndex - 1,
                          );
                        }
                      }}
                      sx={{
                        height: 15,
                        cursor: "pointer",
                        color: selectedAiIndex > 0 ? "#000000" : "#aeaeae",
                      }}
                    />
                    <Typography
                      variant="body2"
                      sx={{
                        height: 20,
                        width: 20,
                        borderRadius: 10,
                        backgroundColor: "#ffffff",
                        textAlign: "center",
                      }}
                    >
                      {`${selectedAiIndex + 1}`}
                    </Typography>
                    <ArrowForwardIosOutlinedIcon
                      onClick={() => {
                        if (selectedAiIndex !== chatGptJobData?.length - 1) {
                          setSelectedAiIndex(
                            (selectedAiIndex) => selectedAiIndex + 1,
                          );
                        }
                      }}
                      sx={{
                        height: 15,
                        cursor: "pointer",
                        color:
                          selectedAiIndex !== chatGptJobData?.length - 1
                            ? "#000000"
                            : "#aeaeae",
                      }}
                    />
                  </Grid>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ maxHeight: 300, overflow: "scroll", p: 5, mb: 2 }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      chatGptJobData[selectedAiIndex]?.content.replaceAll(
                        "\n",
                        "<br/>",
                      ) || "",
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              xs={12}
              sx={{ justifyContent: "space-between", display: "flex" }}
            >
              <Grid item xs={6}>
                <FormikInput
                  name="skills"
                  margin="normal"
                  label="Skills"
                  formikProps={formikProps}
                  options={undefined}
                  variant="outlined"
                  autoComplete="off"
                  inputProps={{
                    style: {
                      minHeight: 10,
                      maxHeight: 50,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <FormikInput
                  name="noticePeriod"
                  margin="normal"
                  label="Notice period"
                  formikProps={formikProps}
                  options={undefined}
                  variant="outlined"
                  autoComplete="off"
                  inputProps={{
                    style: {
                      minHeight: 10,
                      maxHeight: 50,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={2} sx={{ mt: 1, textAlign: "right" }}>
                <Button
                  onClick={() => {
                    const payload = {
                      jobTitle: formikProps?.values?.title,
                      clientId: formikProps?.values?.clientId,
                      skills: formikProps?.values?.skills,
                      expLevel: formikProps?.values?.experienceLevel,
                      employmentType: formikProps?.values?.employeeType,
                      salaryFrom: formikProps?.values?.expectedSalaryFrom,
                      salaryTo: formikProps?.values?.expectedSalaryTo,
                      location: formikProps?.values?.location,
                      noticePeriod: formikProps?.values?.noticePeriod,
                    };
                    generateChatGptJobDescription(payload, dispatch);
                  }}
                  variant="outlined"
                  sx={{ mt: 2 }}
                >
                  {"Generate"}
                </Button>
              </Grid>
            </Grid>
            <Grid container xs={12}>
              <Grid item xs={9}>
                <Typography></Typography>
              </Grid>
              <Grid item xs={3} sx={{ mt: 1, textAlign: "right" }}>
                <Button
                  onClick={() => setShowAiGenerateJob(false)}
                  variant="text"
                  sx={{ mt: 2 }}
                >
                  {"Cancel"}
                </Button>
                <Button
                  onClick={() => {
                    const aiResponse = chatGptJobData[
                      selectedAiIndex
                    ]?.content.replaceAll("\n", "<br/>");
                    setShowAiGenerateJob(false);
                    formikProps.setFieldValue("aboutTheRole", aiResponse);
                    dispatch(chatGptJobError());
                  }}
                  variant="contained"
                  sx={{ mt: 2 }}
                >
                  {"Done"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Box>
    </Modal>
  );
};

export default GenerateJobDescriptionModal;
