import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../services/service";
const BASE_URL_INTERVIEW = process.env.REACT_APP_INTERVIEW_URL;

type Question = {
  subject: string;
  question: string;
  _id: string;
  interviewNote?: string;
};

type AddQuestionRequest = {
  interviewId: string;
  questions: Array<Omit<Question, "_id">>;
};

type InterviewRequest = {
  jobId: string;
  candidateId: string;
};

type InterviewMeeting = {
  createdAt: number;
  interviewerEmail: string;
  link: string;
  meetingId: string;
  summaryGenerated: string;
  summaryOverview: string;
  summaryShort: string;
  firefliesSummaryStatus: "PENDING" | "COMPLETED";
  transcript: Array<{
    speaker: string;
    text: string;
  }>;
};

export type InterviewResponse = {
  candidateId: string;
  candidateResume: string;
  createdAt: string;
  draftSummary: string;
  jobDescription: string;
  meetings: Array<InterviewMeeting>;
  questions: Array<Question>;
  jobId: string;
  updatedAt: string;
  _id: string;
};

type AddNoteRequest = {
  interviewId: string;
  questionId: string;
  interviewNote: string;
};

type AddDraftSummaryRequest = {
  interviewId: string;
};

type AddInterviewSummaryRequest = {
  interviewId: string;
  interviewSummary: string;
};

type QuestionnaireRequest = {
  jobId: string;
  candidateId: string;
};

export const interviewQuestionsApi = createApi({
  reducerPath: "interviewQuestion",
  baseQuery: axiosBaseQuery({
    baseUrl: BASE_URL_INTERVIEW + "interview/",
  }),
  endpoints: (builder) => ({
    questionnaire: builder.query<undefined, QuestionnaireRequest>({
      query: (request) => ({
        url: `questionnaire`,
        method: "POST",
        body: request,
      }),
    }),

    addQuestions: builder.mutation<InterviewResponse, AddQuestionRequest>({
      query: ({ interviewId, questions }) => ({
        url: `${interviewId}/add-question`,
        method: "POST",
        body: questions,
      }),
      onQueryStarted: async ({}, { dispatch, queryFulfilled }) => {
        try {
          const { data: interviewData } = await queryFulfilled;
          dispatch(
            interviewQuestionsApi.util.updateQueryData(
              "getInterview",
              {
                candidateId: interviewData.candidateId,
                jobId: interviewData.jobId,
              },
              (draft) => {
                Object.assign(draft, interviewData);
              },
            ),
          );
        } catch {}
      },
    }),
    getInterview: builder.query<InterviewResponse, InterviewRequest>({
      query: ({ candidateId, jobId }) => ({
        url: "",
        params: {
          candidateId,
          jobId,
        },

        method: "GET",
      }),
    }),
    addNote: builder.mutation<InterviewResponse, AddNoteRequest>({
      query: ({ questionId, interviewId, interviewNote }) => ({
        url: `${interviewId}/question/${questionId}`,
        method: "POST",
        body: {
          interviewNote,
        },
      }),
      onQueryStarted: async ({}, { dispatch, queryFulfilled }) => {
        try {
          const { data: interviewData } = await queryFulfilled;
          dispatch(
            interviewQuestionsApi.util.updateQueryData(
              "getInterview",
              {
                candidateId: interviewData.candidateId,
                jobId: interviewData.jobId,
              },
              (draft) => {
                Object.assign(draft, interviewData);
              },
            ),
          );
        } catch {}
      },
    }),
    addDraftSummary: builder.mutation<
      InterviewResponse,
      AddDraftSummaryRequest
    >({
      query: ({ interviewId }) => ({
        url: `/${interviewId}/draft-summary`,
        method: "POST",
        body: {},
      }),
    }),
    addSummary: builder.mutation<InterviewResponse, AddInterviewSummaryRequest>(
      {
        query: ({ interviewId, interviewSummary }) => ({
          url: `summary/${interviewId}`,
          method: "POST",
          body: {
            interviewSummary,
          },
        }),
        onQueryStarted: async ({}, { dispatch, queryFulfilled }) => {
          try {
            const { data: interviewData } = await queryFulfilled;
            dispatch(
              interviewQuestionsApi.util.updateQueryData(
                "getInterview",
                {
                  candidateId: interviewData.candidateId,
                  jobId: interviewData.jobId,
                },
                (draft) => {
                  Object.assign(draft, interviewData);
                },
              ),
            );
          } catch {}
        },
      },
    ),
  }),
});

export const {
  useAddQuestionsMutation,
  useGetInterviewQuery,
  useAddNoteMutation,
  useAddDraftSummaryMutation,
  useAddSummaryMutation,
  useQuestionnaireQuery,
} = interviewQuestionsApi;
