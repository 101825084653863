import { Box } from "@mui/material";
import { useState } from "react";
import { JobRequest } from "../../../redux/Hire/jobRequestApi";
import DetailBody from "./DetailBody";
import DetailHeader from "./DetailHeader";
import { JobRequestDetailPages } from "./types";

const JobRequestAccordionDetails: React.FC<{
  jobRequest: JobRequest;
}> = ({ jobRequest }) => {
  const [currentPage, setCurrentPage] = useState<JobRequestDetailPages>(
    JobRequestDetailPages.ViewRequest,
  );
  return (
    <Box>
      <DetailHeader
        currentPage={currentPage}
        pages={Object.values(JobRequestDetailPages)}
        setCurrentPage={setCurrentPage}
        jobRequest={jobRequest}
      />
      <DetailBody currentPage={currentPage} jobRequest={jobRequest} />
    </Box>
  );
};

export default JobRequestAccordionDetails;
