import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useEffect } from "react";
import { useAlert } from "react-alert";
import { useRescheduleSlotMutation } from "../../redux/Hire/hireApi";
import { CloseIcon } from "../../shared/components/Icons";
import { useSelector } from "react-redux";

const RescheduleModal = ({
  open,
  closeModal,
  interviewId,
  interviewerEmail,
  interviewerName,
  showError,
}) => {
  const [rescheduleSlot, rescheduleSlotMutating] = useRescheduleSlotMutation();
  const { userInfo } = useSelector(
    //@ts-ignore
    (state) => state.login,
  );
  
  const rescheduleClick = useCallback(() => {
    rescheduleSlot({
      interviewerId: interviewId,
      employerEmail: interviewerEmail,
      employerName: interviewerName,
      rescheduledBy: userInfo.userId
    });
  }, [interviewId, interviewerEmail, interviewerName, rescheduleSlot]);

  const alert = useAlert();
  useEffect(() => {
    if (rescheduleSlotMutating.isSuccess) {
      alert.success("Candidate will select a new slot");
      closeModal();
    }

    if (rescheduleSlotMutating.isError) {
      alert.error("Something went wrong while notifying the candidate");
      if (
        (rescheduleSlotMutating.error as any).data.message ===
        "Email sent to employer to add slots for the week."
      ) {
        showError?.();
      }
    }
  }, [alert, closeModal, rescheduleSlotMutating, showError]);

  return (
    <Dialog open={open} onClose={closeModal} maxWidth="xl">
      <DialogTitle
        fontSize={"32px"}
        justifyContent={"center"}
        display={"flex"}
        alignItems={"center"}
        flexDirection={"column"}
      >
        <Typography fontSize={"24px"} lineHeight={"32px"} fontWeight={700}>
          Reschedule interview call
        </Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={closeModal}
        sx={{
          position: "absolute",
          right: 16,
          top: 16,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        sx={{
          px: 6,
          py: 2,
          width: "50vw",
        }}
      >
        <Typography variant="body2" textAlign={"center"} px={4}>
          You have chosen to reschedule the call.
        </Typography>
        <Typography variant="body2" textAlign={"center"} px={4}>
          Candidate will choose a new time slot as per your given available time
          slots.
        </Typography>

        <Box
          display="flex"
          justifyContent="flex-end"
          sx={{ mt: 1, mb: 1 }}
          gap={2}
        >
          <Button variant="text" sx={{ mt: 2 }} onClick={closeModal}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            sx={{ mt: 2 }}
            onClick={rescheduleClick}
            disabled={rescheduleSlotMutating.isLoading}
          >
            {rescheduleSlotMutating.isLoading ? (
              <CircularProgress size={20}></CircularProgress>
            ) : (
              "Reschedule"
            )}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default RescheduleModal;
