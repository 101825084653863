import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { rowPerPage } from "../../Constants";
import {
  InterviewTime,
  TimeSlot,
} from "../../modules/HireV3/TimeslotBooking/types";

type HireSliceTypes = {
  hireJobsDetail: any;
  allWorkableClients: any;
  hireJobsList: any;
  hireCandidateList: any;
  isHireCandidateStagingLoading: any;
  hireCandidateStaging: any;
  isHireListLoading: any;
  isHireDetailLoading: any;
  isCandidateListLoading: any;
  moreCandidateListLoading: any;
  hireCandidateError: any;
  hireListError: any;
  isHireUpdated: any;
  hireUpdateLoading: any;
  isHirePaging: any;
  candidatePaging: any;
  hirePage: any;
  currentPage: any;
  totalJobs: any;
  totalCandidates: any;
  isHireUserAdded: any;
  hireAddingInProgress: any;
  updateMoreCandidateList: any;
  jobAddUpdateError: any;
  cancelJobError: any;
  isJobAdded: any;
  isCommentAdded: any;
  commentAddedError: any;
  pageSize: any;
  candidateDetail: any;
  candidateDetailPending: any;
  candidateDetailError: any;
  moveCandidate: any;
  moveCandidateError: any;
  disqualifiedCandidate: any;
  disqualifiedCandidateError: any;
  chatGptJobPending: any;
  chatGptJobData: any;
  availableTimeslots: TimeSlot[];
  interviewTime: number;
};

const initialState: HireSliceTypes = {
  hireJobsDetail: {},
  allWorkableClients: [],
  hireJobsList: [],
  hireCandidateList: { qualified: [], disQualified: [] },
  isHireCandidateStagingLoading: false,
  hireCandidateStaging: [],
  isHireListLoading: false,
  isHireDetailLoading: false,
  isCandidateListLoading: false,
  moreCandidateListLoading: false,
  hireCandidateError: "",
  hireListError: "",
  isHireUpdated: false,
  hireUpdateLoading: false,
  isHirePaging: false,
  candidatePaging: {
    qualified: { page: 0, isPaging: false },
    disQualified: { page: 0, isPaging: false },
  },
  hirePage: 0,
  currentPage: 0,
  totalJobs: 0,
  totalCandidates: { qualified: 0, disQualified: 0 },
  isHireUserAdded: false,
  hireAddingInProgress: false,
  updateMoreCandidateList: false,
  jobAddUpdateError: false,
  cancelJobError: false,
  isJobAdded: false,
  isCommentAdded: false,
  commentAddedError: false,
  pageSize: rowPerPage,
  candidateDetail: {},
  candidateDetailPending: false,
  candidateDetailError: false,
  moveCandidate: false,
  moveCandidateError: false,
  disqualifiedCandidate: false,
  disqualifiedCandidateError: false,
  chatGptJobPending: false,
  chatGptJobData: [],
  availableTimeslots: [],
  interviewTime: InterviewTime.MIN_30,
};

const hireSlice = createSlice({
  name: "hire",
  initialState,
  reducers: {
    chatGptJobPending: (state) => {
      state.chatGptJobPending = true;
    },
    chatGptJobSuccess: (state, { payload }) => {
      state.chatGptJobPending = false;
      state.chatGptJobData = payload;
    },
    chatGptJobError: (state) => {
      state.chatGptJobPending = false;
      state.chatGptJobData = [];
    },
    hireAdded: (state, { payload }) => {
      state.isHireUserAdded = payload;
    },
    hireAddedSuccessfully: (state) => {
      state.isHireUserAdded = false;
      state.hireAddingInProgress = false;
    },
    hireAddingInProgress: (state, { payload }) => {
      state.hireAddingInProgress = payload;
    },
    paging: (state, { payload }) => {
      state.isHirePaging = payload.isPaging;
      state.hirePage = payload.page;
      state.currentPage = payload.currentPage;
      state.pageSize = payload.pageSize;
    },
    candidatePaging: (state, { payload }) => {
      state.candidatePaging = payload;
    },
    jobCount: (state, { payload }) => {
      state.totalJobs = payload;
    },
    candidateCount: (state, { payload }) => {
      state.totalCandidates = payload;
    },
    getHireListPending: (state) => {
      state.isHireListLoading = true;
    },
    getHireListSuccess: (state, { payload }) => {
      state.isHireListLoading = false;
      state.hireJobsList = payload.filteredJobs;
      state.hireListError = "";
    },
    getHireListFail: (state, { payload }) => {
      state.isHireListLoading = false;
      state.hireJobsList = [];
      state.hireListError = payload;
    },
    clearHireList: (state) => {
      state.hireJobsList = [];
    },
    getCandidateListPending: (state) => {
      state.isCandidateListLoading = true;
    },
    moreCandidateListPending: (state) => {
      state.moreCandidateListLoading = true;
    },
    stopMoreCandidateListPending: (state) => {
      state.moreCandidateListLoading = false;
    },
    getCandidateListSuccess: (state, { payload }) => {
      state.hireCandidateList = payload;
      state.isCandidateListLoading = false;
      state.hireCandidateError = "";
    },
    getCandidateListFail: (state, { payload }) => {
      state.isCandidateListLoading = false;
      state.hireCandidateError = payload;
    },
    clearCandidateList: (state) => {
      state.hireCandidateList = { qualified: [], disQualified: [] };
    },
    getAllWorkableClients: (state, { payload }) => {
      state.allWorkableClients = payload;
    },
    clearAllWorkableClients: (state) => {
      state.allWorkableClients = [];
    },
    getHireDetailPending: (state) => {
      state.isHireDetailLoading = true;
    },
    getHireDetailSuccess: (state, { payload }) => {
      state.isHireDetailLoading = false;
      state.hireJobsDetail = payload;
    },
    getHireDetailFail: (state) => {
      state.isHireDetailLoading = false;
      state.hireJobsDetail = {};
    },
    getHireCandidateStaging: (state) => {
      state.isHireCandidateStagingLoading = true;
      state.hireCandidateStaging = [];
    },
    setHireCandidateStaging: (state, { payload }) => {
      state.isHireCandidateStagingLoading = false;
      state.hireCandidateStaging = payload;
    },
    clearHireCandidateStaging: (state) => {
      state.isHireCandidateStagingLoading = false;
      state.hireCandidateStaging = [];
    },
    updatedMoreCandidateList: (state, { payload }) => {
      state.updateMoreCandidateList = payload;
    },
    jobAddedUpdateError: (state, { payload }) => {
      state.jobAddUpdateError = payload;
    },
    jobAdded: (state, { payload }) => {
      state.isJobAdded = payload;
    },
    commentAdded: (state, { payload }) => {
      state.isCommentAdded = payload;
    },
    commentAddedSuccessfully: (state) => {
      state.isCommentAdded = false;
    },
    commentAddedError: (state, { payload }) => {
      state.commentAddedError = payload;
    },
    clearCommentAddedError: (state) => {
      state.commentAddedError = false;
    },
    postAdded: (state, { payload }) => {
      state.isJobAdded = payload;
    },
    cancelJobError: (state, { payload }) => {
      state.cancelJobError = payload;
    },
    clearCancelJobError: (state) => {
      state.cancelJobError = false;
    },
    candidateDetailPending: (state) => {
      state.candidateDetailPending = true;
    },
    candidateDetails: (state, { payload }) => {
      state.candidateDetail = payload;
      state.candidateDetailPending = false;
    },
    candidateDetailError: (state, { payload }) => {
      state.candidateDetailPending = false;
      state.candidateDetailError = payload;
      state.candidateDetail = {};
    },
    clearCandidateDetailError: (state) => {
      state.candidateDetailPending = false;
      state.candidateDetailError = false;
    },
    moveCandidateSuccess: (state) => {
      state.moveCandidate = true;
    },
    clearCandidateSuccess: (state) => {
      state.moveCandidate = false;
      state.moveCandidateError = false;
    },
    errorCandidateSuccess: (state, { payload }) => {
      state.moveCandidate = false;
      state.moveCandidateError = payload;
    },
    disCandidateSuccess: (state) => {
      state.disqualifiedCandidate = true;
    },
    cleardisCandidateSuccess: (state) => {
      state.disqualifiedCandidate = false;
      state.disqualifiedCandidateError = false;
    },
    errordisCandidateSuccess: (state, { payload }) => {
      state.disqualifiedCandidate = false;
      state.disqualifiedCandidateError = payload;
    },
    setAvailableTimeslots: (state, { payload }: PayloadAction<TimeSlot[]>) => {
      state.availableTimeslots = payload;
    },
    addTimeslot: (state, { payload }: PayloadAction<TimeSlot>) => {
      state.availableTimeslots.push(payload);
    },
    removeTimeslot: (state, { payload }: PayloadAction<number>) => {
      state.availableTimeslots = state.availableTimeslots.filter(
        (slot) => slot.id !== payload,
      );
    },
    setInterviewTime: (state, { payload }: PayloadAction<InterviewTime>) => {
      state.interviewTime = payload;
    },
  },
});

export const {
  errordisCandidateSuccess,
  cleardisCandidateSuccess,
  disCandidateSuccess,
  clearCandidateSuccess,
  errorCandidateSuccess,
  moveCandidateSuccess,
  clearCommentAddedError,
  clearCandidateDetailError,
  candidateDetailError,
  candidateDetails,
  candidateDetailPending,
  commentAddedSuccessfully,
  commentAdded,
  commentAddedError,
  clearCancelJobError,
  cancelJobError,
  postAdded,
  jobAdded,
  jobAddedUpdateError,
  updatedMoreCandidateList,
  clearHireCandidateStaging,
  getHireCandidateStaging,
  setHireCandidateStaging,
  candidatePaging,
  getHireDetailFail,
  getHireDetailPending,
  getHireDetailSuccess,
  clearAllWorkableClients,
  getAllWorkableClients,
  clearCandidateList,
  getCandidateListFail,
  getCandidateListSuccess,
  getCandidateListPending,
  clearHireList,
  getHireListFail,
  getHireListSuccess,
  getHireListPending,
  candidateCount,
  jobCount,
  paging,
  hireAddingInProgress,
  hireAddedSuccessfully,
  hireAdded,
  moreCandidateListPending,
  stopMoreCandidateListPending,
  chatGptJobPending,
  chatGptJobSuccess,
  chatGptJobError,
  setAvailableTimeslots,
  addTimeslot,
  setInterviewTime,
  removeTimeslot,
} = hireSlice.actions;

export default hireSlice.reducer;
