import { Box, Card, Divider, Grid, Typography } from "@mui/material";
import { memo } from "react";
import { STRINGS } from "../../Strings";

const CandidateResume = ({ headerColor, url = "" }) => {
  return (
    <Card
      className="tbl-card"
      sx={{
        minWidth: 275,
        marginTop: 2,
        boxShadow: 10,
        width: "auto",
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          backgroundColor: headerColor,
          paddingLeft: "1.5rem",
          paddingBottom: "0.5rem",
          paddingTop: "0.5rem",
          justifyContent: "flex-start",
          minHeight: 52,
        }}
      >
        <Grid item xs={11} display={"flex"} justifyContent={"space-between"}>
          <Typography variant="subtitle2" component="div">
            {STRINGS.Resume}
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      <Box
        className="tbl-body"
        sx={{
          padding: "1.5rem",
        }}
      >
        <iframe
          width="100%"
          style={{
            border: 0,
          }}
          height="600"
          title="resume"
          src={`https://docs.google.com/gview?url=${encodeURIComponent(url)}&embedded=true`}
        ></iframe>
      </Box>
    </Card>
  );
};

export default memo(CandidateResume);
