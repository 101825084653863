import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import moment from "moment";
import { FC, useCallback } from "react";
import * as Yup from "yup";
import {
  useAddCandidateCommentMutation,
  useGetCandidateAllCommentsQuery,
} from "../../redux/Hire/jobRequestApi";
import FormikInput from "../../shared/components/FormikInput";
import { CloseIcon } from "../../shared/components/Icons";
import { userAccess } from "../../utils/CommonFunction";

type Props = {
  candidateId: number;
  closeCommentBox: () => void;
};

const initialValues = {
  comment: "",
};

const validationSchema = Yup.object({
  comment: Yup.string().required("Please add a comment"),
});

const CandidateCommentList: FC<Props> = ({ candidateId, closeCommentBox }) => {
  const [addComment, addCommentMutating] = useAddCandidateCommentMutation();

  const { data: commentData, isFetching } = useGetCandidateAllCommentsQuery(
    {
      id: candidateId.toString(),
    },
    {
      skip: !candidateId,
    },
  );

  const submitForm = useCallback(
    (data, formikProps) => {
      addComment({
        addedByUId: userAccess().userId,
        addedByUName: userAccess().firstName + " " + userAccess().lastName,
        candidateId: candidateId,
        text: data.comment,
      });
      formikProps?.resetForm();
    },
    [addComment, candidateId],
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitForm}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {(formikProps) => (
        <Form>
          <Grid
            container
            flexDirection={"column"}
            gap={2}
            p={2}
            mt={2}
            borderRadius={1}
            bgcolor={"#FFF"}
          >
            <Grid
              container
              item
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={2}
            >
              <Grid container item gap={1} alignItems={"center"} xs={true}>
                <Typography
                  fontSize={"14px"}
                  lineHeight={"20px"}
                  fontWeight={700}
                >
                  Comments
                </Typography>
                {isFetching && (
                  <>
                    <Typography
                      fontSize={"10px"}
                      lineHeight={"12px"}
                      fontWeight={400}
                      color={"#707D84"}
                    >
                      Fetching more comments
                    </Typography>
                    <CircularProgress size={20}></CircularProgress>
                  </>
                )}
              </Grid>
              <IconButton
                aria-label="close"
                onClick={closeCommentBox}
                size="small"
                sx={{
                  color: "black",
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid
              container
              item
              direction={"column"}
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={2}
            >
              {commentData?.data.map((comment) => (
                <Grid container item alignItems={"center"}>
                  <Grid container item direction={"column"} xs={9} gap={1}>
                    <Typography fontSize={"12px"} lineHeight={"16px"}>
                      {comment.text}
                    </Typography>
                    <Typography fontSize={"12px"} lineHeight={"16px"}>
                      Comment by: {comment.addedByUName}{" "}
                    </Typography>
                  </Grid>
                  <Grid container xs={3} justifyContent={"flex-end"}>
                    <Typography
                      fontSize={"12px"}
                      lineHeight={"16px"}
                      color={"#707D84"}
                    >
                      {moment(comment.addedOn).format("Do MMM yy, HH:MMA")}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid
              container
              item
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={2}
            >
              <Grid item flex={1}>
                <FormikInput
                  label="Comment Here*"
                  name="comment"
                  className="common-select-dropdown"
                  formikProps={formikProps}
                  options={null}
                  fullWidth
                />
              </Grid>
              <Grid item marginBottom={2}>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={addCommentMutating.isLoading}
                >
                  {addCommentMutating.isLoading ? (
                    <CircularProgress size={20} />
                  ) : (
                    "Add"
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default CandidateCommentList;
