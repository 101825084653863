import {
  Box,
  Button,
  Collapse,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { FC, useCallback, useState } from "react";
import { RequestType } from "../../redux/Hire/jobRequestApi";
import { CloseIcon } from "../../shared/components/Icons";
import Search from "../../shared/components/Search/Search";
import { usePermissions } from "../../utils/permission";
import JobRequestForm from "./JobRequestTable/JobRequestForm";

type Props = {
  requestTypes: Array<RequestType>;
  setSelectedRequestType: (val: number) => void;
  onSearch: (val: string) => void;
};

const HireHeader: FC<Props> = ({
  requestTypes,
  setSelectedRequestType,
  onSearch,
}) => {
  const [showNewJobRequest, setShowNewJobRequest] = useState(false);
  const handleNewRequestClick = useCallback(() => {
    setShowNewJobRequest((prev) => !prev);
  }, []);
  const { Hire_AddJobRequest } = usePermissions();
  const onRequestTypeChange = (event) => {
    setSelectedRequestType(event.target.value);
  };

  const closeNewRequest = useCallback(() => {
    setShowNewJobRequest(false);
  }, []);
  return (
    <>
      <Box className="header-container">
        <Box className="filter-container">
          <Search
            placeHolder="Search by name, client or job title"
            getSearch={onSearch}
            isDisabled={showNewJobRequest}
          />

          <FormControl>
            <InputLabel>Request type</InputLabel>
            <Select
              className="filter-dropdown"
              label="Request type"
              defaultValue={0}
              onChange={onRequestTypeChange}
              disabled={showNewJobRequest}
            >
              {requestTypes.map((item) => {
                return (
                  <MenuItem key={item.name} value={item.value}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>

        {Hire_AddJobRequest ? (
          <Button
            variant="contained"
            className="header-button"
            onClick={handleNewRequestClick}
            disabled={showNewJobRequest}
          >
            New Request
          </Button>
        ) : null}
      </Box>
      <Collapse
        in={showNewJobRequest}
        sx={{
          width: "100%",
          boxShadow: "0px 4px 8px 0px #0000000D",
          bgcolor: "#fff",
          borderLeft: "2px solid #A16AE8",
          px: 2,
          py: 1,
          mt: 2,
        }}
        easing={{ enter: "ease-in" }}
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            display: "flex",
            px: 2,
            py: 1,
            justifyContent: "space-between",
          }}
        >
          <Typography fontSize={"24px"} lineHeight={"32px"} fontWeight={700}>
            New request
          </Typography>
          <IconButton
            aria-label="close"
            onClick={closeNewRequest}
            size="small"
            sx={{
              color: "black",
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <JobRequestForm
          jobDetails={""}
          setShowNewJobRequest={setShowNewJobRequest}
        />
      </Collapse>
    </>
  );
};

export default HireHeader;
