import moment from "moment";
import { memo, useCallback, useState } from "react";
import Layout from "../../shared/components/LayoutV2";
import InterviewBody from "./InterviewBody";
import InterviewHeader from "./InterviewHeader";

const Interview = () => {
  const now = moment();
  const monday = now.clone().weekday(1);
  const friday = now.clone().weekday(5);

  const [startDate, setStartDate] = useState(monday);
  const [endDate, setEndDate] = useState(friday);

  const goToNextWeek = useCallback(() => {
    setStartDate((prev) => prev.clone().add({ week: 1 }));
    setEndDate((prev) => prev.clone().add({ week: 1 }));
  }, []);
  const goToPrevWeek = useCallback(() => {
    setStartDate((prev) => prev.clone().subtract({ week: 1 }));
    setEndDate((prev) => prev.clone().subtract({ week: 1 }));
  }, []);

  const goToThisWeek = useCallback(() => {
    setStartDate(monday);
    setEndDate(friday);
  }, []);
  return (
    <Layout
      header={
        <InterviewHeader
          startDate={startDate}
          endDate={endDate}
          goToNextWeek={goToNextWeek}
          goToPrevWeek={goToPrevWeek}
          goToThisWeek={goToThisWeek}
        />
      }
      body={
        <InterviewBody startDate={startDate} endDate={endDate}></InterviewBody>
      }
    ></Layout>
  );
};

export default memo(Interview);
