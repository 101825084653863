import { CircularProgress, Grid, Table, TableBody } from "@mui/material";
import moment from "moment";
import { forwardRef, memo, useImperativeHandle, useMemo } from "react";
import {
  GetAvailableTimeslotsData,
  useGetSlotsForCandidateQuery,
  useGetSlotsForCandidateAuthQuery,
} from "../../redux/Hire/hireApi";
import { Handle } from "./ReserveSlot";
import TimeSlotRow from "./TimeSlotRow";

type Props = {
  startDate: moment.Moment;
  endDate: moment.Moment;
  clientId: string | number;
  onBookClick: (slot: GetAvailableTimeslotsData) => void;
  loading: boolean;
  interval: number;
  accessToken: string | null;
};

const SlotTable = forwardRef<Handle, Props>(
  ({ startDate, endDate, clientId, onBookClick, loading, interval, accessToken }, ref) => {
    const {
      data: slotsData,
      isLoading: isSlotsDataLoading,
      refetch,
    } = useGetSlotsForCandidateAuthQuery(
      {
        request: {
          slotEndedOn: endDate.toISOString(),
          slotStartedOn: startDate.toISOString(),
          clientId: clientId?.toString(),
          intervalInMin: interval,
        },
        token: accessToken ?? ""
      },
      {
        skip: !startDate || !endDate || !accessToken,
      },
    );

    useImperativeHandle(ref, () => ({
      refetch,
    }));

    const sortedSlotData = useMemo(() => {
      if (slotsData?.data)
        return [...(slotsData?.data || [])]
          .filter((el) => el.isAvailable)
          .sort((a, b) => {
            return moment(a.slotSetOn).diff(moment(b.slotSetOn));
          });
      else return [];
    }, [slotsData]);

    return (
      <Grid container item justifyContent={"center"} alignItems={"center"}>
        {isSlotsDataLoading ? (
          <CircularProgress size={36}></CircularProgress>
        ) : (
          <Table
            size="small"
            sx={{ borderSpacing: "6px", borderCollapse: "separate" }}
          >
            <TableBody>
              {sortedSlotData.map((slot) => {
                return (
                  <TimeSlotRow
                    slot={slot}
                    key={slot.id}
                    disabled={loading}
                    clicked={onBookClick}
                  ></TimeSlotRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </Grid>
    );
  },
);

export default memo(SlotTable);
