import { Typography } from "@mui/material";
import { FC, PropsWithChildren } from "react";

type Props = {};

const Timeslot: FC<PropsWithChildren<Props>> = ({ children }) => {
  return (
    <Typography
      variant="body1"
      fontWeight={700}
      sx={{
        ":first-child": {
          marginTop: "6px",
        },
      }}
      display={"flex"}
      flex={1}
      alignItems={"center"}
    >
      {children}
    </Typography>
  );
};

export default Timeslot;
