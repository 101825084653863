import { Grid, Typography } from "@mui/material";
import { CandidateDetails } from "../../redux/Hire/jobRequestApi";

type Props = {
  candidateDetail: CandidateDetails;
};

const CandidateRejected = (props: Props) => {
  return (
    <Grid container p={2} bgcolor={"#fde0d0"}>
      <Typography fontSize={"14px"} lineHeight={"20px"}>
        Candidate Rejected:
      </Typography>
      <Typography fontSize={"14px"} lineHeight={"20px"} fontWeight={600}>
        {props.candidateDetail.disqualificationReason}
      </Typography>
    </Grid>
  );
};

export default CandidateRejected;
