import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  tooltipClasses,
  Typography,
} from "@mui/material";
import { Country } from "country-state-city";
import { FieldArray, Form, Formik } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import {
  clientAdmin,
  patonaAdmin,
  reactQuillFormats,
  reactQuillModules,
} from "../../../Constants";
import FormikInput from "../../../shared/components/FormikInput";
import { AdminRole, userAccess } from "../../../utils/CommonFunction";
import { generateChatGptJobDescription } from "../Hire.helper";
import GenerateJobDescriptionModal from "../newHire/GenerateJobDescriptionModal";

import { styled } from "@mui/styles";
import _ from "lodash";
import { useAlert } from "react-alert";
import * as yup from "yup";
import { useGetJobTitlesQuery } from "../../../redux/Hire/hireApi";
import { useAddJobRequestMutation } from "../../../redux/Hire/jobRequestApi";
import { useGetFilteredUsersQuery } from "../../../redux/User/userApi";
import { usePermissions } from "../../../utils/permission";
import SalaryMatrix from "../newHire/SalaryMatrix";
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }}></Tooltip>
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: 14,
  },
}));

const validationSchema = yup.object({
  clientId:
    userAccess().role === AdminRole.PatonaAdmin ||
    userAccess().role === AdminRole.TMFAdmin
      ? yup.string().required("Client is required")
      : yup.string().notRequired(),
  title: yup.string().required("Job title is required"),
  experienceLevel: yup.string().required("Experience level is required"),
  employeeType: yup.string().required("Employment type is required"),
  workSetupPreference: yup
    .string()
    .required("Work Setup Preference is required"),
  expectedJoiningDate: yup
    .date()
    .nullable()
    .required("Expected joining date is required"),
  aboutTheRole: yup.string().required("Description is required"),
  interviewTimes: yup.string(),
  location: yup.string().required("Location is required"),
});

const NewJobForm = ({ jobDetails, setShowNewJobRequest }) => {
  const [showAiGenerateJob, setShowAiGenerateJob] = useState(false);

  const alert = useAlert();

  const dispatch = useDispatch();

  const { allWorkableClients } = useSelector(
    //@ts-ignore
    (state) => state.hire,
  );

  const [salaryData, setSalaryData] = useState<any>(null);

  const { Hire_ChangeClient, Hire_ChangeRecruiter } = usePermissions();

  const locations = useMemo(() => {
    return [
      { name: "India", value: "IN" },
      {
        name: "Philippines",
        value: "PH",
      },
      {
        name: "Sri Lanka",
        value: "LK",
      },
    ].concat(
      Country.getAllCountries()?.map((item) => {
        return { name: item.name, value: item.isoCode };
      }),
    );
  }, []);

  const [clientId, setClientId] = useState(userAccess().ClientId ?? 999);

  const { data: recruiterList } = useGetFilteredUsersQuery({
    clientId: 999,
    pageNumber: 0,
    pageSize: 50,
    roles: [patonaAdmin],
    disabled: true,
    searchValue: "",
  });

  const { data: clientUserList } = useGetFilteredUsersQuery({
    clientId: clientId,
    pageNumber: 0,
    pageSize: 50,
    roles: [clientAdmin].concat(["Employer", "Employer_Hiring_Manager"]),
    disabled: true,
    searchValue: "",
  });

  const [initialValues, setInitialValues] = useState({
    clientId: userAccess().ClientId || "",
    client: "",
    title: "",
    experienceLevel: "",
    employeeType: "",
    workSetupPreference: "",
    expectedJoiningDate: null,
    location: "",
    aboutTheRole: "",
    interviewTimes: "",
    otherRequirement: "",
    clientHiringManagerUIds: [],
    jobInterviewQuestions: [
      {
        interviewQuestionId: 0,
        question: "",
        hint: "",
        jobRequestRefId: 0,
      },
    ],
  });

  const { data: jobTitleData } = useGetJobTitlesQuery(
    {
      titleSearch: "",
    },
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    },
  );

  const memoizedOptions = useMemo(() => {
    if (jobTitleData?.data) {
      return jobTitleData?.data.map((el) => ({
        label: el.jobTitle,
      }));
    }
    return [];
  }, [jobTitleData]);

  const [addJobReq, { isLoading, isError, isSuccess }] =
    useAddJobRequestMutation();

  useEffect(() => {
    if (isSuccess) {
      setShowNewJobRequest(false);
      alert.success("Job Added Successfully");
    }
    if (isError) {
      alert.error("Error adding Job");
    }
  }, [isSuccess, isError, alert, setShowNewJobRequest]);

  useEffect(() => {
    if (!_.isEmpty(jobDetails)) {
      setInitialValues({
        clientId: jobDetails?.clientId,
        client: "",
        title: jobDetails?.title,
        experienceLevel: jobDetails?.experienceLevel,
        employeeType: jobDetails?.employeeType,
        workSetupPreference: jobDetails?.workSetupPreference,
        expectedJoiningDate: jobDetails?.expectedJoiningDate,
        location: jobDetails?.location,
        aboutTheRole: jobDetails?.description,
        otherRequirement: jobDetails?.otherRequirement,
        jobInterviewQuestions: jobDetails?.jobInterviewQuestions,
        interviewTimes: jobDetails?.interviewTime,
        clientHiringManagerUIds: [],
      });
    }
  }, [jobDetails]);

  const jobPostFormSubmit = useCallback(
    (data) => {
      data.clientId = data.clientId ? data.clientId : 0;
      data.expectedSalaryFrom = salaryData?.minSalary || 0;
      data.expectedSalaryTo = salaryData?.maxSalary || 0;
      data.accountManager = "";
      data.uClientId = userAccess().ClientId;
      data.uId = userAccess().userId;

      data.client =
        allWorkableClients.find((item) => item.id === data.clientId)?.name ||
        userAccess().clientName;
      addJobReq({
        ...data,
        clientHiringManagerUIds: data.clientHiringManagerUIds.join(","),
      });
    },
    [addJobReq, allWorkableClients, salaryData],
  );

  return (
    <Paper style={{ padding: 16 }}>
      <Box sx={{ mt: 1 }}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={(data, helper) => {
            jobPostFormSubmit(data);
            helper.resetForm();
          }}
        >
          {(formikProps) => {
            return (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormikInput
                      label="Job title*"
                      name="title"
                      inputType="autocomplete"
                      formikProps={formikProps}
                      schemaChange
                      schemaOnChangeFun={(value) => {
                        formikProps.setFieldValue("title", value?.label || "");
                      }}
                      options={memoizedOptions}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormikInput
                      label="Employment type*"
                      name="employeeType"
                      className="common-select-dropdown"
                      formikProps={formikProps}
                      options={[
                        { name: "On Contract", value: "On Contract" },
                        { name: "Permanent", value: "Permanent" },
                      ]}
                      inputType="select"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormikInput
                      label="Experience level*"
                      name="experienceLevel"
                      className="common-select-dropdown"
                      formikProps={formikProps}
                      options={[
                        { name: "Junior", value: 1 },
                        { name: "Mid Level", value: 2 },
                        { name: "Senior", value: 3 },
                        { name: "Lead", value: 4 },
                      ]}
                      inputType="select"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <FormikInput
                      label="Location*"
                      name="location"
                      className="common-select-dropdown"
                      formikProps={formikProps}
                      options={locations}
                      inputType="select"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <FormikInput
                      label="Work set up preference*"
                      name="workSetupPreference"
                      className="common-select-dropdown"
                      formikProps={formikProps}
                      options={[
                        { name: "Hybrid", value: "Hybrid" },
                        { name: "Office", value: "Office" },
                        { name: "Remote", value: "Remote" },
                      ]}
                      inputType="select"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormikInput
                      inputType="date-picker"
                      label="Expected Joining date"
                      inputFormat="MM/DD/YYYY"
                      formikProps={formikProps}
                      name="expectedJoiningDate"
                      options={undefined}
                      disablePast={true}
                    />
                  </Grid>

                  {Hire_ChangeClient && (
                    <Grid item xs={12} sm={4}>
                      <FormikInput
                        label="Client*"
                        name="clientId"
                        className="common-select-dropdown"
                        formikProps={formikProps}
                        options={allWorkableClients.map((item) => {
                          return { name: item.name, value: item.id };
                        })}
                        inputType="select"
                        schemaChange
                        schemaOnChangeFun={(val) => {
                          formikProps.setFieldValue("clientId", val);
                          formikProps.setFieldValue(
                            "clientHiringManagerUIds",
                            [],
                          );
                          setClientId(val);
                        }}
                        fullWidth
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={4}>
                    <FormikInput
                      label="Hiring Managers"
                      name="clientHiringManagerUIds"
                      className="common-select-dropdown"
                      formikProps={formikProps}
                      options={clientUserList?.data.users.map((clientUser) => ({
                        name: clientUser.userName,
                        value: clientUser.userId,
                      }))}
                      inputType="multi-select"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormikInput
                      label="Interview Time"
                      name="interviewTimes"
                      className="common-select-dropdown"
                      formikProps={formikProps}
                      options={[
                        { name: "15 minutes", value: "15" },
                        { name: "30 minutes", value: "30" },
                        { name: "45 minutes", value: "45" },
                        { name: "60 minutes", value: "60" },
                      ]}
                      inputType="select"
                      fullWidth
                    />
                  </Grid>

                  {Hire_ChangeRecruiter && (
                    <Grid item xs={12} sm={4}>
                      <FormikInput
                        label="Recruiter"
                        name="recruiter"
                        className="common-select-dropdown"
                        formikProps={formikProps}
                        options={
                          recruiterList?.data?.users.map((recruiter) => ({
                            name: recruiter.userName,
                            value: recruiter.userId,
                          })) || []
                        }
                        inputType="select"
                        fullWidth
                      />
                    </Grid>
                  )}
                </Grid>
                <SalaryMatrix storeSalaryData={setSalaryData} />

                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    sx={{
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      disabled={
                        !!(
                          formikProps?.values?.title === "" ||
                          formikProps?.values?.clientId === 0
                        )
                      }
                      onClick={() => {
                        const payload = {
                          jobTitle: formikProps?.values?.title,
                          clientId: formikProps?.values?.clientId,
                          expLevel: formikProps?.values?.experienceLevel,
                          employeeType: formikProps?.values?.employeeType,
                          salaryFrom: salaryData?.minSalary || 0,
                          salaryTo: salaryData?.maxSalary || 0,
                          location: formikProps?.values?.location,
                          noticePeriod: "",
                        };
                        generateChatGptJobDescription(payload, dispatch);
                        setShowAiGenerateJob(true);
                      }}
                      variant="contained"
                      sx={{ mt: 2 }}
                    >
                      {"AI Generated job description"}
                    </Button>
                    <Box
                      sx={{
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center",
                        mt: 2,
                      }}
                    >
                      <InfoOutlinedIcon sx={{ ml: 1 }} />
                      <Typography
                        variant="caption"
                        sx={{ pl: 1, color: "#aeaeae" }}
                      >
                        {!userAccess().client
                          ? `Client and Job title is required to generate AI job description`
                          : `Job title is required to generate AI job description`}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12}>
                    <ReactQuill
                      theme="snow"
                      id="aboutTheRole"
                      modules={reactQuillModules}
                      formats={reactQuillFormats}
                      placeholder={`Description`}
                      value={formikProps.values.aboutTheRole}
                      onChange={(e) => {
                        formikProps.setFieldValue("aboutTheRole", e);
                      }}
                      style={{
                        height: 250,
                        marginBottom: 50,
                        marginTop: 20,
                      }}
                    />
                    <Box className="formik-input-div">
                      {formikProps.errors.aboutTheRole && (
                        <span className="error">
                          {formikProps.errors.aboutTheRole}
                        </span>
                      )}
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12}>
                    <FormikInput
                      label="Other requirement*"
                      name="otherRequirement"
                      formikProps={formikProps}
                      options={undefined}
                      multiline
                      fullWidth
                      rows="5"
                    />
                  </Grid>
                </Grid>

                {
                  <FieldArray
                    name="jobInterviewQuestions"
                    render={(arrayHelpers) => {
                      const questions =
                        formikProps.values.jobInterviewQuestions;
                      return (
                        <Grid>
                          <Grid
                            container
                            justifyContent={"space-between"}
                            mb={2}
                          >
                            <Box display={"flex"} gap={1}>
                              <Typography variant="h6">
                                Interview Questions
                              </Typography>
                              <LightTooltip
                                title="You can add your questions and expected
                                  answer. Our recruiters will ask the questions
                                  to the candidate while interviewing."
                              >
                                <InfoOutlinedIcon />
                              </LightTooltip>
                            </Box>

                            <IconButton
                              onClick={() => {
                                arrayHelpers.push({
                                  interviewQuestionId: 0,
                                  question: "",
                                  hint: "",
                                  jobRequestRefId: 0,
                                });
                              }}
                            >
                              <img
                                width={24}
                                height={24}
                                src="/images/plus-purple.svg"
                                alt="icon"
                              />
                            </IconButton>
                          </Grid>
                          {questions.map((q, index) => (
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6}>
                                <FormikInput
                                  label="Required Question"
                                  name={`jobInterviewQuestions.${index}.question`}
                                  formikProps={formikProps}
                                  options={undefined}
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <FormikInput
                                  label="Hint for the question"
                                  name={`jobInterviewQuestions.${index}.hint`}
                                  formikProps={formikProps}
                                  options={undefined}
                                  fullWidth
                                />
                              </Grid>
                            </Grid>
                          ))}
                        </Grid>
                      );
                    }}
                  ></FieldArray>
                }

                <GenerateJobDescriptionModal
                  formikProps={formikProps}
                  showAiGenerateJob={showAiGenerateJob}
                  setShowAiGenerateJob={setShowAiGenerateJob}
                />

                <Box
                  display="flex"
                  justifyContent="flex-end"
                  sx={{ mt: 3, mb: 2 }}
                >
                  <Button
                    variant="text"
                    sx={{ mt: 2 }}
                    disabled={isLoading}
                    onClick={() => setShowNewJobRequest(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    disabled={isLoading}
                    variant="contained"
                    sx={{ mt: 2 }}
                  >
                    {isLoading ? (
                      <CircularProgress
                        color="inherit"
                        size={20}
                      ></CircularProgress>
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Paper>
  );
};

export default NewJobForm;
