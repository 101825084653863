import { Box, Skeleton } from "@mui/material";

export const JobReqDetailSkeleton = () => {
  return (
    <Box sx={{ display: "flex", gap: 2 }}>
      <Skeleton animation="wave" variant="rounded" height={500} width={"15%"} />
      <Skeleton animation="wave" variant="rounded" height={500} width={"85%"} />
    </Box>
  );
};

export const JobReqQuestionSkeleton = () => {
  return (
    <Box sx={{ p: 3 }}>
      <Skeleton animation="wave" variant="rounded" height={42} />
      <Skeleton animation="wave" variant="rounded" height={42} sx={{ my: 2 }} />
      <Skeleton animation="wave" variant="rounded" height={42} />
    </Box>
  );
};
