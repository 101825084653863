import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import { memo, useCallback, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import {
  Round,
  useGetAllMeetingsQuery,
} from "../../../redux/Hire/candidateMeetingApi";
import { useAddSummaryMutation } from "../../../redux/Hire/interviewQuestionSlice";
import { usePermissions } from "../../../utils/permission";

const Summary = ({
  handleClick,
  interviewData,
  isPreInterview,
  selectedStage,
  candidateId,
}) => {
  const [editMode, setEditMode] = useState(false);

  const { data, isFetching, isError, refetch } = useGetAllMeetingsQuery({
    candidateId,
    round: Round.Round1,
  });

  const [draftSummaryValue, setDraftSummaryValue] = useState(
    interviewData?.draftSummary || "",
  );
  const [addSummary, { isLoading, isSuccess }] = useAddSummaryMutation();

  const [showFirefliesData, setShowFirefliesData] = useState(false);

  const [showOverview, setShowOverview] = useState(true);
  const interviewSummaryRecorded =
    typeof interviewData?.interviewSummary === "string";
  const alert = useAlert();

  const { Hire_EditInterviewSummary, Hire_EditCandidateStage } =
    usePermissions();

  useEffect(() => {
    if (isSuccess) {
      alert.success("Successfully updated summary");
      setEditMode(false);
    }
  }, [isSuccess]);

  const meetingData = interviewData?.meetings.find(
    (el) => el?.link === data?.data[0]?.link,
  );
  useEffect(() => {
    if (interviewData?.draftSummary) {
      setDraftSummaryValue(interviewData?.draftSummary);
    }
  }, [interviewData]);
  let summaryText =
    "You will receive summary of the candidate after a call with the candidate";

  if (!interviewData?.draftSummary) {
    summaryText = "Summary will be generated in few minutes.";
  } else if (interviewData?.draftSummary) {
    summaryText = interviewData?.draftSummary;
  }

  if (interviewData?.interviewSummary) {
    summaryText = interviewData?.interviewSummary;
  }

  const submitSummary = () => {
    if (interviewData?._id && draftSummaryValue) {
      addSummary({
        interviewId: interviewData?._id,
        interviewSummary: draftSummaryValue,
      });
    }
  };

  const cancelEditing = () => {
    setEditMode(false);
    if (interviewData?.draftSummary) {
      setDraftSummaryValue(draftSummaryValue);
    }
  };

  const toggleFirefliesDataView = useCallback(() => {
    setShowFirefliesData((prev) => !prev);
  }, []);

  const showOverviewView = useCallback(() => {
    setShowOverview(true);
  }, []);

  const showTranscriptView = useCallback(() => {
    setShowOverview(false);
  }, []);

  return (
    <>
      <Box className="interview-list-page-sub-header">
        <h3>Summary of the interview</h3>
        <Box className="icon-div-box">
          <Box>
            {selectedStage.code === "shortlist" &&
            !interviewSummaryRecorded &&
            Hire_EditInterviewSummary ? (
              <IconButton
                disableRipple={true}
                onClick={(e) => {
                  setEditMode(true);
                }}
              >
                <EditIcon />
              </IconButton>
            ) : null}
            {meetingData && (
              <Button variant="outlined" onClick={toggleFirefliesDataView}>
                {showFirefliesData ? "View summary" : "View full transcription"}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
      {showFirefliesData ? (
        <Box className="summary-candiate-box" flexDirection={"column"} gap={1}>
          <Grid>
            <Button
              variant="text"
              onClick={showOverviewView}
              disabled={showOverview}
            >
              Overview
            </Button>
            <Button
              variant="text"
              onClick={showTranscriptView}
              disabled={!showOverview}
            >
              Transcript
            </Button>
          </Grid>
          <Grid
            direction={"column"}
            container
            justifyContent={"center"}
            alignItems={"center"}
          >
            {showOverview ? (
              <Typography variant="h3" whiteSpace="pre-line">
                {meetingData?.summaryGenerated}
              </Typography>
            ) : (
              <Grid
                container
                direction={"column"}
                maxHeight={"250px"}
                overflow={"auto"}
                wrap="nowrap"
                gap={2}
              >
                {meetingData?.transcript.map((t) => (
                  <Grid>
                    <Typography
                      variant="body1"
                      whiteSpace="pre-line"
                      fontWeight={"500"}
                      fontSize={"14px"}
                    >
                      {t.speaker}
                    </Typography>
                    <Typography variant="body2" whiteSpace="pre-line">
                      {t.text}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
        </Box>
      ) : (
        <Box className="summary-candiate-box" flexDirection={"column"} gap={1}>
          {editMode ? (
            <TextField
              label="Summary"
              fullWidth
              value={draftSummaryValue}
              multiline
              onChange={(e) => {
                setDraftSummaryValue(e.target.value);
              }}
            ></TextField>
          ) : (
            <Grid
              direction={"column"}
              container
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Typography variant="h3" whiteSpace="pre-line">
                {summaryText}
              </Typography>
              {!interviewData?.draftSummary && (
                <Typography
                  variant="h3"
                  whiteSpace="pre-line"
                  color={"#00E08C"}
                  fontSize={"14px"}
                  lineHeight={"32px"}
                  fontWeight={"500"}
                >
                  {data?.data.length === 0 &&
                    "Please schedule a call with candidate"}
                  {(data?.data.length || 0) > 0 &&
                    `The interview call is schedule at ${moment.utc(data?.data[0].meetingStartedOn).local().format("ddd Do yy | hh:mm a")} `}
                </Typography>
              )}
            </Grid>
          )}
          {editMode ? (
            <Box justifyContent={"flex-end"} display={"flex"} width={"100%"}>
              <Button
                variant="text"
                size="small"
                disabled={isLoading}
                color="primary"
                onClick={cancelEditing}
                sx={{ marginRight: "10px" }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="small"
                disabled={isLoading}
                onClick={submitSummary}
              >
                {isLoading ? (
                  <CircularProgress size={20}></CircularProgress>
                ) : (
                  "Submit"
                )}
              </Button>
            </Box>
          ) : null}
        </Box>
      )}
    </>
  );
};

export default memo(Summary);
