import CloseIcon from "@mui/icons-material/Close";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Moment from "moment";
import { FC } from "react";

import { STRINGS } from "../../Strings";
import TextSkeleton from "../../shared/components/Skeletons/TextSkeleton";
import { getBase64Download } from "../People/People.helper";

import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import TabDetails from "../../shared/components/TabDetails/TabDetails";
import { styles } from "./HireStyle";

type JobDetailsProps = {
  showModal: boolean;
  closeModal?: () => void;
  hireJobsDetail: any;
  isHireDetailLoading: boolean;
};

const JobDetails: FC<JobDetailsProps> = ({
  showModal,
  closeModal,
  hireJobsDetail,
  isHireDetailLoading,
}) => {
  const classes = styles();

  const listItems: Array<{
    title: string;
    value: string;
  }> = [
    {
      title: STRINGS.Client,
      value: hireJobsDetail?.company?.label,
    },
    {
      title: STRINGS.Requestraisedby,
      value: hireJobsDetail?.client,
    },
    {
      title: STRINGS.CreatedAt,
      value: Moment(hireJobsDetail?.createdDate).format("DD-MM-YYYY"),
    },
    {
      title: STRINGS.JobTitle,
      value: hireJobsDetail?.title,
    },
    {
      title: STRINGS.Experience,
      value: hireJobsDetail?.experienceLevel,
    },
    {
      title: STRINGS.EmploymentType,
      value: hireJobsDetail?.employmentType?.systemRecordLabel,
    },
    {
      title: STRINGS.ExpSalary,
      value: hireJobsDetail?.pay?.jobPayLabel,
    },
    {
      title: STRINGS.ExpJoiningDate,
      value: Moment(hireJobsDetail?.expectedJoiningDate).format("DD-MM-YYYY"),
    },
    {
      title: STRINGS.Location,
      value: hireJobsDetail?.location?.addressLabel,
    },
    {
      title: STRINGS.Owner,
      value: hireJobsDetail?.owner?.label,
    },
  ];

  return (
    <Dialog open={showModal} onClose={closeModal} maxWidth="xl">
      <DialogTitle variant="h4" fontWeight={400} fontSize={"32px"}>
        Request
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={closeModal}
        sx={{
          position: "absolute",
          right: 16,
          top: 16,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        sx={{
          p: 0,
          minWidth: "70vw",
        }}
      >
        <Box className={classes.job_display_card}>
          <Grid container columnSpacing={2}>
            <Grid item xs={12} md={4}>
              {/* left side */}
              <Box
                flexDirection={"row"}
                alignItems={"center"}
                display={"flex"}
                justifyContent={"space-between"}
                px={"16px"}
                sx={{
                  backgroundColor: "#fffaea",
                  minHeight: 56,
                }}
              >
                <Typography
                  variant="h6"
                  component="div"
                  className={classes.headerTitle}
                >
                  {STRINGS.RequestSummary}
                </Typography>
              </Box>

              <Box
                className="tbl-body"
                sx={{
                  padding: "1rem",
                  backgroundColor: "#ffffff",
                }}
              >
                {listItems.map((item) => (
                  <TabDetails
                    key={`${item.title}-${hireJobsDetail?.jobId}`}
                    title={item.title}
                    value={item.value}
                    showSkeleton={isHireDetailLoading}
                  />
                ))}

                <Grid container spacing={2} sx={{ display: "flex" }}>
                  <Grid item xs={6}>
                    <Typography variant="body2" component="div">
                      {STRINGS.Attachment}
                    </Typography>
                  </Grid>
                  {!isHireDetailLoading && hireJobsDetail?.fileName && (
                    <Grid item xs={6}>
                      {isHireDetailLoading ? (
                        <TextSkeleton />
                      ) : (
                        <FileDownloadOutlinedIcon
                          onClick={(e) => {
                            e.stopPropagation();
                            getBase64Download(
                              hireJobsDetail.fileName,
                              hireJobsDetail.fileURL,
                            );
                          }}
                          style={{ color: "#002DFF" }}
                        />
                      )}
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              justifyContent={"flex-start"}
              alignContent={"flex-start"}
              alignSelf={"flex-start"}
            >
              <Box>
                <Box
                  flexDirection={"row"}
                  alignItems={"center"}
                  display={"flex"}
                  justifyContent={"space-between"}
                  px={"16px"}
                  sx={{
                    backgroundColor: "#fffaea",
                    minHeight: 56,
                  }}
                >
                  <Typography
                    variant="h6"
                    component="div"
                    className={classes.headerTitle}
                  >
                    {STRINGS.AboutTheRole}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    alignContent: "center",
                    backgroundColor: "#ffffff",
                    p: 2,
                    borderRadius: "0px 0px 10px 10px",
                  }}
                >
                  {isHireDetailLoading ? (
                    <TextSkeleton />
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: hireJobsDetail?.aboutTheRole || "NA",
                      }}
                    />
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default JobDetails;
