import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { memo, useEffect } from "react";
import { useAlert } from "react-alert";
import * as yup from "yup";
import {
  useAddTaskCommentMutation,
  useUpdateTaskMutation,
} from "../../redux/Task/TaskApi";
import FormikInput from "../../shared/components/FormikInput";
import { userAccess } from "../../utils/CommonFunction";

const initialValues = {
  comment: "",
};

const validationSchema = yup.object({
  comment: yup.string(),
});

const CompleteTaskSubpage = ({ task, goToComment }) => {
  const [updateTaskApi, { isLoading, isError, isSuccess }] =
    useUpdateTaskMutation();
  const [
    addCommentApi,
    {
      isLoading: addCommentLoading,
      isError: addCommentError,
      isSuccess: addCommentSuccess,
    },
  ] = useAddTaskCommentMutation();
  const alert = useAlert();

  useEffect(() => {
    if (addCommentSuccess && !isSuccess) {
      updateTaskApi({
        ...task,
        completedAction: "done",
        completedBy: userAccess().userId,
        completedByName: userAccess().firstName + " " + userAccess().lastName,
      });
    }
  }, [addCommentSuccess, task, updateTaskApi, isSuccess]);

  useEffect(() => {
    if (isSuccess) {
      alert.success("Task completed successfully");
      goToComment();
    }
  }, [alert, goToComment, isSuccess]);

  useEffect(() => {
    if (isError || addCommentError) {
      alert.error("Error while completing Task");
    }
  }, [isError, addCommentError, alert]);

  const postAddComment = async (values) => {
    addCommentApi({
      comment: `Task complete - ${values.comment}`,
      id: 0,
      taskId: task.id,
      commentedBy: userAccess().userId,
      commentedByEmail: userAccess().email,
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={(data) => postAddComment(data)}
    >
      {(formikProps) => (
        <Form
          style={{
            width: "100%",
          }}
        >
          <Typography variant="body2">
            Are sure you want to change the status of the task to complete?
            Mention the reason if any.
          </Typography>
          <Grid container spacing={2} mt={1} alignItems={"center"}>
            <Grid item justifyContent={"space-between"} flex={1}>
              <FormikInput
                label="Reason"
                name="comment"
                formikProps={formikProps}
                options={undefined}
                fullWidth
              />
            </Grid>

            <Grid item mb={1}>
              <Button
                type="submit"
                disabled={isLoading || addCommentLoading}
                variant="contained"
              >
                {isLoading || addCommentLoading ? (
                  <CircularProgress size={20}></CircularProgress>
                ) : (
                  "Complete task"
                )}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default memo(CompleteTaskSubpage);
