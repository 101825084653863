import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import {
  useGetFilteredJobRequetsQuery,
  useGetJobStatusListQuery,
} from "../../redux/Hire/jobRequestApi";
import Layout from "../../shared/components/LayoutV2";
import { userAccess } from "../../utils/CommonFunction";
import HireHeader from "./HireHeader";
import "./index.scss";
import JobRequestTable from "./JobRequestTable";

const HireContainer = () => {
  const { data, isLoading } = useGetJobStatusListQuery(null);

  const [selectedRequestType, setSelectedRequestType] = useState<number>(0);

  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");

  const { clientCode } = useSelector(
    //@ts-ignore
    (state) => state.client,
  );

  const {
    data: jobsList,
    isLoading: jobRequestLoading,
    isFetching: jobRequestFetching,
  } = useGetFilteredJobRequetsQuery({
    requestType: selectedRequestType,
    clientId: clientCode || userAccess().ClientId,
    pageNumber: pageNumber,
    pageSize: pageSize,
    searchValue: searchTerm,
  });

  const onSearch = useCallback((inputTerm: string) => {
    setSearchTerm(inputTerm);
  }, []);

  const handlePageChange = useCallback((paramPageNumber: number) => {
    setPageNumber(paramPageNumber);
  }, []);

  const handlePageSizeChange = useCallback((paramPageSize: number) => {
    setPageSize(paramPageSize);
  }, []);

  return (
    <>
      <Layout
        header={
          <HireHeader
            requestTypes={data?.data || []}
            setSelectedRequestType={setSelectedRequestType}
            onSearch={onSearch}
          />
        }
        body={
          <JobRequestTable
            jobRequestList={jobsList?.data.jobs || []}
            requestType={selectedRequestType}
            pagination={{
              handlePageChange: (param) => {
                handlePageChange(param);
              },
              handlePageSizeChange: (param) => {
                handlePageSizeChange(param);
              },
              pageNumber: pageNumber,
              pageSize: pageSize,
              totalRowCount: jobsList?.data.paginationFilter.count || 0,
            }}
            isLoading={isLoading || jobRequestLoading || jobRequestFetching}
          />
        }
      />
    </>
  );
};

export default HireContainer;
