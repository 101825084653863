import api from "../services/BaseApi";
const BASE_URL_JOB_OPPORTUNITY = process.env.REACT_APP_BASE_API_URL;

const referForOppurtunities =
  BASE_URL_JOB_OPPORTUNITY + "referForOppurtunities";
const applyForOppurtunities =
  BASE_URL_JOB_OPPORTUNITY + "applyForOppurtunities";

export const referForJob = async (payload) => {
  try {
    const res = await api.post(referForOppurtunities, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return res;
  } catch (error) {
    console.error("referForOppurtunities Error:", error);
    return error;
  }
};

export const applyForJob = async (payload) => {
  try {
    const res = await api.post(applyForOppurtunities, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return res;
  } catch (error) {
    console.error("applyForOppurtunities Error:", error);
    return error;
  }
};
