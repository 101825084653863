import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import moment from "moment";
import { FC, useCallback, useEffect } from "react";
import { useAlert } from "react-alert";
import { useDeleteSlotsMutation } from "../../../../redux/Hire/hireApi";
import { CloseIcon } from "../../../../shared/components/Icons";
import { userAccess } from "../../../../utils/CommonFunction";
import { SlotEvent } from "../DragNDropCalendar";

type Props = {
  event: SlotEvent | null;
  closeModal: () => void;
};

const DeleteEventModal: FC<Props> = ({ event, closeModal }) => {
  const [deleteSlots, deleteSlotsMutating] = useDeleteSlotsMutation();
  const alert = useAlert();
  const handleDeleteSlot = useCallback(() => {
    if (event) {
      const id_ = event.id.split("::")[0];
      deleteSlots({
        deletedBy: userAccess().firstName + " " + userAccess().lastName,
        slotId: Number(id_),
      });
    }
  }, [deleteSlots, event]);

  useEffect(() => {
    if (deleteSlotsMutating.isSuccess) {
      alert.success("Slot Deleted Successfully");
      closeModal();
    }
    if (deleteSlotsMutating.isError) {
      alert.error("Error while deleting slot. Please try again");
    }
  }, [alert, deleteSlotsMutating]);

  return (
    <Dialog open={event !== null} onClose={closeModal} maxWidth="xl">
      <DialogTitle
        fontSize={"32px"}
        justifyContent={"center"}
        display={"flex"}
        alignItems={"center"}
        flexDirection={"column"}
      >
        <Typography fontSize={"24px"} lineHeight={"32px"} fontWeight={700}>
          Delete Timeslot
        </Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={closeModal}
        sx={{
          position: "absolute",
          right: 16,
          top: 16,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        sx={{
          px: 4,
          py: 2,
          width: "50vw",
        }}
      >
        <Typography variant="body2" textAlign={"center"} px={4}>
          Are you sure you want to delete the timeslot?
        </Typography>
        <Typography variant="body2" textAlign={"center"} px={4}>
          {moment.utc(event?.start).local().format("ddd Do MMM yy, hh:mm a")} to{" "}
          {moment.utc(event?.end).local().format("ddd Do MMM yy, hh:mm a")}
        </Typography>
        <Box
          display="flex"
          justifyContent="flex-end"
          sx={{ mt: 1, mb: 1 }}
          gap={2}
        >
          <Button variant="text" sx={{ mt: 2 }} onClick={closeModal}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            sx={{ mt: 2 }}
            onClick={handleDeleteSlot}
            disabled={deleteSlotsMutating.isLoading}
          >
            {deleteSlotsMutating.isLoading ? (
              <CircularProgress size={20} />
            ) : (
              "Delete"
            )}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteEventModal;
