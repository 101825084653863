export enum InterviewTime {
  MIN_30 = 30,
  MIN_45 = 45,
  MIN_60 = 60,
}
export type TimeSlot = {
  startTime: Date;
  duration: number;
  id: number;
  isFetched: boolean; // Fetch from server
  isAvailable: boolean; // Fetch from server
};
