import api from "../services/BaseApi";
import { userAccess } from "../utils/CommonFunction";
const BASE_URL = process.env.REACT_APP_BASE_API_URL;

const markJobReqAsCancelled = BASE_URL + "markJobReqAsCancelled";
const markJobReqAsRestored = BASE_URL + "markJobReqAsRestored";

const getAllWorkableClients = BASE_URL + "getAllClients";
const addJobRequest = BASE_URL + "addJobRequest";
const updateJobRequest = BASE_URL + "updateJobRequest";
const generateChatGptJobDescriptionURL =
  BASE_URL + "generateChatGptJobDescription";

export const getAllWorkClients = async (payload: any = "") => {
  try {
    const res = await api.get(getAllWorkableClients);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const postJob = async (payload) => {
  try {
    let request: any = {
      uId: "",
    };
    if (payload) {
      request = { ...request, ...payload };
    }
    request.uId = userAccess().userId;
    const res = await api.post(addJobRequest, request);
    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const editPostJob = async (payload) => {
  try {
    let request: any = {
      uId: "",
    };
    if (payload) {
      request = { ...request, ...payload };
    }
    request.uId = userAccess().userId;
    const res = await api.post(updateJobRequest, request);
    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const cancelJobRequest = async (payload) => {
  try {
    let request: any = {
      uId: "",
    };
    if (payload) {
      request = { ...request, ...payload };
    }
    request.uId = userAccess().userId;
    const res = await api.post(markJobReqAsCancelled, request);
    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const restoreJobRequest = async (payload) => {
  try {
    let request: any = {
      uId: "",
    };
    if (payload) {
      request = { ...request, ...payload };
    }
    request.uId = userAccess().userId;
    const res = await api.post(markJobReqAsRestored, request);
    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const generateChatGptJobDescription = async (payload) => {
  try {
    let request: any = {
      jobTitle: "",
      clientId: 0,
      skills: "",
      expLevel: "",
      employmentType: "",
      salaryFrom: "",
      salaryTo: "",
      location: "",
      noticePeriod: "",
    };
    if (payload) {
      request = { ...request, ...payload };
    }
    const res = await api.post(generateChatGptJobDescriptionURL, request);
    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
