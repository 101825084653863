import { Accordion, AccordionDetails, AccordionSummary, Box, Fade, Grid } from "@mui/material";
import React, { useState } from "react";

const AccordionRow:React.FC<{
    rowDetail:React.JSX.Element,
    rowDetailExpand:React.JSX.Element,
    onExpand?:() => void
}> = ({
    rowDetail,
    rowDetailExpand,
    onExpand
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleOnChange = () => {
    setExpanded(!expanded)
    if(onExpand)
    onExpand()
  }
  return (
    <Grid
      container
      item
      sx={{
        boxShadow: "0px 4px 8px 0px #0000000D",
      }}
      xs={true}
    >
      <Fade in={expanded}>
        <Box
          sx={{
            borderLeft: "2px solid #A16AE8",
          }}
        ></Box>
      </Fade>
      <Accordion
        onChange={handleOnChange}
        disableGutters
        square
        sx={{
          borderRadius: "0px",
          flex: 1,
        }}

      >
        <AccordionSummary
          sx={{
            flexDirection: "row-reverse",
            "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
              transform: "rotate(90deg)",
            },
            minHeight: "82px",
            borderRadius: 0,
          }}
          expandIcon={
            <img
              src="/images/down-arrow.svg"
              alt=""
              width={20}
              height={20}
            />
          }
        >
            {rowDetail}
        </AccordionSummary>
        <AccordionDetails
          sx={{
            borderRadius: 0,
            padding: 0,
          }}
        >
            {expanded ? rowDetailExpand : null}
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default AccordionRow;