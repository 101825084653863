import { Box, Grid, Typography } from "@mui/material";
import moment from "moment";
import {
  FC,
  MouseEvent,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from "react";

type Props = {
  uncertainHour: number;
  unavailabilityHour: number;
  zone: string;
};

const MINUTES_IN_14H = 840;
const MINUTES_IN_HOUR = 60;

const TimezoneLine: FC<PropsWithChildren<Props>> = ({
  children,
  uncertainHour,
  unavailabilityHour,
  zone = "IST",
}) => {
  const [mousePositionVertical, setMousePositionVertical] = useState(0);

  //Offset value to align the range to the timegrid
  const OFFSET_UNCERTAIN = 4 * uncertainHour;
  const OFFSET_UNAVAILABLE = 4.5 * unavailabilityHour;

  const onMouseEnter = useCallback((e: MouseEvent<HTMLDivElement>) => {
    let bounds = e.currentTarget.getBoundingClientRect();
    let y = e.clientY - bounds.top;
    setMousePositionVertical(y);
  }, []);

  const onMouseLeave = useCallback(() => {
    setMousePositionVertical(0);
  }, []);
  const onMouseMove = useCallback((e: MouseEvent<HTMLDivElement>) => {
    let bounds = e.currentTarget.getBoundingClientRect();
    let y = e.clientY - bounds.top;
    setMousePositionVertical(y);
  }, []);

  const getTimeIST = useMemo(() => {
    const height = 600;
    const timeInMinutes = (900 * mousePositionVertical) / height;

    return moment()
      .set({
        hour: 7,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
      .add({
        minutes: timeInMinutes,
      })
      .tz("Asia/Kolkata")
      .format("hh:mm a");
  }, [mousePositionVertical]);
  const getTimePHST = useMemo(() => {
    const height = 600;
    const timeInMinutes = (900 * mousePositionVertical) / height;

    return moment()
      .set({
        hour: 7,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
      .add({
        minutes: timeInMinutes,
      })
      .tz("Asia/Manila")
      .format("hh:mm a");
  }, [mousePositionVertical]);

  return (
    <Box
      display={"flex"}
      pl={4}
      position={"relative"}
      onMouseEnter={onMouseEnter}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
    >
      <Grid container direction={"column"} position={"relative"}>
        <Grid
          item
          position={"absolute"}
          top={5}
          left={-20}
          sx={{
            transform: "rotate(-90deg)",
          }}
        >
          <Typography variant="body2" color={"#A16AE8"} fontWeight={"500"}>
            {zone}
          </Typography>
        </Grid>

        {mousePositionVertical > 0 && (
          <Grid
            position={"absolute"}
            left={500}
            top={mousePositionVertical}
            zIndex={10}
          >
            <Grid
              sx={{
                height: "50px",
                background: "#fff",
                position: "relative",
                width: "130px",
                textAlign: "center",
                lineHeight: "50px",
                borderRadius: 1,
                borderColor: "#CCD3D5",
                borderStyle: "solid",
                borderWidth: "3px",
              }}
            >
              <Typography
                fontSize={"12px"}
                lineHeight={"20px"}
                letterSpacing={"0.1px"}
              >
                India: {getTimeIST}
              </Typography>
              <Typography
                fontSize={"12px"}
                lineHeight={"20px"}
                letterSpacing={"0.1px"}
              >
                Php: {getTimePHST}
              </Typography>

              <Grid
                sx={{
                  height: "35px",
                  background: "#fff",
                  position: "absolute",
                  width: "35px",
                  top: 5,
                  left: -18,
                  textAlign: "center",
                  lineHeight: "50px",
                  borderRadius: 1,
                  borderStyle: "solid",
                  borderColor: "#CCD3D5",
                  borderWidth: "0px 0px 3px 3px",
                  transform: "rotate(45deg)",
                }}
              ></Grid>
            </Grid>
          </Grid>
        )}
        <Grid
          sx={{
            backgroundColor: "#E61A1A",
            width: "3px",
            height: `${((unavailabilityHour * MINUTES_IN_HOUR - OFFSET_UNAVAILABLE) / MINUTES_IN_14H) * 100}%`,
          }}
        ></Grid>
        <Grid
          sx={{
            backgroundColor: "#F27F0D",
            width: "3px",
            height: `${((uncertainHour * MINUTES_IN_HOUR - OFFSET_UNCERTAIN) / MINUTES_IN_14H) * 100}%`,
          }}
        ></Grid>
        <Grid
          item
          sx={{ backgroundColor: "#00E08C", width: "3px" }}
          flex={1}
        ></Grid>
      </Grid>
      {children}
    </Box>
  );
};

export default TimezoneLine;
