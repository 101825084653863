import axios from "axios";
import api from "../services/BaseApi";

const ONBOARDING_URL = process.env.REACT_APP_ONBAORDING_URL;
const REG_URL = process.env.REACT_APP_BASE_API_URL;

const loginUrl = ONBOARDING_URL + "signin";
const userProfileUrl = ONBOARDING_URL + "registerUsers";
const googleRegisterUrl = ONBOARDING_URL + "googlesignin";
const logoutUrl = ONBOARDING_URL + "logout";
const refreshToken = ONBOARDING_URL + "refreshtoken";

const sendUserInviteUrl = ONBOARDING_URL + "signUpInvite";
const getRefUrl = ONBOARDING_URL + "getRef";

const authUrl = ONBOARDING_URL + "auth";

const forgotPasswordUrl = ONBOARDING_URL + "sendForgotPasswordEmail";

const resetPasswordUrl = ONBOARDING_URL + "ResetPassword";
const emailRef = ONBOARDING_URL + "GetEmailRef";
const getUserbyUserID = ONBOARDING_URL + "getUserbyUserID";
const getRolesForUser = ONBOARDING_URL + "getRolesForUser";
const updateRoleForUser = ONBOARDING_URL + "updateRoleForUser";

const getAllMeetingsCandidateUrl = REG_URL + "getAllMeetingsCandidate";
const addInterviewMeetingCandidateUrl =
  REG_URL + "addInterviewMeetingCandidate";

export const resetPasswordService = async (frmData) => {
  try {
    const res = await api.post(resetPasswordUrl, frmData);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const forgotPasswordService = async (frmData) => {
  try {
    const res = await api.post(forgotPasswordUrl, frmData);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const userRegistration = async (frmData) => {
  try {
    const res = await api.post(userProfileUrl, frmData);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const googleRegistration = async (frmData) => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };

    const res = await axios.post(googleRegisterUrl, frmData, {
      headers: headers,
    });

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const registrationInvite = async (frmData) => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    const res = await axios.post(sendUserInviteUrl, frmData, {
      headers: headers,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getUserRef = async (frmData, token) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const res = await axios.post(getRefUrl, frmData, { headers: headers });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getAuthToken = async () => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    const res = await axios.post(authUrl, {}, { headers: headers });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const userLogin = async (frmData) => {
  try {
    const res = await api.post(loginUrl, frmData);
    return res;
  } catch (error: any) {
    throw error?.response?.data || "Something went wrong!";
  }
};

export const userLogout = async (frmData) => {
  try {
    const res = await api.post(logoutUrl, frmData);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getRefreshToken = async (payload) => {
  try {
    const res = await api.post(refreshToken, payload);
    return res;
  } catch (error: any) {
    if (error.message === "Request failed with status code 403") {
      console.log(error);
    }
  }
};

export const getRefreshTokenBaseApi = async (payload, token) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const res = await axios.post(refreshToken, payload, {
      headers: headers,
    });
    return res;
  } catch (error: any) {
    if (error.message === "Request failed with status code 403") {
      console.log(error);
    }
  }
};

export const getEmailRef = async (refId, token) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const res = await axios.post(
      emailRef,
      {
        refId,
        routeName: "",
        operation: "",
      },
      { headers: headers },
    );
    return res?.data.data;
  } catch (error) {
    console.log(error);
  }
};

export const getAllMeetingsForCandidate = async (
  candidateId: string,
  round: number,
  token: string,
) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const res = await axios.post(
      getAllMeetingsCandidateUrl,
      {
        candidateId: candidateId,
        round: round,
      },
      { headers: headers },
    );
    return res?.data;
  } catch (error) {
    console.log(error);
  }
};

export const addInterviewMeetingForCandidate = async (
  data: any,
  token: string,
) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const res = await axios.post(addInterviewMeetingCandidateUrl, data, {
      headers: headers,
    });
    return res?.data;
  } catch (error) {
    console.log(error);
  }
};

export const getUserDetails = async (id) => {
  try {
    const res = await api.post(getUserbyUserID, { userId: id });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getUserRoles = async (id) => {
  try {
    const res = await api.post(getRolesForUser, { userId: id });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const updateUserRole = async (payload) => {
  try {
    const res = await api.post(updateRoleForUser, payload);
    return res;
  } catch (error) {
    console.log(error);
  }
};
