import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { InterviewQuestion } from "../../modules/HireV3/JobRequestTable/types";
import axiosBaseQuery from "../../services/service";
const BASE_URL = process.env.REACT_APP_BASE_API_URL;

export type HireApiResponse<T> = {
  status: number;
  message: string;
  success: string;
  data: Array<T>;
};

type JobTitleRequest = {
  titleSearch: string;
};

type JobTitle = {
  jobTitle: string;
  jobId: number;
};

type JobTitleResponse = {
  data: Array<JobTitle>;
};

type SalaryMatrixRequest = {
  title: string;
  experienceLevel: string;
  minExperience?: number;
  maxExperience?: 0;
  region: string;
};

type MatrixResponse = {
  averageSalary: number;
  employeeType: string;
  experienceLevel: string;
  jobMatrixId: number;
  maxExperience: number;
  maxSalary: number;
  minExperience: number;
  minSalary: number;
  region: string;
  title: string;
  yrOfExperience: string;
};

type SalaryMatrixResponse = {
  data: Array<MatrixResponse>;
  message: string;
  status: number;
  success: boolean;
};

type GetAvailableSlotsResponse = {
  data: Array<GetAvailableTimeslotsData>;
  message: string;
  status: number;
  success: boolean;
};

export type GetAvailableSlotsRequest = {
  slotStartedOn: string;
  slotEndedOn: string;
  clientId?: string;
  jobId?: string;
  intervalInMin: number;
};

export type GetAvailableSlotsRequestExtended = {
  request: object;
  token: string;
};

export type GetAvailableSlotsEmployerRequest = {
  slotStartedOn: string;
  slotEndedOn: string;
  clientId?: string;
  employerEmail?: string;
  jobId?: string;
};

export interface GetAvailableTimeslotsData {
  id: number;
  interviewId: number;
  slotSetOn: string;
  slotSetAt: string;
  durationInMin: number;
  employerName: string;
  employerEmail: string;
  isAvailable: boolean;
  clientId: string;
  jobId: string;
  reservedByCandidateId: string;
  reservedByInterviewerId: string;
  reservedAt: string;
  updatedAt: string;
  updatedBy: string;
  deletedBy: string;
  isDeleted: string;
  deletedAt: string;
}

type DeleteTimeslotRequest = {
  slotId: number;
  deletedBy: string;
};

type DeleteTimeslotResponse = {
  status: number;
  successfull: boolean;
  message: string;
};

type SetSlotsAvailabilityRequest = {
  slots: {
    id: number;
    interviewId: number;
    slotSetOn: string;
    slotSetAt: string;
    durationInMin: number;
    isAvailable: boolean;
    clientId: string;
    jobId: string;
  }[];
  employerName: string;
  employerEmail: string;
};

type UpdateSlotsAvailabilityRequest = {
  slotId: number;
  slotSetOn: string;
  slotSetAt: string;
  durationInMin: number;
  isAvailable: boolean;
  clientId: string;
  jobId: string;
  updatedBy: string;
  employerName: string;
  employerEmail: string;
  reschduleSlotLink: string;
};

type SetSlotsAvailabilityResponse = {
  status: number;
  successfull: boolean;
  message: string;
};
type InterviewQuestionRequest = {
  internalCode: string;
};
type InterviewQuestionsResponse = HireApiResponse<InterviewQuestion>;

type MarkJobReqCancelledResponse = {
  status: number;
  success: boolean;
  message: string;
};

type MarkJobReqCancelledRequest = {
  jobRequestId: number;
  uId: string;
};

type RescheduleRequest = {
  interviewerId: number;
  employerName: string;
  employerEmail: string;
  rescheduledBy: string;
};

type RescheduleResponse = {
  data: any;
  message: string;
  status: number;
  success: boolean;
};

type DeleteSlotRequest = {
  slotId: number;
  deletedBy: string;
};

type DeleteSlotResponse = {};

export const hireApi = createApi({
  reducerPath: "hireJobRequest",
  baseQuery: axiosBaseQuery({
    baseUrl: BASE_URL || "",
  }),
  tagTypes: ["AvailableSlot"],
  endpoints: (builder) => ({
    getJobTitles: builder.query<JobTitleResponse, JobTitleRequest>({
      query: ({ titleSearch }) => ({
        url: "/getAllJobTitle",
        method: "POST",
        body: {
          titleSearch,
        },
      }),
    }),

    getSalaryMatrix: builder.query<SalaryMatrixResponse, SalaryMatrixRequest>({
      query: ({ title, experienceLevel, region }) => ({
        url: "/getSalaryRangeMatrix",
        method: "POST",
        body: {
          title,
          experienceLevel,
          region,
        },
      }),
    }),
    getAvailableSlots: builder.query<
      GetAvailableSlotsResponse,
      GetAvailableSlotsEmployerRequest
    >({
      query: (request) => ({
        url: "getSlotsForEmployer",
        method: "POST",
        body: request,
      }),
      providesTags: ["AvailableSlot"],
    }),
    getSlotsForCandidate: builder.query<
      GetAvailableSlotsResponse,
      GetAvailableSlotsRequest
    >({
      query: (request) => ({
        url: "getSlotsForCandidate",
        method: "POST",
        body: request,
      }),
      providesTags: ["AvailableSlot"],
    }),
    //New authenticated endpoint
    getSlotsForCandidateAuth: builder.query<
      GetAvailableSlotsResponse,
      GetAvailableSlotsRequestExtended
    >({
      query: ({ request, token }) => ({
        url: "getSlotsCandidate",
        method: "POST",
        body: request,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      providesTags: ["AvailableSlot"],
    }),
    setSlotsAvailability: builder.mutation<
      SetSlotsAvailabilityResponse,
      SetSlotsAvailabilityRequest
    >({
      query: (request) => ({
        url: "setSlotsAvailability",
        method: "POST",
        body: request,
      }),
      invalidatesTags: ["AvailableSlot"],
    }),
    updateSlotsAvailability: builder.mutation<
      SetSlotsAvailabilityResponse,
      UpdateSlotsAvailabilityRequest
    >({
      query: (request) => ({
        url: "updateSlotsAvailability",
        method: "POST",
        body: request,
      }),
      invalidatesTags: ["AvailableSlot"],
    }),
    rescheduleSlot: builder.mutation<RescheduleResponse, RescheduleRequest>({
      query: (request) => ({
        url: "candidateInterviewRescheduledSlots",
        method: "POST",
        body: request,
      }),
    }),

    deleteTimeslot: builder.mutation<
      DeleteTimeslotResponse,
      DeleteTimeslotRequest
    >({
      query: (request) => ({
        url: "deleteSlots",
        method: "POST",
        body: request,
      }),
      invalidatesTags: ["AvailableSlot"],
    }),
    getInterviewQuestions: builder.query<
      InterviewQuestionsResponse,
      InterviewQuestionRequest
    >({
      query: ({ internalCode }) => ({
        url: "/getInterviewQuestions",
        method: "POST",
        body: {
          internalCode,
        },
      }),
    }),
    deleteSlots: builder.mutation<DeleteSlotResponse, DeleteSlotRequest>({
      query: (request) => ({
        url: "/deleteSlots",
        method: "post",
        body: request,
      }),
      invalidatesTags: ["AvailableSlot"],
    }),
    markJobReqAsCancelled: builder.mutation<
      MarkJobReqCancelledResponse,
      MarkJobReqCancelledRequest
    >({
      query: (request) => ({
        url: "/markJobReqAsCancelled",
        method: "POST",
        body: request,
      }),
    }),
  }),
});

export const {
  useGetJobTitlesQuery,
  useLazyGetSalaryMatrixQuery,
  useGetInterviewQuestionsQuery,
  useMarkJobReqAsCancelledMutation,
  useGetAvailableSlotsQuery,
  useGetSlotsForCandidateQuery,
  useGetSlotsForCandidateAuthQuery,
  useLazyGetAvailableSlotsQuery,
  useDeleteTimeslotMutation,
  useSetSlotsAvailabilityMutation,
  useUpdateSlotsAvailabilityMutation,
  useDeleteSlotsMutation,
  useRescheduleSlotMutation,
} = hireApi;
