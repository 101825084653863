import { CircularProgress, Grid, Link, Typography } from "@mui/material";
import moment from "moment";
import { FC } from "react";
import {
  Round,
  useGetAllMeetingsQuery,
} from "../../redux/Hire/candidateMeetingApi";

type Props = {
  candidateId: string;
};

const ScheduleRound2View: FC<Props> = ({ candidateId }) => {
  const { data, isFetching } = useGetAllMeetingsQuery({
    candidateId,
    round: Round.Round2,
  });

  return (
    <Grid container item bgcolor={"#fff"} borderRadius={"10px"} p={2} my={2}>
      {isFetching && (
        <Grid container item justifyContent={"center"} alignItems={"center"}>
          <CircularProgress size={36} />
        </Grid>
      )}
      {!isFetching && data?.data.length === 0 && (
        <Grid container item>
          <Typography fontSize={"14px"} lineHeight={"32px"} fontWeight={"500"}>
            Candidate has not yet booked any timeslot
          </Typography>
        </Grid>
      )}
      {!isFetching && data && data.data.length > 0 && (
        <Grid container item>
          <Typography fontSize={"14px"} lineHeight={"32px"} fontWeight={"500"}>
            The interview call is schedule at{" "}
            {moment
              .utc(data.data[0].meetingStartedOn)
              .local()
              .format("ddd Do MMM yy | hh:mm a")}
          </Typography>
          <Typography fontSize={"14px"} lineHeight={"32px"} fontWeight={"500"}>
            Meeting link:{" "}
            <Link href={data.data[0].link}>{data.data[0].link}</Link>
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default ScheduleRound2View;
