import { CircularProgress, Grid } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";
import { useGetTaskByCreatorIdQuery } from "../../redux/Task/TaskApi";
import { userAccess } from "../../utils/CommonFunction";
import TabPanel from "../HireV3/common/TabPanel";
import PaginatedTasks from "./PaginatedTasks";

const CreatedTaskList = ({ tabValue, filters, searchText }) => {
  const [pageNumber, setPageNumber] = useState<number>(1);

  const onChangePage = useCallback(
    (event: React.ChangeEvent<unknown> | null, value: number) => {
      setPageNumber(value);
    },
    [],
  );

  const { clientCode } = useSelector(
    //@ts-ignore
    (state) => state.client,
  );
  const { data, isLoading, refetch, isError } = useGetTaskByCreatorIdQuery(
    {
      createdBy: userAccess().userId,
      pageNumber: pageNumber,
      pageSize: 10,
      searchValue: searchText,
      priority: filters.priority,
      status: filters.status,
      clientId: clientCode,
    },
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    },
  );
  const alert = useAlert();

  useEffect(() => {
    if (isError) {
      alert.error("Something went wrong: Created Tasks");
    }
  }, [alert, isError]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    onChangePage(null, 1);
  }, [tabValue, filters, searchText, onChangePage]);
  return (
    <TabPanel tabValue={tabValue} index={0}>
      {isLoading ? (
        <Grid
          container
          item
          justifyContent={"center"}
          alignItems={"center"}
          height={"100%"}
        >
          <CircularProgress size={36}></CircularProgress>
        </Grid>
      ) : (
        <PaginatedTasks
          list={data?.data.tasks || []}
          onChangePage={onChangePage}
          pageNumber={pageNumber}
          refetch={refetch}
          totalCount={data?.data.paginationFilter.count || 0}
        />
      )}
    </TabPanel>
  );
};

export default CreatedTaskList;
