import { Box, Button, CircularProgress } from "@mui/material";
import { memo, useEffect } from "react";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import { useUpdateTaskMutation } from "../../../redux/Task/TaskApi";
import { userAccess } from "../../../utils/CommonFunction";
const BTN_STYLE = {
  minWidth: 150,
  textTransform: "none",
  color: "#fff",
  borderRadius: "10px",
  border: "none",
  boxShadow: "none",
};
const TaskButton = ({ task, action }) => {
  const navigate = useNavigate();
  const [
    updateTaskApiCall,
    {
      isLoading: updateTaskLoading,
      isError: updateTaskError,
      isSuccess: updateTaskSuccess,
    },
  ] = useUpdateTaskMutation();
  const alert = useAlert();

  const updateTaskApi = async () => {
    const request = {
      ...task,
    };
    request.completedAction = action.id;
    request.completedBy = userAccess().userId;
    request.completedByName =
      userAccess().firstName + " " + userAccess().lastName;

    updateTaskApiCall(request);
  };
  useEffect(() => {
    if (updateTaskError) {
      alert.error("Error while completing task");
    }
  }, [alert, updateTaskError]);

  useEffect(() => {
    if (updateTaskSuccess) {
      alert.success("Successfully completed task");
    }
  }, [alert, updateTaskSuccess]);

  return (
    <Box>
      <Button
        variant="contained"
        size="small"
        disabled={updateTaskLoading}
        sx={[
          BTN_STYLE,
          {
            backgroundColor: action.colour,
            ":hover": {
              backgroundColor: action.colour,
            },
          },
        ]}
        onClick={(e) => {
          e.stopPropagation();
          if (action.type === "link") {
            navigate(action.url);
          } else updateTaskApi();
        }}
      >
        {updateTaskLoading ? (
          <CircularProgress color="inherit" size={20}></CircularProgress>
        ) : (
          action.text
        )}
      </Button>
    </Box>
  );
};

export default memo(TaskButton);
