import { Grid, GridProps } from "@mui/material";
import { FC, PropsWithChildren } from "react";

type Props = {
  tabValue: number;
  index: number;
};

const TabPanel: FC<PropsWithChildren<GridProps & Props>> = (props) => {
  const { children, tabValue, index, ...other } = props;
  return (
    <Grid
      role="tabpanel"
      hidden={tabValue !== index}
      id={`performance-tabpanel-${index}`}
      aria-labelledby={`performance-tab-${index}`}
      sx={{
        height: "100%",
      }}
      {...other}
    >
      {tabValue === index && <>{children}</>}
    </Grid>
  );
};

export default TabPanel;
