import { Grid, Typography } from "@mui/material";
import moment from "moment";
import { PropsWithChildren } from "react";

type Props = {};

const TimeslotGutterText = (props: PropsWithChildren<Props>) => {
  return (
    <Grid
      direction={"column"}
      container
      gap={0.5}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Typography variant="body1" fontSize={"14px"} fontWeight={"700"}>
        Time slots
      </Typography>
      <Typography fontWeight={"700"}>
        ({moment.tz(moment.tz.guess()).format("z")})
      </Typography>
    </Grid>
  );
};

export default TimeslotGutterText;
