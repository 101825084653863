import React from "react";

import { Box, Card, Divider, Grid, Typography } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import { STRINGS } from "../../Strings";
import TextSkeleton from "../../shared/components/Skeletons/TextSkeleton";

const CandidateExpeience: React.FunctionComponent<any> = (props) => {
  const { headerColor = "#fffaea", experience = null } = props;
  return (
    <Card
      className="tbl-card"
      sx={{
        minWidth: 275,
        marginBottom: 2,
        marginTop: 2,
        boxShadow: 10,
        width: "100%",
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          backgroundColor: headerColor,
          paddingLeft: "1.5rem",
          paddingBottom: "0.5rem",
          paddingTop: "0.5rem",
          justifyContent: "flex-start",
          minHeight: 52,
        }}
      >
        <Grid item xs={11} display={"flex"} justifyContent={"space-between"}>
          <Typography variant="subtitle2" component="div">
            {STRINGS.WorkExperience}
          </Typography>
          {props.isMemLoading ? <TextSkeleton /> : null}
        </Grid>
      </Grid>
      <Divider />
      <Box
        className="tbl-body"
        sx={{
          padding: "1.5rem",
        }}
      >
        {!_.isNull(experience) &&
          experience.length > 0 &&
          experience?.map((exe, exeIndex) => {
            return (
              <Grid
                container
                spacing={2}
                sx={{ display: "flex", mt: exeIndex > 0 ? 2 : 0 }}
                key={exeIndex}
              >
                <Grid item xs={4}>
                  <Typography variant="body2" component="div">
                    {moment(exe.startDate || new Date()).format("YYYY")}-
                    {exe.current
                      ? "now"
                      : moment(exe.endDate || new Date()).format("YYYY")}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  {props.isMemLoading ? (
                    <TextSkeleton />
                  ) : (
                    <Typography variant="body2" component="div">
                      {exe.title} at {exe.company}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            );
          })}
      </Box>
    </Card>
  );
};

export default CandidateExpeience;
