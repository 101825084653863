import { Grid, Typography } from "@mui/material";
import moment from "moment";
import { FC } from "react";
import { useGetCandidateTimelineQuery } from "../../redux/Hire/jobRequestApi";

type Props = {
  candidateId: number;
};

const CandidateTimeline: FC<Props> = ({ candidateId }) => {
  const { data } = useGetCandidateTimelineQuery(
    {
      id: candidateId?.toString() || "",
    },
    {
      skip: !candidateId,
    },
  );

  if (data?.data && data?.data?.length > 0)
    return (
      <Grid
        container
        item
        bgcolor={"#fff"}
        p={2}
        flexDirection={"column"}
        gap={3}
        mt={2}
        borderRadius={1}
      >
        <Typography fontSize={"14px"} lineHeight={"20px"} fontWeight={700}>
          Timeline
        </Typography>
        <Grid container item bgcolor={"#fff"} flexDirection={"column"} gap={1}>
          {data.data.map((timelineItem) => (
            <Grid container item spacing={1} alignItems={"center"}>
              <Grid item xs={1}>
                <img src="/images/next.svg" width={24} height={24} alt="->" />
              </Grid>
              <Grid item xs={3}>
                {timelineItem.addedByUName ? (
                  <Typography
                    fontSize={"14px"}
                    lineHeight={"20px"}
                    fontWeight={700}
                  >
                    {timelineItem.addedByUName}
                  </Typography>
                ) : (
                  <Typography
                    fontSize={"14px"}
                    lineHeight={"20px"}
                    fontWeight={700}
                  >
                    {" "}
                    Unknown User
                  </Typography>
                )}
              </Grid>
              <Grid item xs={5}>
                <Typography
                  fontSize={"12px"}
                  lineHeight={"16px"}
                  color={"#707D84"}
                >
                  {timelineItem.text}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  fontSize={"12px"}
                  lineHeight={"16px"}
                  color={"#707D84"}
                >
                  {moment
                    .utc(timelineItem.addedOn)
                    .local()
                    .format("Do MMM yy, HH:MMA")}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    );
  return null;
};

export default CandidateTimeline;
