import { Button, Grid, IconButton, Typography } from "@mui/material";
import { uuid4 } from "@sentry/core";
import moment from "moment";
import { useCallback } from "react";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import { JobRequest } from "../../../redux/Hire/jobRequestApi";

const JobRequestSummary: React.FC<{
  jobRequest: JobRequest;
}> = ({ jobRequest }) => {
  const navigate = useNavigate();
  let rowData: Array<string | number> = [jobRequest.title, 0, 0, 0, 0, 0, 0];
  let colorOfTag = "#002DFF";
  let text = `Raised by employer on ${moment(jobRequest.createdDate).format("Do MMM yy")}`;
  const alert = useAlert();
  if (jobRequest.status === "Open") {
    rowData = [
      jobRequest.title,
      jobRequest.stages[0].count || 0,
      jobRequest.stages[1].count || 0,
      jobRequest.stages[2].count || 0,
      jobRequest.stages[3].count || 0,
      jobRequest.stages[4].count || 0,
      jobRequest.stages[5].count || 0,
    ];
    colorOfTag = "#00E08C";
    text = `Published on ${moment(jobRequest.lastUpdatedDateTimeUTC).format("Do MMM yy")}`;
  }
  if (jobRequest.status === "Cancelled") {
    rowData = [
      jobRequest.title,
      "Reason",
      jobRequest.client,
      moment(jobRequest.archievedDate || "").format("Do MMM,yyyy"),
      "Cancelled by",
      "Interview Stage",
    ];
    colorOfTag = "#00B370";
    text = `Cancelled on ${moment(jobRequest.lastUpdatedDateTimeUTC).format("Do MMM yy")}`;
  }
  if (jobRequest.status === "Completed") {
    rowData = [
      jobRequest.title,
      jobRequest.experience,
      jobRequest.candidateList.map((el) => el.name).join(),
      moment(jobRequest.completedDate || "").format("Do MMM,yyyy"),
    ];
    colorOfTag = "#00B370";
    text = `Completed on ${moment(jobRequest.lastUpdatedDateTimeUTC).format("Do MMM yy")}`;
  }

  if (jobRequest.status === "Initiated") {
    colorOfTag = "#F27F0D";
    text = "Yet to publish";
  }

  const onViewRequestClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      navigate(`/job-request/${jobRequest.jobRequestId}`);
    },
    [jobRequest, navigate],
  );

  return (
    <Grid container item xs={true} pl={1}>
      <Grid container item alignItems={"center"} gap={1}>
        {rowData.map((data) => (
          <Grid item xs={true} key={uuid4()}>
            <Typography
              fontSize={"14px"}
              lineHeight={"16px"}
              fontWeight={"600"}
            >
              {data}
            </Typography>
          </Grid>
        ))}
        <Grid item xs={true}>
          <Button sx={{ m: 0 }} size="small" onClick={onViewRequestClick}>
            View Request
          </Button>
        </Grid>
      </Grid>
      <Grid container item>
        <Grid item xs={true}>
          <Typography variant="caption">Client: {jobRequest.client}</Typography>
          <Typography
            variant="caption"
            color={"inherit"}
            fontWeight={"600"}
            fontSize={"12px"}
            lineHeight={"16px"}
          >
            ({jobRequest.workableInternalCode})
          </Typography>
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              navigator.clipboard.writeText(jobRequest.workableInternalCode);
              alert.success("Code Copied Successfully", {
                timeout: 1000,
              });
            }}
            title="copy-code"
          >
            <img
              src="/images/copy-icon.svg"
              alt="copy"
              width={16}
              height={16}
            ></img>
          </IconButton>
        </Grid>
        <Grid item xs={true}>
          <Typography variant="caption">
            Expected joining date:{" "}
            {moment(jobRequest.expectedJoiningDate).format("MMM DD, yyyy") ??
              "N/A"}
          </Typography>
        </Grid>
        <Grid item xs={true}>
          <Typography variant="caption">
            Expected salary range: {jobRequest.expectedSalary ?? "N/A"}
          </Typography>
        </Grid>
        <Grid
          container
          justifyContent={"center"}
          item
          xs={true}
          color={colorOfTag}
          borderLeft={`4px solid ${colorOfTag}`}
          borderRight={`4px solid ${colorOfTag}`}
          alignItems={"center"}
          position={"relative"}
        >
          <Typography
            variant="caption"
            color={"inherit"}
            fontWeight={"600"}
            fontSize={"12px"}
            lineHeight={"16px"}
          >
            {text}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default JobRequestSummary;
