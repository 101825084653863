import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import { FC, useCallback, useEffect, useState } from "react";
import * as Yup from "yup";
import {
  useGetTaskForCandidateAtStageQuery,
  useUpdateTaskMutation,
} from "../../redux/Task/TaskApi";
import FormikInput from "../../shared/components/FormikInput";
import { CloseIcon } from "../../shared/components/Icons";
import { userAccess } from "../../utils/CommonFunction";

type Props = {
  candidateId: number;
  closeDisqualify: () => void;
  jobId: number;
  stage: any;
};

const initialValues = {
  reason: "",
};

const validationSchema = Yup.object({
  reason: Yup.string().required("Please select a reason"),
});

const DisqualifyCandidate: FC<Props> = ({
  candidateId,
  closeDisqualify,
  jobId,
  stage,
}) => {
  const [pollingInterval, setPollingInterval] = useState<number>(5000);
  const { data: taskData } = useGetTaskForCandidateAtStageQuery(
    {
      CandidateId: candidateId.toString(),
      JobId: jobId.toString(),
      StageCode: stage.code === "shortlist" ? "internalInterview" : stage.code,
    },
    {
      skip: !candidateId || !jobId || !stage,
      pollingInterval: pollingInterval,
    },
  );
  useEffect(() => {
    if (taskData?.data.result) {
      setPollingInterval(0);
    }
  }, [taskData]);

  const [updateTask, updateTaskMutating] = useUpdateTaskMutation();

  const submitForm = useCallback(
    (data) => {
      if (taskData?.data.result) {
        updateTask({
          ...taskData?.data.result,
          id: taskData.data.result.taskId,
          completedAction: JSON.parse(taskData?.data.result.actionsJson)[1].id,
          completedBy: userAccess().userId,
          disquialifiedReason: data.reason,
          completedByName: userAccess().firstName + " " + userAccess().lastName,
        });
      }
    },
    [taskData, updateTask],
  );

  useEffect(() => {
    if (updateTaskMutating.isSuccess) {
      closeDisqualify();
    }
  }, [closeDisqualify, updateTaskMutating]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitForm}
      validationSchema={validationSchema}
    >
      {(formikProps) => (
        <Form>
          <Grid
            container
            flexDirection={"column"}
            gap={2}
            p={2}
            bgcolor={"#FFFAEA"}
          >
            <Grid
              container
              item
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={2}
            >
              <Typography
                fontSize={"14px"}
                lineHeight={"20px"}
                fontWeight={"600"}
              >
                Disqualify the candidate?
              </Typography>
              <IconButton
                aria-label="close"
                onClick={closeDisqualify}
                size="small"
                sx={{
                  color: "black",
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid
              container
              item
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={2}
            >
              <Grid item flex={1}>
                <FormikInput
                  label="Reason"
                  name="reason"
                  inputType="select"
                  className="common-select-dropdown"
                  options={[
                    {
                      name: "Poor verbal communication skills",
                      value: "Poor verbal communication skills",
                    },
                    {
                      name: "Unable to respond to questions clearly and concisely",
                      value:
                        "Unable to respond to questions clearly and concisely",
                    },
                    {
                      name: "Less enthusiasm for the role or industry",
                      value: "Less enthusiasm for the role or industry",
                    },
                    {
                      name: "Work style don't align with the company's culture",
                      value:
                        "Work style don't align with the company's culture",
                    },
                    {
                      name: "Salary expectations are too high",
                      value: "Salary expectations are too high",
                    },
                    {
                      name: "Lacks in technical skills",
                      value: "Lacks in technical skills",
                    },
                  ]}
                  formikProps={formikProps}
                  fullWidth
                />
              </Grid>
              <Grid item marginBottom={2}>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={
                    updateTaskMutating.isLoading || !taskData?.data.result
                  }
                >
                  {updateTaskMutating.isLoading ? (
                    <CircularProgress size={20} />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default DisqualifyCandidate;
