import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { InterviewQuestion } from "../../modules/HireV3/JobRequestTable/types";
import axiosBaseQuery from "../../services/service";
import { HireApiResponse } from "./hireApi";
const BASE_URL = process.env.REACT_APP_BASE_API_URL;

type BaseResponse<T> = {
  message: string;
  success: boolean;
  status: number;
  data: T;
};

export type RequestType = {
  name: string;
  value: number;
};

type JobStatusListResponse = BaseResponse<Array<RequestType>>;

type JobRequestMandatory = {
  client: string;
  clientId: number;
  countryCode: string | null;
  createdDate: string;
  expectedJoiningDate: Date;
  expectedSalary: string;
  experience: string;
  jobRequestId: number;
  shortcode: string;
  lastUpdatedDateTimeUTC: string | null;
  title: string;
  workableInternalCode: string;
};

type Stage = {
  name: string;
  code: string | null;
  count: number;
  index: number;
};

type JobListRequest = {
  requestType: number;
  clientId: number;
  searchValue: string;
  pageNumber: number;
  pageSize: number;
};

export type JobRequest =
  | (JobRequestMandatory & DraftStageDetails)
  | (JobRequestMandatory & InitiatedStageDetails)
  | (JobRequestMandatory & OpenStageDetails)
  | (JobRequestMandatory & CancelledStageDetails)
  | (JobRequestMandatory & CompletedStageDetails);

type JobListResponse = BaseResponse<{
  jobs: Array<JobRequest>;
  paginationFilter: {
    count: number;
    pageNumber: number;
    pageSize: number;
  };
}>;

type AddJobReqRequest = {
  clientId: number;
  client: string;
  title: string;
  experienceLevel: string;
  employeeType: string;
  workSetupPreference: string;
  expectedJoiningDate: string;
  location: string;
  aboutTheRole: string;
  otherRequirement: string;
  uId: string;
  accountManager: string;
  recruiter: string;
  jobInterviewQuestions: InterviewQuestion[];
};

type AddJobReqResponse = HireApiResponse<JobRequest>;

type JobDetailsRequest = {
  jobRequestId: number;
};

type DraftStageDetails = {
  status: "Draft";
  stages: null;
  candidateList: null;
  completedDate: null;
  archievedDate: null;
};

type InitiatedStageDetails = {
  status: "Initiated";
  stages: Array<Stage>;
  candidateList: null;
  completedDate: null;
  archievedDate: null;
};

type OpenStageDetails = {
  status: "Open";
  stages: Array<Stage>;
  candidateList: null;
  completedDate: null;
  archievedDate: null;
};

type CancelledStageDetails = {
  status: "Cancelled";
  stages: Array<Stage>;
  candidateList: null;
  completedDate: null;
  archievedDate: string;
};

type CompletedStageDetails = {
  status: "Completed";
  stages: null;
  candidateList: Array<{
    id: string;
    name: string;
    firstname: string;
    lastname: string;
  }>;
  completedDate: string;
  archievedDate: null;
};

type JobRequestDetails = {
  jobId: number;
  clientId: number;
  workableInternalCode: string;
  title: string;
  location: string;
  numberOfCandidateRequired: number;
  createdDate: Date | null;
  client: string;
  expectedJoiningDate: Date | null;
  expectedSalary: string;
  completedDate: Date | null;
  aboutTheRole: string;
  isJobPostedOnWorkable: boolean;
  additionalRequirements: string | null;
  fileURL: string | null;
  fileName: string | null;
  employeeType: string;
  isCancelled: boolean;
  experienceLevel: string;
  jobInterviewQuestionsDto: Array<any>;
  workSetupPreference: string;
  accountManager: string;
  recruiter: string;
  clientHiringManagerUIds: Array<string>;
  interviewTimes: string;
  status: string;
};

type JobDetailsResponse = BaseResponse<JobRequestDetails>;

type JobStatRequest = {
  jobRequestId: number;
};

type Stat = {
  code: string;
  count: number;
  index: number;
  name: string;
};

type JobStatResponse = BaseResponse<Array<Stat>>;

export type Candidate = {
  disqualified: boolean;
  id: string;
  name: string | null;
  wId: string;
};
export type CandidateDetails = {
  address: string | null;
  answers: string | null;
  candidateEducationEntries: Array<CandidateEducation>;
  candidateWorkExperiences: Array<CandidateWork>;
  city: string | null;
  country: string | null;
  countryCode: string | null;
  createdAt: string;
  disqualificationReason: string | null;
  disqualified: boolean;
  disqualifiedAt: string | null;
  domain: string | null;
  email: string | null;
  firstname: string | null;
  fullname: string | null;
  headline: string | null;
  hiredAt: string | null;
  id: number;
  jobDepartment: string;
  jobId: string;
  jobShortCode: string;
  jobTitle: string;
  lastname: string | null;
  location_str: string;
  name: string;
  outboundMailbox: string | null;
  phone: string | null;
  rId: string | null;
  rResumeURL: string | null;
  region: string | null;
  regionCode: string | null;
  salaryMatrixID: number | null;
  skills: string | null;
  source: string | null;
  stage: string | null;
  tags: string | null;
  updatedAt: string | null;
  wEventId: string;
  wId: string;
  wProfileURL: string | null;
  wResumeURL: string;
  zipCode: string | null;
};

type CandidateListResponse = BaseResponse<{
  candidates: Array<Candidate>;
  filter: {
    count: number;
    pageNumber: number;
    pageSize: number;
  };
}>;
type CandidateListRequest = {
  pageNumber: number;
  pageSize: number;
  jobId: string;
  jobPipeLineCode: string;
  searchValue: string;
  showDisquilify: boolean;
};

type CandidateDetailsRequest = {
  id: string;
};
type CandidateDetailsResponse = BaseResponse<CandidateDetails>;

type CandidateRequest = {
  id: number;
  wEventId: string;
  wId: string;
  rId: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  firstname: string;
  lastname: string;
  fullname: string;
  headline: string;
  wProfileURL: string;
  address: string;
  phone: string;
  email: string;
  skills: string;
  answers: string;
  wResumeURL: string;
  rResumeURL: string;
  tags: string;
  outboundMailbox: string;
  stage: string;
  jobId: string;
  jobDepartment: string;
  jobShortCode: string;
  jobTitle: string;
  disqualified: boolean;
  disqualifiedAt: string;
  disqualificationReason: string;
  hiredAt: string;
  source: string;
  domain: string;
  location_str: string;
  country: string;
  countryCode: string;
  region: string;
  regionCode: string;
  city: string;
  zipCode: string;
  salaryMatrixID: number;
  candidateEducationEntries: Array<CandidateEducation>;
  candidateWorkExperiences: Array<CandidateWork>;
};

type CandidateEducation = {
  id: number;
  degree: string;
  school: string;
  fieldOfStudy: string;
  startDate: string;
  endDate: string;
};

type CandidateWork = {
  id: number;
  title: string;
  summary: string;
  company: string;
  industry: string;
  startDate: string;
  endDate: string;
  current: boolean;
};

type AddCandidateCommentRequest = {
  candidateId: number;
  text: string;
  addedByUId: string;
  addedByUName: string;
};

type Comment = {
  addedByUId: string | null;
  addedByUName: string | null;
  addedOn: string | null;
  candidateId: number;
  id: number;
  text: string;
};

type GetCandidateCommentsResponse = BaseResponse<Comment[]>;

type CandidateTimelineRequest = {
  id: string;
};

type Timeline = {
  addedByUId: string | null;
  addedByUName: string | null;
  addedOn: string | null;
  candidateId: number;
  id: number;
  text: string;
};

type CandidateTimelineResponse = BaseResponse<Timeline[]>;

export const jobRequestApi = createApi({
  reducerPath: "jobRequestApi",
  baseQuery: axiosBaseQuery({
    baseUrl: BASE_URL || "",
  }),
  tagTypes: [
    "job-request-list",
    "request-type",
    "candidateList",
    "stats",
    "candidateComments",
    "candidateTimeline",
  ],
  endpoints: (builder) => ({
    getJobStatusList: builder.query<JobStatusListResponse, null>({
      query: () => ({
        url: "getJobStatusList",
        method: "GET",
      }),
    }),
    getFilteredJobRequets: builder.query<JobListResponse, JobListRequest>({
      query: (request) => ({
        url: "getFilteredJobRequets",
        method: "POST",
        body: request,
      }),
      providesTags: ["job-request-list"],
    }),
    getJobRequestById: builder.query<JobDetailsResponse, JobDetailsRequest>({
      query: (request) => ({
        url: "getJobRequestById",
        method: "POST",
        body: request,
      }),
    }),
    getJobStats: builder.query<JobStatResponse, JobStatRequest>({
      query: (request) => ({
        url: "getJobStats",
        method: "POST",
        body: request,
      }),
      providesTags: ["stats"],
    }),
    getCandidateDetails: builder.query<
      CandidateDetailsResponse,
      CandidateDetailsRequest
    >({
      query: (request) => ({
        url: "getCandidateDetails",
        method: "POST",
        body: request,
      }),
    }),
    addCandidate: builder.mutation<BaseResponse<any>, CandidateRequest>({
      query: (request) => ({
        url: "addCandidate",
        method: "POST",
        body: request,
      }),
      invalidatesTags: ["candidateList", "stats"],
    }),

    addJobRequest: builder.mutation<AddJobReqResponse, AddJobReqRequest>({
      query: (request) => ({
        url: "/addJobRequest",
        method: "POST",
        body: request,
      }),
      invalidatesTags: ["job-request-list"],
    }),

    addCandidateComment: builder.mutation<any, AddCandidateCommentRequest>({
      query: (request) => ({
        url: "addCandidateComment",
        method: "POST",
        body: request,
      }),
      invalidatesTags: ["candidateComments"],
    }),

    getCandidateAllComments: builder.query<
      GetCandidateCommentsResponse,
      CandidateTimelineRequest
    >({
      query: (request) => ({
        url: "getCandidateAllComments",
        method: "POST",
        body: request,
      }),
      providesTags: ["candidateComments"],
    }),

    getCandidateTimeline: builder.query<
      CandidateTimelineResponse,
      CandidateTimelineRequest
    >({
      query: (request) => ({
        url: "getCandidateTimeline",
        method: "POST",
        body: request,
      }),
      providesTags: ["candidateTimeline"],
    }),
    getCandidateListPerStageByJobId: builder.query<
      CandidateListResponse,
      CandidateListRequest
    >({
      query: (request) => ({
        url: "getCandidateListPerStageByJobId",
        method: "POST",
        body: request,
      }),
      serializeQueryArgs: ({ queryArgs, endpointName }) => {
        return `${endpointName}?jobId=${queryArgs.jobId}&pipelineCode=${queryArgs.jobPipeLineCode}`;
      },
      providesTags: (result, error, arg) => [
        { type: "candidateList", id: `${arg.jobId}-${arg.jobPipeLineCode}` },
      ],
      onQueryStarted(arg, api) {
        if (arg.pageNumber === 1)
          api.updateCachedData((candidateResponse) => {
            candidateResponse.data.candidates = [];
          });
      },

      merge: (currentCache, response) => {
        return {
          ...currentCache,
          data: {
            ...currentCache.data,
            candidates: currentCache.data.candidates.concat(
              response.data.candidates || [],
            ),
          },
        };
      },
      forceRefetch({ currentArg, previousArg }) {
        return (
          currentArg?.jobId !== previousArg?.jobId ||
          currentArg?.jobPipeLineCode !== previousArg?.jobPipeLineCode ||
          currentArg?.pageNumber !== previousArg?.pageNumber
        );
      },
    }),
  }),
});

export const {
  useGetJobStatusListQuery,
  useGetFilteredJobRequetsQuery,
  useGetJobRequestByIdQuery,
  useGetJobStatsQuery,
  useGetCandidateListPerStageByJobIdQuery,
  useGetCandidateDetailsQuery,
  useAddCandidateMutation,
  useAddJobRequestMutation,
  useGetCandidateTimelineQuery,
  useAddCandidateCommentMutation,
  useGetCandidateAllCommentsQuery,
} = jobRequestApi;
