import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useCallback, useEffect, useState } from "react";

import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

import { CircularProgress } from "@mui/material";
import { useAlert } from "react-alert";
import {
  useAddDraftSummaryMutation,
  useAddNoteMutation,
  useAddQuestionsMutation,
  useGetInterviewQuery,
  useQuestionnaireQuery,
} from "../../../redux/Hire/interviewQuestionSlice";
import { usePermissions } from "../../../utils/permission";
import CandidateHeader from "../CandidateHeader";
import CandidateMoveDialog from "../CandidateMoveDialog";
import AddQuestionModal from "./AddQuestionModal";
import InterviewQuestionList from "./InterviewQuestionList";
import Summary from "./Summary";

const CandiateInterviewDetails = ({
  candidateDetail,
  selectedStage,
  jobId,
  candidateId,
  candidateEmail,
  setSelectedStage,
  selectedCandidate,
  clientName,
  roleName,
  clientId,
  recruiterEmail,
  recruiterName,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [showQuestionModal, setShowQuestionModal] = useState(false);
  const [snackBarOpen, setSnackBarkOpen] = useState(false);

  const [pollingInterval, setPollingInterval] = useState<number | undefined>(
    5000,
  );

  useQuestionnaireQuery(
    {
      jobId: jobId,
      candidateId,
    },
    {
      skip: !candidateId,
    },
  );

  const { data: interviewData } = useGetInterviewQuery(
    {
      candidateId,
      jobId: jobId,
    },
    {
      pollingInterval: pollingInterval,
      skip: !candidateId,
      refetchOnMountOrArgChange: true,
    },
  );

  const alert = useAlert();

  const isPreInterview =
    selectedStage?.code === "Screened by Talent Team" ||
    selectedStage?.code === "APPLIED" ||
    selectedStage?.code === "shortlist";

  useEffect(() => {
    if (interviewData?.meetings[0]?.firefliesSummaryStatus === "COMPLETED") {
      setPollingInterval(0);
    } else {
      setPollingInterval(5000);
    }
  }, [interviewData]);

  const [addDraftSummary, { isLoading: isDraftSummaryLoading, isSuccess }] =
    useAddDraftSummaryMutation();

  const {
    Hire_AddInterviewNotes,
    Hire_SubmitInterviewandEditSummary,
    Hire_AddInterviewQuestions,
  } = usePermissions();

  const [addQuestions] = useAddQuestionsMutation();
  const [addNote] = useAddNoteMutation();

  useEffect(() => {
    setPollingInterval(5000);
  }, [jobId, candidateId]);

  const addNewQuestions = (data) => {
    if (interviewData?._id) {
      addQuestions({
        interviewId: interviewData?._id,
        questions: data.questionList,
      });
    }
    setShowQuestionModal(false);
  };

  const submitForm = async (values) => {
    if (interviewData) {
      try {
        await addDraftSummary({
          interviewId: interviewData?._id,
        }).unwrap();
      } catch (e: any) {
        alert.error("Error updating candidate summary " + e.message);
      }
    }
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackBarOpen}
        onClose={() => setSnackBarkOpen(false)}
        autoHideDuration={2000}
      >
        <Alert
          onClose={() => setSnackBarkOpen(false)}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
          className="custom-snackbar-msg"
        >
          Questions added successfully
        </Alert>
      </Snackbar>

      <AddQuestionModal
        show={showQuestionModal}
        setModel={() => setShowQuestionModal(false)}
        onFormSubmitAction={addNewQuestions}
      />

      <Grid item xs={12}>
        <CandidateHeader
          candidateDetail={candidateDetail}
          selectedCandidate={selectedCandidate}
          selectedStage={selectedStage}
          candidateId={candidateId}
          clientName={clientName}
          jobId={jobId}
          clientId={clientId}
          setSelectedStage={setSelectedStage}
          roleName={roleName}
          recruiterEmail={recruiterEmail}
          recruiterName={recruiterName}
          tabValue={1}
        >
          <Box>
            {selectedStage?.code === "shortlist" &&
              Hire_SubmitInterviewandEditSummary && (
                <CandidateMoveDialog
                  candidateId={candidateId}
                  jobId={jobId}
                  bgColor="#FFFAEA"
                  preText={
                    "Read the summary before moving candidate to next stage or disqualifying."
                  }
                  text="Move candidate to client endorsement?"
                  selectedStage={selectedStage}
                  setSelectedStage={setSelectedStage}
                />
              )}
            <Summary
              handleClick={handleClick}
              interviewData={interviewData}
              isPreInterview={isPreInterview}
              selectedStage={selectedStage}
              candidateId={candidateId}
            />

            <Box
              className="interview-list-page-sub-header"
              style={{ marginTop: "10px" }}
            >
              <h3>Interview questions list</h3>
              {isPreInterview && Hire_AddInterviewQuestions ? (
                <Box>
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    onClick={() => setShowQuestionModal(true)}
                  >
                    Add interview questions
                  </Button>
                </Box>
              ) : null}
            </Box>
            <Box>
              <InterviewQuestionList
                isShortlistStage={selectedStage.code === "shortlist"}
                isPostInterview={!isPreInterview || !Hire_AddInterviewNotes}
                disabled={
                  selectedStage?.code !== "shortlist" || !Hire_AddInterviewNotes
                }
                addNoteFun={(text, questionId) => {
                  if (interviewData && questionId) {
                    addNote({
                      interviewId: interviewData?._id,
                      interviewNote: text,
                      questionId: questionId,
                    });
                  }
                }}
                questionsList={interviewData?.questions || []}
              />
            </Box>

            {selectedStage?.code === "shortlist" &&
              Hire_SubmitInterviewandEditSummary &&
              !interviewData?.draftSummary && (
                <Box className="save_submit_box">
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={isDraftSummaryLoading || isSuccess}
                    onClick={submitForm}
                  >
                    {isDraftSummaryLoading ? (
                      <CircularProgress size={20}></CircularProgress>
                    ) : (
                      "Submit answers"
                    )}
                  </Button>
                </Box>
              )}
          </Box>
        </CandidateHeader>
      </Grid>
    </>
  );
};

export default CandiateInterviewDetails;
