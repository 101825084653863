import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import moment from "moment";
import { FC, PropsWithChildren, useState } from "react";
import { CandidateDetails } from "../../redux/Hire/jobRequestApi";
import { usePermissions } from "../../utils/permission";
import CandidateCommentList from "./CandidateCommentList";
import CandidateMoveDialog from "./CandidateMoveDialog";
import CandidateRejected from "./CandidateRejected";
import CandidateTimeline from "./CandidateTimeline";
import DisqualifyCandidate from "./DisqualifyCandidate";
import { styles } from "./HireStyle";
import ScheduledCallView from "./ScheduledCallView";
import ScheduleRound2View from "./ScheduleRound2View";

type Props = {
  candidateId: number;
  candidateDetail: CandidateDetails;
  selectedCandidate: any;
  selectedStage: any;
  setSelectedStage: (stage: any) => void;
  clientId: number;
  clientName: string;
  jobId: number;
  roleName: string;
  recruiterEmail: string;
  recruiterName: string;
  tabValue: number;
};

const CandidateHeader: FC<PropsWithChildren<Props>> = ({
  selectedCandidate,
  candidateDetail,
  selectedStage,
  candidateId,
  setSelectedStage,
  clientId,
  clientName,
  jobId,
  roleName,
  recruiterEmail,
  recruiterName,
  children,
  tabValue,
}) => {
  const classes = styles();
  const { Hire_AddCandidateActivitycomment, Hire_ScheduleMeet, Hire_MeetInfo } =
    usePermissions();

  const [showScheduleCall, setShowScheduleCall] = useState<boolean>(false);
  const [showRound2Meet, setShowRoud2Meet] = useState<boolean>(false);
  const [showDisqualify, setShowDisqualify] = useState<boolean>(false);
  const [showCommentBox, setShowCommentBox] = useState<boolean>(false);
  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        bgcolor="#F0E9FC"
        height={"56px"}
        px={1}
        alignItems={"center"}
        borderRadius={"10px 10px 0px 0px"}
      >
        <Grid item>
          <Box>
            <Typography variant="h6" component="div">
              {selectedCandidate?.name}
            </Typography>
            <Box
              sx={{ display: "flex" }}
              justifyContent={"space-between"}
              alignContent={"space-between"}
            >
              <Typography variant="caption" sx={{ mr: 4 }}>
                {candidateDetail?.candidateWorkExperiences?.[0]?.title}
              </Typography>
              {candidateDetail?.updatedAt && (
                <Typography variant="caption">
                  {moment(candidateDetail?.updatedAt).format("DD-MM-YYYY")}
                </Typography>
              )}
            </Box>
          </Box>
        </Grid>

        <Grid item>
          <Grid container alignItems={"center"} mr={"10px"} gap={1}>
            {Hire_MeetInfo &&
            tabValue === 2 &&
            selectedStage?.code?.trim() === "clientInterview" ? (
              <Button
                variant="contained"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowRoud2Meet?.(true);
                }}
              >
                Call Details
              </Button>
            ) : null}

            {Hire_AddCandidateActivitycomment && tabValue === 0 ? (
              <IconButton
                title="comments"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowCommentBox?.(true);
                }}
              >
                <img
                  className={classes.qualifiedTimelineIcon}
                  src="/images/comment-icon.svg"
                  alt="icon"
                />
              </IconButton>
            ) : null}
            {candidateDetail?.disqualified ? (
              <Button
                variant={"contained"}
                size="small"
                disabled
                onClick={(e) => {}}
              >
                Qualify Candidate
              </Button>
            ) : null}
            {(selectedStage?.code?.trim() === "shortlist" ||
              selectedStage?.code?.trim() === "screeningByTalentTeam") &&
            !candidateDetail?.disqualified &&
            tabValue === 0 ? (
              <Button
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowDisqualify(true);
                }}
              >
                Disqualify
              </Button>
            ) : null}
            {Hire_ScheduleMeet &&
            selectedStage?.code?.trim() === "screeningByTalentTeam" &&
            !candidateDetail?.disqualified ? (
              <Button
                variant="contained"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowScheduleCall?.(true);
                }}
              >
                Schedule Call
              </Button>
            ) : null}
            {Hire_ScheduleMeet &&
            (selectedStage?.code?.trim() === "shortlist" ||
              selectedStage?.code === "clientEndorsement") &&
            !candidateDetail?.disqualified ? (
              <Button
                variant="contained"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowScheduleCall?.(true);
                }}
              >
                Call Details
              </Button>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
      <Box width={"100%"} height={"calc(100vh - 400px)"} overflow={"auto"}>
        {candidateDetail?.disqualified ? (
          <CandidateRejected candidateDetail={candidateDetail} />
        ) : null}
        {showDisqualify && (
          <DisqualifyCandidate
            candidateId={candidateId}
            jobId={jobId}
            closeDisqualify={() => {
              setShowDisqualify(false);
            }}
            stage={selectedStage}
          />
        )}

        {showCommentBox && (
          <CandidateCommentList
            candidateId={candidateId}
            closeCommentBox={() => {
              setShowCommentBox(false);
            }}
          ></CandidateCommentList>
        )}
        {showRound2Meet && (
          <ScheduleRound2View candidateId={candidateId?.toString()} />
        )}
        {selectedStage?.code === "clientEndorsement" && (
          <CandidateMoveDialog
            candidateId={candidateId?.toString()}
            jobId={jobId.toString()}
            selectedStage={selectedStage}
            setSelectedStage={setSelectedStage}
          ></CandidateMoveDialog>
        )}

        {tabValue === 0 && <CandidateTimeline candidateId={candidateId} />}

        {showScheduleCall && (
          <ScheduledCallView
            candidateId={candidateId}
            candidateDetail={candidateDetail}
            clientName={clientName}
            clientId={clientId}
            jobId={jobId}
            roleName={roleName}
            recruiterEmail={recruiterEmail}
            setSelectedStage={setSelectedStage}
            recruiterName={recruiterName}
            selectedStage={selectedStage?.code}
            closeBox={() => {
              setShowScheduleCall(false);
            }}
          />
        )}

        {children}
      </Box>
    </>
  );
};

export default CandidateHeader;
