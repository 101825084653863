import { Button, Grid, Skeleton, Tooltip, Typography } from "@mui/material";
import moment from "moment";
import { memo, useCallback, useState } from "react";
import { EventObject } from "../../redux/Hire/candidateMeetingApi";
import { useGetJobRequestByIdQuery } from "../../redux/Hire/jobRequestApi";
import RescheduleErrorBook from "./RescheduleErrorBook";
import RescheduleModal from "./RescheduleModal";

const InterviewComponent = ({ interview }: { interview: EventObject }) => {
  const { data, isFetching } = useGetJobRequestByIdQuery({
    jobRequestId: parseInt(interview.jobId),
  });

  const [showError, setShowError] = useState(false);
  const [showReschedule, setShowReschedule] = useState(false);
  const openReschedule = useCallback(() => {
    setShowReschedule(true);
  }, []);
  const closeReschedule = useCallback(() => {
    setShowReschedule(false);
  }, []);

  const showErrorModal = useCallback(() => {
    closeReschedule();
    setShowError(true);
  }, []);
  const closeErrorModal = useCallback(() => {
    setShowError(false);
  }, []);
  return (
    <Grid
      container
      item
      sx={{
        backgroundColor: "#fff",
        boxShadow: "0px 4px 8px 0px #0000000D",
      }}
      gap={1}
      px={6}
      py={2}
    >
      <RescheduleModal
        interviewerEmail={interview.interviewerEmail}
        interviewerName={interview.interviewerName}
        interviewId={interview.id}
        open={showReschedule}
        closeModal={closeReschedule}
        showError={showErrorModal}
      ></RescheduleModal>
      <RescheduleErrorBook closeModal={closeErrorModal} open={showError} />
      <Grid item xs={2}>
        <Typography variant="body1">
          {moment(interview.meetingStartedOn).format("MM-DD-yyyy (dddd)")}
        </Typography>
        <Typography variant="body1">
          {moment.utc(interview.meetingStartedOn).local().format("hh:mm")} to{" "}
          {moment.utc(interview.meetingEndedOn).local().format("hh:mm")} (
          {Intl.DateTimeFormat().resolvedOptions().timeZone})
        </Typography>
      </Grid>
      <Grid item xs={2}>
        {isFetching ? (
          <Skeleton width={"30px"}></Skeleton>
        ) : (
          <Typography variant="body1">{data?.data.title}</Typography>
        )}
      </Grid>
      <Grid item xs={1.5}>
        <Typography variant="body1">{interview.candidateName}</Typography>
      </Grid>
      <Grid item xs={1.5}>
        <Typography variant="body1">{interview.interviewerName}</Typography>
      </Grid>
      <Grid item xs={1.5}>
        <Tooltip title={interview.attendeesEmail}>
          <Typography variant="body1">
            {interview.attendeesEmail.split(",").length} people
          </Typography>
        </Tooltip>
      </Grid>
      <Grid item xs={1.5}>
        <Button
          variant="contained"
          disabled={moment().isAfter(moment(interview.meetingEndedOn))}
          onClick={() => {
            window.open(interview.link, "_blank");
          }}
        >
          Google Meet
        </Button>
      </Grid>
      {moment().isBefore(moment(interview.meetingEndedOn)) && (
        <Grid item xs={1}>
          <Button variant="text" onClick={openReschedule}>
            Reschedule
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default memo(InterviewComponent);
