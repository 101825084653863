import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import { Button, Grid, IconButton, Typography } from "@mui/material";
import moment from "moment";
import { FC, memo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { usePermissions } from "../../utils/permission";

type Props = {
  startDate: moment.Moment;
  endDate: moment.Moment;
  goToNextWeek: () => void;
  goToPrevWeek: () => void;
  goToThisWeek: () => void;
};

const InterviewHeader: FC<Props> = ({
  startDate,
  endDate,
  goToNextWeek,
  goToPrevWeek,
  goToThisWeek,
}) => {
  const now = moment();
  let week = "Current";
  const { Interview_BookSlots } = usePermissions();
  const navigate = useNavigate();
  const goToTimeSlots = useCallback(() => {
    navigate("/set-slot");
  }, [navigate]);

  if (now.isBefore(startDate)) {
    week = "Upcoming";
  }

  if (now.isAfter(endDate.clone().weekday(7))) {
    week = "Previous";
  }

  return (
    <Grid container justifyContent={"space-between"}>
      <Grid container gap={2} alignItems={"center"} item xs={6}>
        <IconButton onClick={goToPrevWeek}>
          <NavigateBefore></NavigateBefore>
        </IconButton>
        <Typography variant="body1">
          {`${moment(startDate).format("MM-DD-yyyy")} to ${moment(endDate).format("MM-DD-yyyy")}`}
        </Typography>
        <IconButton onClick={goToNextWeek}>
          <NavigateNext></NavigateNext>
        </IconButton>
        {moment().isBetween(startDate, endDate, null, "[]") ? null : (
          <Button variant={"outlined"} onClick={goToThisWeek}>
            Today
          </Button>
        )}
      </Grid>
      <Grid
        container
        item
        gap={2}
        xs={6}
        justifyContent={"flex-end"}
        alignItems={"center"}
      >
        {Interview_BookSlots && (
          <Button variant="contained" onClick={goToTimeSlots}>
            Book and edit slots
          </Button>
        )}
        <Button variant="outlined">Completed Calls</Button>
        <Typography variant="body2">{week} week</Typography>
      </Grid>
    </Grid>
  );
};

export default memo(InterviewHeader);
