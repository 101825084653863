import { CircularProgress, Grid, Link, Typography } from "@mui/material";
import jwtDecode from "jwt-decode";
import moment from "moment";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { useSearchParams } from "react-router-dom";
import {
  Round,
  useAddInterviewMeetingAuthMutation,
} from "../../redux/Hire/candidateMeetingApi";
import { GetAvailableTimeslotsData } from "../../redux/Hire/hireApi";
import {
  getAllMeetingsForCandidate,
  getAuthToken,
  getEmailRef,
} from "../../services/OnBoarding";
import { userAccess } from "../../utils/CommonFunction";
import SlotTable from "./SlotTable";

export type Handle = {
  refetch: () => {};
};

type RefData = {
  aud: string;
  candidateEmail: string;
  candidateId: string;
  candidateName: string;
  clientId: string;
  companyDetail: string;
  exp: number;
  iss: string;
  jobId: string;
  jobTitle: string;
  nbf: number;
  recruiterEmail: string;
  jobIdInterval: string;
  recruiterName: string;
  employerName: string;
  employerEmail: string;
};

const ReserveSlot = () => {
  const [queryParams, _] = useSearchParams();
  const startDateParam = queryParams.get("startDate");
  const endDateParam = queryParams.get("endDate");

  const refForSlots = useRef<Handle>(null);
  const [guestToken, setGuestToken] = useState<string | null>(null);
  const [refData, setRefData] = useState<any | null>(null);
  const [isSuccessRefData, setIsSuccessRefData] = useState<boolean>(false);
  const [isLoadingRefData, setIsLoadingRefData] = useState<boolean>(true);
  const [decodedRefData, setDecodedRefData] = useState<RefData>();

  const [meetingData, setMeetingData] = useState<any | null>(null);
  const [isSuccessMeetingData, setIsSuccessMeetingData] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  //TODO TBR: Hotfix - sending header token in getEmailRef Api
  useEffect(() => {
    //get auth token
    const fetchTokenAnEmailRef = async () => {
      try {
        const tokenResult: any = await getAuthToken();
        const token_ = tokenResult?.data.token;
        const refDataResult = await getEmailRef(
          queryParams.get("ref") ?? "",
          token_,
        );
        setGuestToken(token_);
        setRefData({
          data: refDataResult,
        });
        setIsSuccessRefData(true);
      } catch (error) {
        setIsSuccessRefData(false);
      } finally {
        setIsLoadingRefData(false);
      }
    };
    fetchTokenAnEmailRef();
  }, [queryParams]);

  const fetchData = useCallback(async () => {
    if (!decodedRefData) {
      return;
    }
    setIsLoading(true);
    try {
      const meetingsDataResult = await getAllMeetingsForCandidate(
        decodedRefData?.candidateId || "",
        Round.Round2,
        guestToken ?? "",
      );
      setMeetingData(meetingsDataResult);
      setIsSuccessMeetingData(true);
    } catch (error) {
      setIsSuccessMeetingData(false);
    } finally {
      setIsLoading(false);
    }
  }, [decodedRefData, guestToken]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  //TODO TBR: Code commented for hotfix
  /*const {
    data: refData,
    isSuccess: isSuccessRefData,
    isLoading: isLoadingRefData,
  } = useGetRefQuery({
    refId: queryParams.get("ref") || "",
    operation: "",
    routeName: "",
  });*/

  useEffect(() => {
    if (isSuccessRefData) {
      setDecodedRefData(jwtDecode<RefData>(refData?.data.data || ""));
    }
  }, [isSuccessRefData, refData]);

  //TODO TBR: Code commented for hotfix
  /*const {
    data: meetingData,
    isSuccess: isSuccessMeetingData,
    isLoading,
    refetch: refetchMeetings,
  } = useGetAllMeetingsQuery(
    {
      candidateId: decodedRefData?.candidateId,
      round: Round.Round2,
    },
    {
      skip: !decodedRefData,
    },
  );*/

  const startDate = useRef<moment.Moment>(
    startDateParam
      ? moment(startDateParam, "MM-DD-yyyy")
      : moment().add({ day: 1 }).set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        }),
  );
  const endDate = useRef<moment.Moment>(
    endDateParam
      ? moment(endDateParam, "MM-DD-yyyy")
      : moment()
          .add({ week: 1 })
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .weekday(5),
  );

  const alert = useAlert();

  const [loading, setLoading] = useState(false);

  const [addInterview, addInterviewMutating] =
    useAddInterviewMeetingAuthMutation();

  useEffect(() => {
    if (addInterviewMutating.isSuccess) {
      alert.success("Meeting created Successfully");
      fetchData(); //TODO TBR: Code commented for hotfix
    }
    if (addInterviewMutating.isError) {
      setLoading(false);
      alert.error("Error while adding invite");
    }
  }, [alert, addInterviewMutating]);

  const onBookClick = useCallback(
    (slot: GetAvailableTimeslotsData) => {
      setLoading(true);
      const attendeesEmail = new Set<string>([
        decodedRefData?.candidateEmail || "",
        slot.employerEmail,
        decodedRefData?.employerEmail || "",
        decodedRefData?.recruiterEmail || "",
      ]);
      if (decodedRefData) {
        addInterview({
          attendeesEmail: Array.from(attendeesEmail)
            .filter((el) => el)
            .filter((el) => el !== "null"),
          candidateId: decodedRefData?.candidateId || "",
          candidateName: decodedRefData?.candidateName || "",
          clientId: decodedRefData?.clientId || "",
          clientName: decodedRefData?.companyDetail || "",
          description: "",
          jobId: decodedRefData?.jobId || "",
          meetingStartDate: moment.utc(slot.slotSetOn).local().toISOString(),
          meetingEndDate: moment
            .utc(slot.slotSetOn)
            .local()
            .add({ minute: slot.durationInMin })
            .toISOString(),
          slotId: slot.id.toString(),
          updatedBy: userAccess().userId,
          role: decodedRefData?.jobTitle || "",
          scheduledBy: decodedRefData?.candidateId || "",
          interviewerName: slot.employerName,
          interviewerEmail: slot.employerEmail,
          employerEmail: decodedRefData.employerEmail,
          employerName: decodedRefData.employerName,
          round: Round.Round2,
          title: "2nd Round Interview",
          userId: decodedRefData?.candidateId || "",
          candidateEmail: decodedRefData?.candidateEmail || "",
          recruiterEmail: decodedRefData?.recruiterEmail || "",
          recruiterName: decodedRefData?.recruiterName || "",
          accessToken: guestToken || null,
        });
      }
    },
    [addInterview, decodedRefData, guestToken],
  );

  return (
    <Grid
      container
      direction={"column"}
      width={"80vw"}
      m={"0 auto"}
      pt={4}
      gap={2}
    >
      {(isLoading || isLoadingRefData) && (
        <Grid container item justifyContent={"center"} alignItems={"center"}>
          <CircularProgress size={36} />
        </Grid>
      )}
      {isSuccessMeetingData && meetingData.data.length === 0 && (
        <>
          <Grid>
            <Typography variant="h4">Choose a time slot</Typography>
          </Grid>
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography variant="body2">
              Week: {startDate.current.format("MM-DD-yyyy")} to{" "}
              {endDate.current.format("MM-DD-yyyy")}
            </Typography>
            <Typography variant="body2">
              Interview Time: {decodedRefData?.jobIdInterval || 30} min
            </Typography>
          </Grid>
          <Grid container maxHeight={"60vh"} overflow={"auto"}>
            <SlotTable
              clientId={decodedRefData?.clientId || ""}
              accessToken={guestToken ?? null}
              startDate={startDate.current}
              endDate={endDate.current}
              loading={loading}
              onBookClick={onBookClick}
              interval={parseInt(decodedRefData?.jobIdInterval || "30")}
              ref={refForSlots}
            ></SlotTable>
          </Grid>
          <Grid>
            <Typography variant="caption" fontWeight={"bold"}>
              Note: The call will be recorded for safety and analysis purpose
            </Typography>
          </Grid>
        </>
      )}
      {isSuccessMeetingData && meetingData.data.length > 0 && (
        <Grid
          container
          item
          justifyContent={"center"}
          alignItems={"center"}
          direction={"column"}
        >
          <Typography>Hi {decodedRefData?.candidateName},</Typography>

          <Typography>
            You have already scheduled a call with the interviewer.
          </Typography>

          <Typography>
            Please join the{" "}
            <Link href={meetingData.data[0].link}>Google meet</Link> here on{" "}
            {moment
              .utc(meetingData.data[0].meetingStartedOn)
              .local()
              .format("ddd Do MMM yyyy, hh:mm a")}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default memo(ReserveSlot);
