import { Grid, Typography } from "@mui/material";
import moment from "moment-timezone";

import { Tooltip, tooltipClasses } from "@mui/material";
import { styled } from "@mui/styles";

type Props = {
  event: {
    end: Date;
    start: Date;
    title: string;
    id: number | string;
  };
};

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    border: "1px solid #dadde9",
  },
}));

const Event = ({ event }: Props) => {
  const start = moment(event.start);
  const end = moment(event.end);

  return (
    <HtmlTooltip
      title={
        <>
          <Typography
            fontSize={"12px"}
            lineHeight={"20px"}
            letterSpacing={"0.1px"}
            fontWeight={"700"}
            align="center"
          >
            {event.title}
          </Typography>
          <Typography
            fontSize={"12px"}
            lineHeight={"20px"}
            letterSpacing={"0.1px"}
            fontWeight={"700"}
            align="center"
          >
            Local time - {start.format("hh:mm a")} to {end.format("hh:mm a")}
          </Typography>
          <Typography
            fontSize={"12px"}
            lineHeight={"20px"}
            letterSpacing={"0.1px"}
            fontWeight={"700"}
            align="center"
          >
            India - {start.tz("Asia/Kolkata").format("hh:mm a")} to{" "}
            {end.tz("Asia/Kolkata").format("hh:mm a")}{" "}
          </Typography>
          <Typography
            fontSize={"12px"}
            lineHeight={"20px"}
            letterSpacing={"0.1px"}
            fontWeight={"700"}
            align="center"
          >
            Philippines - {start.tz("Asia/Manila").format("hh:mm a")} to{" "}
            {end.tz("Asia/Manila").format("hh:mm a")}
          </Typography>
        </>
      }
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        gap={1}
        direction={"column"}
        height={"100%"}
        px={2}
      >
        <Typography
          fontSize={"12px"}
          lineHeight={"20px"}
          letterSpacing={"0.1px"}
          fontWeight={"700"}
          align="center"
        >
          India - {start.tz("Asia/Kolkata").format("hh:mm a")} to{" "}
          {end.tz("Asia/Kolkata").format("hh:mm a")}{" "}
        </Typography>
        <Typography
          fontSize={"12px"}
          lineHeight={"20px"}
          letterSpacing={"0.1px"}
          fontWeight={"700"}
          align="center"
        >
          Philippines - {start.tz("Asia/Manila").format("hh:mm a")} to{" "}
          {end.tz("Asia/Manila").format("hh:mm a")}
        </Typography>
      </Grid>
    </HtmlTooltip>
  );
};

export default Event;
