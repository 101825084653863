import { CircularProgress, Grid, Typography } from "@mui/material";
import { memo, useEffect } from "react";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";
import { TransitionGroup } from "react-transition-group";
import {
  Round,
  useGetAllMeetingsQuery,
} from "../../redux/Hire/candidateMeetingApi";
import { userAccess } from "../../utils/CommonFunction";
import InterviewComponent from "./InterviewComponent";
const InterviewBody = ({
  startDate,
  endDate,
}: {
  startDate: moment.Moment;
  endDate: moment.Moment;
}) => {
  //Set the date time to 00:00:00.000 UTC, Sets time to 00:00:00.000
  const startDate__ = startDate.utc().startOf("day");
  const endDate__ = endDate.utc().endOf("day");

  //@ts-ignore
  const { clientCode } = useSelector((state) => state.client);
  const { data, isFetching, isError, refetch } = useGetAllMeetingsQuery(
    {
      clientId: clientCode || userAccess().ClientId.toString(),
      round: Round.Round2,
      startDate: startDate__.toISOString(),
      endDate: endDate__.toISOString(),
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );
  const alert = useAlert();

  useEffect(() => {
    if (isError) {
      alert.error("Something went wrong while fetching interviews");
    }
  }, [alert, isError]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  return isFetching ? (
    <Grid
      container
      item
      justifyContent={"center"}
      alignItems={"center"}
      height={"100%"}
    >
      <CircularProgress size={36}></CircularProgress>
    </Grid>
  ) : (
    <Grid container direction={"column"} mb={4}>
      <Grid
        container
        item
        sx={{
          backgroundColor: "#fff",
          boxShadow: "0px 4px 8px 0px #0000000D",
          height: "54px",
        }}
        gap={1}
        px={6}
        py={2}
        mb={2}
      >
        <Grid item xs={2}>
          <Typography variant="h5">Date & Time</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h5">Job Title</Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography variant="h5">Candidate name</Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography variant="h5">Scheduled by</Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography variant="h5">Attended by</Typography>
        </Grid>
      </Grid>

      <Grid container item>
        <TransitionGroup
          style={{
            display: "flex",
            flexDirection: "column-reverse",
            flex: 1,
            gap: "16px",
          }}
        >
          {data?.data.map((interview) => (
            <InterviewComponent
              interview={interview}
              key={interview.id}
            ></InterviewComponent>
          ))}
        </TransitionGroup>
      </Grid>
    </Grid>
  );
};

export default memo(InterviewBody);
