import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import Layout from "../../../shared/components/LayoutV2";

import { useAlert } from "react-alert";
import { momentLocalizer } from "react-big-calendar";
import {
  useGetAvailableSlotsQuery,
  useSetSlotsAvailabilityMutation,
  useUpdateSlotsAvailabilityMutation,
} from "../../../redux/Hire/hireApi";
import { userAccess } from "../../../utils/CommonFunction";
import { TimeGridHeader } from "../TimeslotBooking/TimeGridHeader";
import DragAndDropCalendar, { SlotEvent } from "./DragNDropCalendar";
import { TimeSlot } from "./types";

const localizer = momentLocalizer(moment);

const TimeslotBooking = () => {
  const alert = useAlert();
  const now = moment();
  const monday = now.clone().weekday(1);
  const saturday = now.clone().weekday(6);

  const [modifiedSlotList, setModifiedSlotList] = useState<Array<SlotEvent>>(
    [],
  );

  const [startDate, setStartDate] = useState(monday);
  const [endDate, setEndDate] = useState(saturday);

  const goToNextWeek = useCallback(() => {
    setStartDate((prev) => prev.clone().add({ week: 1 }));
    setEndDate((prev) => prev.clone().add({ week: 1 }));
  }, []);
  const goToPrevWeek = useCallback(() => {
    setStartDate((prev) => prev.clone().subtract({ week: 1 }));
    setEndDate((prev) => prev.clone().subtract({ week: 1 }));
  }, []);

  const goToThisWeek = useCallback(() => {
    setStartDate(monday);
    setEndDate(saturday);
  }, []);

  const { data } = useGetAvailableSlotsQuery({
    slotStartedOn: startDate
      .set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
      .toISOString(),
    slotEndedOn: endDate
      .set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
      .toISOString(),
    clientId: userAccess().ClientId.toString(),
    employerEmail: userAccess().email,
  });

  const setEventsList = useCallback((events: Array<SlotEvent>) => {
    setModifiedSlotList(events);
  }, []);

  const [setAvailability, setSlotMutating] = useSetSlotsAvailabilityMutation();
  const [updateSlot, updateSlotMutating] = useUpdateSlotsAvailabilityMutation();

  useEffect(() => {
    if (setSlotMutating.isSuccess) alert.success("Timeslot added successfully");

    if (setSlotMutating.isError)
      alert.error("An error occured when adding timeslot");
  }, [alert, setSlotMutating]);

  useEffect(() => {
    if (updateSlotMutating.isSuccess)
      alert.success("Timeslot updated successfully");

    if (updateSlotMutating.isError)
      alert.error("An error occured when updating timeslot");
  }, [alert, updateSlotMutating]);

  const availableTimeSlotsFetched: TimeSlot[] = useMemo(() => {
    if (!data) return [];
    const mapped: TimeSlot[] = data.data.map((slot) => ({
      startTime: moment(slot.slotSetOn)
        .set("hours", 0)
        .set("minutes", 0)
        .add(Number(slot.slotSetAt.split(":")[0]), "hours")
        .add(
          Number(slot.slotSetAt.split(":")[1]) + moment().utcOffset(),
          "minutes",
        )
        .toDate(),
      duration: slot.durationInMin,
      id: slot.id,
      isFetched: true,
      isAvailable: slot.isAvailable,
    }));
    return mapped;
  }, [data]);

  const handleBookTimeslot = useCallback(() => {
    const newTimeSlots: Array<TimeSlot> = modifiedSlotList
      .filter((slot) => slot.newSlot)
      .map((event) => {
        const id_ = event.id.split("::")[0];
        return {
          duration: moment(event.end).diff(moment(event.start), "minutes"),
          id: Number(id_),
          isFetched: false,
          startTime: moment(event.start).toDate(),
          isAvailable: event.isAvailable,
        };
      });

    const updatedSlots: Array<TimeSlot> = modifiedSlotList
      .filter((slot) => !slot.newSlot && slot.updated)
      .map((event) => {
        const id_ = event.id.split("::")[0];
        return {
          duration: moment(event.end).diff(moment(event.start), "minutes"),
          id: Number(id_),
          isFetched: false,
          startTime: moment(event.start).toDate(),
          isAvailable: event.isAvailable,
        };
      });

    if (updatedSlots.length && data && data.data.length) {
      updatedSlots.forEach((nts) => {
        const findFromList = data.data.find((slot) => slot.id === nts.id);
        if (findFromList) {
          updateSlot({
            ...findFromList,
            durationInMin: nts.duration,
            slotSetOn: nts.startTime.toISOString(),
            slotSetAt: moment(nts.startTime).utc(false).format("HH:mm"),
            reschduleSlotLink: "",
            slotId: findFromList.id,
          });
        }
      });
    }

    if (newTimeSlots.length)
      setAvailability({
        employerEmail: userAccess().email,
        employerName: userAccess().firstName + " " + userAccess().lastName,
        slots: newTimeSlots.map((nts) => ({
          id: nts.id,
          clientId: userAccess().ClientId.toString(),
          durationInMin: nts.duration,
          interviewId: 0,
          isAvailable: true,
          jobId: "",
          slotSetOn: nts.startTime.toISOString(),
          slotSetAt: moment(nts.startTime).utc(false).format("HH:mm"),
        })),
      });
  }, [data, modifiedSlotList, setAvailability, updateSlot]);

  return (
    <Layout
      header={
        <TimeGridHeader
          endDate={endDate}
          startDate={startDate}
          goToNextWeek={goToNextWeek}
          goToPrevWeek={goToPrevWeek}
          goToThisWeek={goToThisWeek}
          handleTimeslotBookClick={handleBookTimeslot}
          loadingSlot={
            setSlotMutating.isLoading || updateSlotMutating.isLoading
          }
        />
      }
      body={
        <DragAndDropCalendar
          localizer={localizer}
          startDate={startDate}
          endDate={endDate}
          slots={availableTimeSlotsFetched}
          updatedEvents={modifiedSlotList}
          setEventsList={setEventsList}
        />
      }
    />
  );
};

export default TimeslotBooking;
