import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";

type Props = {
  startDate: moment.Moment;
  endDate: moment.Moment;
  goToNextWeek: () => void;
  goToPrevWeek: () => void;
  goToThisWeek: () => void;
  handleTimeslotBookClick: () => void;
  loadingSlot: boolean;
};

export const TimeGridHeader: React.FC<Props> = ({
  endDate,
  goToNextWeek,
  goToPrevWeek,
  goToThisWeek,
  startDate,
  handleTimeslotBookClick,
  loadingSlot,
  children,
}) => {
  return (
    <Box className="header-container">
      <Grid container gap={2} alignItems={"center"} item xs={6}>
        <IconButton onClick={goToPrevWeek}>
          <NavigateBefore></NavigateBefore>
        </IconButton>
        <Typography variant="body1">
          {`${moment(startDate).format("MM-DD-yyyy")} to ${moment(endDate).format("MM-DD-yyyy")}`}
        </Typography>
        <IconButton onClick={goToNextWeek}>
          <NavigateNext></NavigateNext>
        </IconButton>
        {moment().isBetween(startDate, endDate, null, "[]") ? null : (
          <Button variant={"outlined"} onClick={goToThisWeek}>
            Today
          </Button>
        )}
      </Grid>
      <Grid
        container
        alignItems={"center"}
        item
        xs={6}
        justifyContent={"flex-end"}
      >
        <Button
          variant="contained"
          onClick={handleTimeslotBookClick}
          disabled={loadingSlot}
        >
          {loadingSlot ? <CircularProgress size={24} /> : "Book timeslot"}
        </Button>
      </Grid>
    </Box>
  );
};
