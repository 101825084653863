import {
  Button,
  Chip,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Form, Formik } from "formik";
import moment from "moment";
import { FC, memo, useCallback, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import * as yup from "yup";
import {
  AddOrUpdateMeetingRequest,
  EventObject,
  Round,
  useAddInterviewMeetingMutation,
  useUpdateInterviewMeetingMutation,
} from "../../redux/Hire/candidateMeetingApi";
import { useGetJobStatsQuery } from "../../redux/Hire/jobRequestApi";
import {
  useGetTaskForCandidateAtStageQuery,
  useUpdateTaskMutation,
} from "../../redux/Task/TaskApi";
import FormikInput from "../../shared/components/FormikInput";
import { userAccess } from "../../utils/CommonFunction";

const timeGeneration = () => {
  const items: Array<{
    value: number;
    name: string;
  }> = [];

  new Array(24).fill(0).forEach((acc, index) => {
    let time = moment({ hour: index });
    items.push({ value: time.valueOf(), name: time.format("h:mm A") });
    time = time.add({ minutes: 15 });
    items.push({ value: time.valueOf(), name: time.format("h:mm A") });
    time = time.add({ minutes: 15 });
    items.push({ value: time.valueOf(), name: time.format("h:mm A") });
    time = time.add({ minutes: 15 });
    items.push({ value: time.valueOf(), name: time.format("h:mm A") });
  });
  return items;
};

const duration = [
  {
    name: "15 minutes",
    value: 15,
  },
  {
    name: "30 minutes",
    value: 30,
  },
  {
    name: "45 minutes",
    value: 45,
  },
  {
    name: "1 hour",
    value: 60,
  },
];

const validationSchema = yup.object({
  title: yup.string().required("Title is required"),
  description: yup.string(),
  meetingStartDate: yup.string().required("Date is required"),
  interviewerEmail: yup
    .string()
    .email("Invalid email address")
    .required("Please enter Interviewer email"),
  duration: yup.number().required("Duration is required"),
  startTime: yup.number().required("Start time is required"),
});

type Props = {
  candidateId: string;
  candidateName: string;
  clientId: string;
  clientName: string;
  jobId: string;
  roleName: string;
  candidateEmail: string;
  meetingDetails?: EventObject;
  closeReschedule: () => void;
  recruiterName: string;
  recruiterEmail: string;
  setSelectedStage: (stage: any) => void;
  allowStageUpdate?: boolean;
};

const ScheduleCall: FC<Props> = ({
  candidateId,
  candidateName,
  clientId,
  clientName,
  jobId,
  roleName,
  candidateEmail,
  meetingDetails,
  closeReschedule,
  recruiterEmail,
  recruiterName,
  setSelectedStage,
  allowStageUpdate = false,
}) => {
  const [pollingInterval, setPollingInterval] = useState<number>(5000);
  const { data: taskData } = useGetTaskForCandidateAtStageQuery(
    {
      CandidateId: candidateId.toString(),
      JobId: jobId.toString(),
      StageCode: "screeningByTalentTeam",
    },
    {
      skip: !candidateId || !jobId,
      pollingInterval: pollingInterval,
    },
  );
  useEffect(() => {
    if (taskData?.data.result) {
      setPollingInterval(0);
    }
  }, [taskData]);

  const [updateTask, updateTaskMutating] = useUpdateTaskMutation();

  const { data: stagesData } = useGetJobStatsQuery({
    jobRequestId: parseInt(jobId),
  });

  const alert = useAlert();

  const [initialValues] = useState<any>(
    meetingDetails
      ? {
          title: meetingDetails.title,
          description: meetingDetails.description,
          meetingStartDate: moment(new Date(meetingDetails.meetingStartedOn)),
          startTime: moment(meetingDetails.meetingStartedOn).local().valueOf(),
          attendeesEmail: meetingDetails.attendeesEmail.split(","),
          interviewerName: meetingDetails.interviewerName,
          interviewerEmail: meetingDetails.interviewerEmail,
          duration: moment
            .duration(
              moment(meetingDetails.meetingEndedOn).diff(
                moment(meetingDetails.meetingStartedOn),
              ),
            )
            .asMinutes(),
          name: "",
        }
      : {
          title: "1st Round Interview",
          description:
            "We are looking forward to meeting and learning more about you. Our transcription service, fireflies will also be added to the call to help us transcribe the discussion.",
          meetingStartDate: null,
          startTime: "",
          attendeesEmail: [],
          duration: "",
          name: "",
        },
  );

  const [addMeetingApi, { isLoading }] = useAddInterviewMeetingMutation();
  const [
    updateMeeting,
    { isLoading: isUpdateLoading, isSuccess: isUpdateSuccess },
  ] = useUpdateInterviewMeetingMutation();

  useEffect(() => {
    if (isUpdateSuccess) closeReschedule();
  }, [closeReschedule, isUpdateSuccess]);

  const addInvinteAndMoveCandidateToNextRound = useCallback(
    async (meetingRequest: AddOrUpdateMeetingRequest) => {
      try {
        await addMeetingApi(meetingRequest).unwrap();
      } catch (e) {
        alert.error("Error while adding invite");
      }

      try {
        if (
          taskData &&
          taskData.data?.result &&
          stagesData &&
          allowStageUpdate
        ) {
          await updateTask({
            ...taskData?.data.result,
            completedAction: JSON.parse(taskData.data.result.actionsJson)[0].id,
            id: taskData.data.result.taskId,
            completedBy: userAccess().userId,
            completedByName:
              userAccess().firstName + " " + userAccess().lastName,
          }).unwrap();
          setSelectedStage(
            stagesData.data.find((el) => el.code === "shortlist"),
          );
        }
      } catch (e) {
        alert.error("Error while moving candidate to next stage");
      }
    },
    [
      addMeetingApi,
      alert,
      allowStageUpdate,
      setSelectedStage,
      stagesData,
      taskData,
      updateTask,
    ],
  );

  const onSubmit = useCallback(
    async (data) => {
      const attendeesEmail = new Set<string>(
        data.attendeesEmail
          .concat(candidateEmail)
          .concat(data.interviewerEmail)
          .concat(userAccess().email),
      );
      const meetingRequest: AddOrUpdateMeetingRequest = {
        id: meetingDetails ? meetingDetails.id : undefined,
        candidateId,
        candidateName,
        clientId,
        clientName,
        description: data.description,
        interviewerName: data.interviewerEmail,
        interviewerEmail: data.interviewerEmail,
        candidateEmail: candidateEmail,
        recruiterEmail: userAccess().email,
        recruiterName: userAccess().firstName + " " + userAccess().lastName,
        updatedBy: userAccess().userId,
        jobId,
        eventId: meetingDetails ? meetingDetails.eventId : undefined,
        link: meetingDetails ? meetingDetails.link : undefined,
        role: roleName,
        scheduledBy: userAccess().firstName + " " + userAccess().lastName,
        userId: userAccess().userId,
        attendeesEmail: Array.from(attendeesEmail).filter((el) => el),
        meetingStartDate: moment(data.meetingStartDate.toDate())
          .set("hour", moment(data.startTime).hour())
          .set("minute", moment(data.startTime).minute())
          .toISOString(),
        meetingEndDate: moment(data.meetingStartDate.toDate())
          .set("hour", moment(data.startTime).hour())
          .set("minute", moment(data.startTime).minute())
          .add({ minutes: data.duration })
          .toISOString(),
        title: data.title,
        round: Round.Round1,
      };
      if (meetingDetails) {
        updateMeeting(meetingRequest);
      } else {
        addInvinteAndMoveCandidateToNextRound(meetingRequest);
      }
    },
    [
      addInvinteAndMoveCandidateToNextRound,
      candidateEmail,
      candidateId,
      candidateName,
      clientId,
      clientName,
      jobId,
      meetingDetails,
      roleName,
      updateMeeting,
    ],
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {(formikProps) => (
        <Form>
          <Grid container spacing={2}>
            {meetingDetails && (
              <Grid item xs={12}>
                <Typography fontWeight={500} variant="body2">
                  Reschedule the call
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} sm={12}>
              <FormikInput
                label="Add title"
                name="title"
                formikProps={formikProps}
                options={undefined}
                fullWidth
              />
            </Grid>
            <Grid container item xs={12} gap={2}>
              <FormikInput
                label="Add description"
                name="description"
                formikProps={formikProps}
                options={undefined}
                inputType="textarea"
                rows={3}
                multiline
                width={"100%"}
                fullWidth
                className="full-width"
              />
            </Grid>
            <Grid container item xs={12} gap={1} alignItems={"center"}>
              <Grid item xs={2.5}>
                <Typography variant="body2" fontWeight={500}>
                  Select day and time
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <FormikInput
                  inputType="date-picker"
                  label="Interview Date"
                  inputFormat="MM/DD/YYYY"
                  formikProps={formikProps}
                  name="meetingStartDate"
                  options={undefined}
                  disablePast={true}
                />
              </Grid>
              <Grid item xs={3}>
                <FormikInput
                  label="Start time"
                  name="startTime"
                  className="common-select-dropdown"
                  formikProps={formikProps}
                  options={timeGeneration()}
                  inputType="select"
                  fullWidth
                />
              </Grid>

              <Grid item xs={3}>
                <FormikInput
                  label="Duration"
                  name="duration"
                  className="common-select-dropdown"
                  formikProps={formikProps}
                  options={duration}
                  inputType="select"
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} gap={1} alignItems={"center"}>
              <Grid item xs={2.5}>
                <Typography variant="body2" fontWeight={500}>
                  Add Interviewer
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <FormikInput
                  label="Interviewer Email"
                  name="interviewerEmail"
                  formikProps={formikProps}
                  options={undefined}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} gap={1} alignItems={"center"}>
              <Grid item xs={2.5}>
                <Typography variant="body2" fontWeight={500}>
                  Add guest
                </Typography>
              </Grid>
              <Grid container item xs={9}>
                <Grid item xs={12} container alignItems={"center"}>
                  <Grid item flex={1}>
                    <FormikInput
                      label="Attendee Email"
                      name="name"
                      formikProps={formikProps}
                      fullWidth
                      options={undefined}
                    />
                  </Grid>

                  <Button
                    disabled={
                      !formikProps.values.name.length &&
                      !formikProps.values.attendeesEmail.includes(
                        formikProps.values.name,
                      )
                    }
                    variant="text"
                    onClick={() => {
                      formikProps.setFieldValue(
                        "attendeesEmail",
                        formikProps.values.attendeesEmail.concat(
                          formikProps.values.name,
                        ),
                        false,
                      );
                      formikProps.setFieldValue("name", "");
                    }}
                  >
                    Add Guest
                  </Button>
                </Grid>
                <Grid container item xs={12} gap={1}>
                  {formikProps.values.attendeesEmail.map((item) => (
                    <Chip
                      key={item}
                      label={item}
                      onDelete={() => {
                        formikProps.setFieldValue(
                          "attendeesEmail",
                          formikProps.values.attendeesEmail.filter(
                            (e) => e !== item,
                          ),
                        );
                      }}
                    />
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box
            display="flex"
            justifyContent="flex-end"
            sx={{ mt: 3, mb: 2 }}
            gap={2}
          >
            {meetingDetails ? (
              <Button type="button" sx={{ mt: 2 }} onClick={closeReschedule}>
                Cancel
              </Button>
            ) : null}

            <Button
              type="submit"
              disabled={
                isLoading ||
                isUpdateLoading ||
                updateTaskMutating.isLoading ||
                !taskData?.data
              }
              variant="contained"
              sx={{ mt: 2 }}
            >
              {isLoading || isUpdateLoading || updateTaskMutating.isLoading ? (
                <CircularProgress color="inherit" size={20}></CircularProgress>
              ) : meetingDetails ? (
                "Update invite"
              ) : (
                `Send invite${allowStageUpdate ? " and move to next stage" : ""}`
              )}
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default memo(ScheduleCall);
