import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LaunchIcon from "@mui/icons-material/Launch";
import {
  Box,
  FormControlLabel,
  Grid,
  List,
  Paper,
  Switch,
  Typography,
  IconButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/material/styles";
import moment from "moment";
import { memo, useState } from "react";
import Search from "../../shared/components/Search/Search";
import RowSkeleton from "../../shared/components/Skeletons/RowSkeleton";
import { STRINGS } from "../../Strings";
import { styles } from "./HireStyle";

import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 320,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

const ScrollingCandidateList = ({
  candidates,
  isCandidateListLoading,
  showCandidate,
  selectedCandidate,
  moreCandidateListLoading,
  onScrollCandidateList,
  removeRejected,
  setRemoveRejected,
  setSearchInputValue,
  searchToggle,
  setSearchToggle
}) => {
  const classes = styles();
  return (
    <Grid
      container
      item
      xs={12}
      sm={4}
      justifyContent={"flex-start"}
      alignSelf={"flex-start"}
    >
      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        height={"56px"}
        mx={"0.5rem"}
        bgcolor={"#F0E9FC"}
        px={"0.5rem"}
        mb={"1rem"}
        borderRadius={"10px 10px 0px 0px"}
      >
        <Typography variant="h6">Candidates</Typography>
        <FormControlLabel
          control={
            <Switch
              checked={removeRejected}
              onChange={setRemoveRejected}
              name="disqualify"
            />
          }
          label="Disqualified"
          labelPlacement="start"
          componentsProps={{
            typography: {
              fontSize: "14px",
              lineHeight: "32px",
            },
          }}
        />

        <IconButton
          title="Search"
          disableRipple={true}
          onClick={(e) => {
            e.stopPropagation();
            setSearchToggle(!searchToggle);
            if(searchToggle) setSearchInputValue(""); //clear search input value
          }}
        >
          <SearchIcon />
        </IconButton>
      </Grid>

      {searchToggle && (
        <Box px={"0.5rem"} pb={"1rem"} width={"100%"}>
          <Search
            getSearch={(val) => {
              setSearchInputValue(val);
            }}
            closeSearch={() => setSearchInputValue("")}
          />
        </Box>
      )}
      {isCandidateListLoading && (
        <Box px={"0.5rem"} width={"100%"}>
          <Grid display={"flex"} gap={"0.475rem"} py="0.5rem">
            <Grid md={12}>
              <RowSkeleton rowHeight={40} />
            </Grid>
          </Grid>
          {/* <Divider /> */}
        </Box>
      )}
      <Box className="customScroll" px={"0.5rem"} width={"100%"}>
        <Paper
          id={"candidateListRoot"}
          elevation={0}
          style={{
            height: "calc(100vh - 480px)",
            overflow: "auto",
            backgroundColor: "transparent",
            borderRadius: 0,
          }}
          onScroll={onScrollCandidateList}
        >
          <List>
            <>
              {candidates?.length > 0 ? (
                candidates?.map((candidate: any, candidateIndex) => {
                  return (
                    <Box
                      key={candidate.id}
                      onClick={() => showCandidate(candidate)}
                      mb={1}
                      className={classes.cd_list_container}
                      sx={{ cursor: "pointer" }}
                    >
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        sx={{ width: "100%" }}
                      >
                        <Box className={classes.cd_mid_col}>
                          <Typography
                            variant="body2"
                            component="div"
                            fontWeight={"600"}
                            color={
                              candidate?.id === selectedCandidate?.id
                                ? "#A16AE8"
                                : ""
                            }
                          >
                            {candidate?.name}
                          </Typography>
                        </Box>

                        <Box>
                          <HtmlTooltip
                            className="tooltip-candidate-info-box"
                            title={
                              <>
                                <ul className="tooltip-ul-candiate-info">
                                  <li>
                                    <span className="label-span">Email</span>{": "}
                                    <span>{candidate?.email}</span>
                                  </li>
                                  <li>
                                    <span className="label-span">Location</span>{": "}
                                    <span>
                                      {candidate?.location ?? "NA"}
                                    </span>
                                  </li>
                                  <li>
                                    <span className="label-span">Source</span>{": "}
                                    <span>
                                      {candidate?.source ?? "NA"}
                                    </span>
                                  </li>
                                  <li>
                                    <span className="label-span">
                                      Applied on
                                    </span>{": "}
                                    <span>
                                      {candidate?.appliedOn ? candidate?.appliedOn : "NA"}
                                    </span>
                                  </li>
                                </ul>
                              </>
                            }
                          >
                            <InfoOutlinedIcon />
                          </HtmlTooltip>
                        </Box>
                      </Box>

                      <Box
                        className={classes.cd_last_col}
                        display={"flex"}
                        flexDirection={"column"}
                        rowGap={1}
                        alignItems={"flex-end"}
                      >
                        <LaunchIcon
                          className={classes.cursor}
                          sx={{
                            display: {
                              xs: "block",
                              md: "none",
                              lg: "none",
                            },
                          }}
                        />
                        {candidate?.expectedJoiningDate && (
                          <Typography component="div" variant="body2" noWrap>
                            {moment(candidate?.expectedJoiningDate).format(
                              "DD-MM-YYYY",
                            )}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  );
                })
              ) : (
                <Typography variant="caption">
                  {STRINGS.NoRecordFound}
                </Typography>
              )}
            </>

            {moreCandidateListLoading && (
              <Box px={"0.5rem"}>
                <Grid display={"flex"} gap={"0.475rem"} py="0.5rem">
                  <Grid md={12}>
                    <RowSkeleton rowHeight={40} />
                  </Grid>
                </Grid>
                {/* <Divider /> */}
              </Box>
            )}
          </List>
        </Paper>
      </Box>
    </Grid>
  );
};

export default memo(ScrollingCandidateList);
