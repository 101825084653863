import { Grid, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDownloadDocumentQuery } from "../../redux/Documents/documentSlice";
import { STRINGS } from "../../Strings";
import CandidateInfo from "./CandidateInfo";
import CandidateInterviewDetails from "./CandidateInterviewDetails";
import CandidateInterviewClientDetails from "./CandidateInterviewDetails/CandidateInterviewClient";

import {
  useGetCandidateDetailsQuery,
  useGetCandidateListPerStageByJobIdQuery,
  useGetJobRequestByIdQuery,
} from "../../redux/Hire/jobRequestApi";
import CandidateScrollingList from "./CandidateScrollingList";
import TabPanel from "./common/TabPanel";

export const CANDIDATE_PIPELINE_CODE_MAP = {
  APPLIED: "Applied",
  SOURCED: "Screened by TT",
  SHORTLIST: "Shortlist",
  CV_SENT: "Client endorsement",
  INTERVIEW: "Client interview",
  OFFER: "Offer",
  PLACED: "Candidate started",
};

const CandidateListBody = ({
  jobId,
  selectedStage,
  setSelectedStage,
  tabValue,
}) => {
  const [selectedCandidate, setSelectedCandidate] = useState<any>(null);

  const [selectedPage, setSelectedPage] = useState(1);

  const [rejected, setRejected] = useState<boolean>(false);

  const { data } = useGetJobRequestByIdQuery({
    jobRequestId: jobId,
  });

  const [searchInputValue, setSearchInputValue] = useState("");
  const [searchToggle, setSearchToggle] = useState(false);

  const {
    data: candidateListData,
    isLoading,
    isSuccess,
    isFetching,
  } = useGetCandidateListPerStageByJobIdQuery(
    {
      jobId: jobId,
      jobPipeLineCode: selectedStage?.code,
      pageNumber: selectedPage,
      pageSize: 20,
      showDisquilify: rejected,
      searchValue: searchInputValue,
    },
    {
      skip: !selectedStage,
      refetchOnMountOrArgChange: true,
    },
  );

  const { data: candidateDetail, isFetching: candidateDetailPending } =
    useGetCandidateDetailsQuery(
      {
        id: selectedCandidate?.id,
      },
      {
        skip: !selectedCandidate,
      },
    );

  const resumeFileName = candidateDetail?.data?.wResumeURL?.split("/") || [];

  const { data: cvUrl } = useDownloadDocumentQuery(
    {
      fileName: resumeFileName[resumeFileName?.length - 1]?.split("?")[0],
    },
    {
      skip: resumeFileName.length === 0,
      refetchOnMountOrArgChange: true,
    },
  );

  useEffect(() => {
    if (selectedStage?.code) {
      setSelectedPage(1);
      setRejected(false);
      setSearchInputValue("");
      setSearchToggle(false);
    }
  }, [selectedStage]);

  const handleSwitchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRejected(event.target.checked);
    },
    [],
  );

  const showDetails = (candidate) => {
    setSelectedCandidate(candidate);
  };

  useEffect(() => {
    if (selectedStage?.code) setSelectedCandidate(null);
  }, [selectedStage]);

  useEffect(() => {
    if (
      isSuccess &&
      candidateListData.data.candidates.length &&
      selectedCandidate === null
    ) {
      setSelectedCandidate(candidateListData.data.candidates[0]);
    }
  }, [candidateListData, isSuccess, selectedCandidate, selectedStage]);

  const onScrollCandidateList = (event) => {
    if (!isLoading) {
      var element = event.target;
      if (
        Math.ceil(element.scrollHeight) - Math.ceil(element.scrollTop) ===
        Math.ceil(element.clientHeight)
      ) {
        if (
          (candidateListData?.data?.candidates.length || 0) <
          (candidateListData?.data.filter.count || 0)
        ) {
          setSelectedPage((selectedPage) => selectedPage + 1);
        }
      }
    }
  };

  const NoRecordFoundGrid = () => (
    <Grid
      spacing={"10px"}
      alignItems="center"
      mt={"2rem"}
      justifyContent={"center"}
      display={"flex"}
    >
      <Typography variant="h5">{STRINGS.NoRecordFound}</Typography>
    </Grid>
  );

  return isSuccess && candidateListData?.data?.candidates ? (
    <Grid container display={"flex"} spacing={1}>
      <CandidateScrollingList
        candidates={candidateListData?.data.candidates || []}
        isCandidateListLoading={isLoading}
        moreCandidateListLoading={isFetching}
        showCandidate={showDetails}
        selectedCandidate={selectedCandidate}
        setSearchInputValue={setSearchInputValue}
        onScrollCandidateList={onScrollCandidateList}
        removeRejected={rejected}
        setRemoveRejected={handleSwitchChange}
        searchToggle={searchToggle}
        setSearchToggle={setSearchToggle}
      />
      <TabPanel tabValue={tabValue} index={0} sm={8} item>
        {candidateListData?.data.candidates.length === 0 ? (
          <NoRecordFoundGrid />
        ) : (
          <CandidateInfo
            candidateId={selectedCandidate?.id}
            candidateDetail={candidateDetail?.data}
            selectedCandidate={selectedCandidate}
            selectedStage={selectedStage}
            setSelectedStage={setSelectedStage}
            candidateDetailPending={candidateDetailPending}
            clientId={data?.data.clientId}
            clientName={data?.data.client || ""}
            jobId={data?.data?.jobId}
            roleName={data?.data?.title}
            recruiterEmail={data?.data.recruiter}
            recruiterName={data?.data.recruiter}
            cvUrl={cvUrl?.link || ""}
          />
        )}
      </TabPanel>
      <TabPanel tabValue={tabValue} index={1} sm={8} item>
        {candidateListData?.data.candidates.length === 0 ? (
          <NoRecordFoundGrid />
        ) : (
          <CandidateInterviewDetails
            selectedStage={selectedStage}
            jobId={jobId}
            setSelectedStage={setSelectedStage}
            candidateId={selectedCandidate?.id}
            candidateEmail={selectedCandidate?.email}
            candidateDetail={candidateDetail?.data}
            clientId={data?.data.clientId}
            clientName={data?.data.client || ""}
            recruiterEmail={data?.data.recruiter}
            recruiterName={data?.data.recruiter}
            roleName={data?.data?.title}
            selectedCandidate={selectedCandidate}
          />
        )}
      </TabPanel>
      <TabPanel tabValue={tabValue} index={2} sm={8} item>
        {candidateListData?.data.candidates.length === 0 ? (
          <NoRecordFoundGrid />
        ) : (
          <CandidateInterviewClientDetails
            jobId={jobId}
            candidateId={selectedCandidate?.id}
            candidateDetail={candidateDetail}
            clientName={data?.data.client || ""}
            roleName={data?.data?.title}
            recruiterEmail={data?.data.recruiter}
            clientId={data?.data.clientId}
            recruiterName={data?.data.recruiter}
            selectedCandidate={selectedCandidate}
            selectedStage={selectedStage}
            setSelectedStage={setSelectedStage}
          />
        )}
      </TabPanel>
    </Grid>
  ) : (
    <Grid
      container
      spacing={"10px"}
      alignItems="center"
      mt={"2rem"}
      justifyContent={"center"}
    >
      <Typography variant="h5">{STRINGS.NoRecordFound}</Typography>
    </Grid>
  );
};

export default CandidateListBody;
