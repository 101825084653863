import { Button, Grid, Typography } from "@mui/material";
import { JobRequest } from "../../../redux/Hire/jobRequestApi";
import { JobRequestDetailPages } from "./types";

const DetailHeader: React.FC<{
  currentPage: JobRequestDetailPages;
  pages: string[];
  setCurrentPage: any;
  jobRequest: JobRequest;
}> = ({ currentPage, pages, setCurrentPage, jobRequest }) => {
  return (
    <Grid
      container
      px={2}
      justifyContent={"space-between"}
      alignItems={"center"}
      mb={2}
    >
      <Typography variant="subtitle2" fontWeight={500}>
        {currentPage}
        {currentPage === JobRequestDetailPages.ViewRequest ? " Details" : ""}
      </Typography>
      <Grid item>
        {pages
          .filter((page) => page !== JobRequestDetailPages.CancelRequest)
          .map((page) => (
            <Button
              disabled={page === currentPage}
              onClick={() => {
                setCurrentPage(page);
              }}
              key={page}
            >
              {page}
            </Button>
          ))}
        <Button onClick={() => {}}>Clone Request</Button>
        <Button
          color="error"
          disabled={JobRequestDetailPages.CancelRequest === currentPage}
          onClick={() => {
            setCurrentPage(JobRequestDetailPages.CancelRequest);
          }}
        >
          {JobRequestDetailPages.CancelRequest}
        </Button>
      </Grid>
    </Grid>
  );
};

export default DetailHeader;
