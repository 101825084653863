import { useCallback, useEffect, useMemo, useState } from "react";
import { Calendar, DateLocalizer, SlotInfo, Views } from "react-big-calendar";
// Storybook cannot alias this, so you would use 'react-big-calendar/lib/addons/dragAndDrop'
import { Grid } from "@mui/material";
import moment from "moment";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { userAccess } from "../../../utils/CommonFunction";
import CustomWeekView from "./CustomWeekView";
import Day from "./components/Day";
import DeleteEventModal from "./components/DeleteEventModal";
import Event from "./components/Event";
import Timeslot from "./components/Timeslot";
import TimeslotGutterText from "./components/TimeslotGutterText";
import TimeslotGutterWrapper from "./components/TimeslotGutterWrapper";
import { TimeSlot } from "./types";

export type SlotEvent = {
  id: string;
  title: string;
  start: Date;
  end: Date;
  updated?: boolean;
  newSlot?: boolean;
  isAvailable: boolean;
};
const DragAndDropCalendar = withDragAndDrop(Calendar<SlotEvent, any>);

type Props = {
  localizer: DateLocalizer;
  startDate: moment.Moment;
  endDate: moment.Moment;
  slots: Array<TimeSlot>;
  setEventsList: (slots: Array<SlotEvent>) => void;
  updatedEvents: Array<SlotEvent>;
};

export default function DragNDropCalendar({
  localizer,
  startDate,
  slots,
  setEventsList,
  updatedEvents,
}: Props) {
  const memoizedSlots = useMemo(() => {
    return slots.map((slot, index) => ({
      id: slot.id + "::" + index,
      start: moment(slot.startTime).toDate(),
      end: moment(slot.startTime).add({ minutes: slot.duration }).toDate(),
      title: userAccess().firstName + " " + userAccess().lastName + "'s slot",
      isAvailable: slot.isAvailable,
    }));
  }, [slots]);

  const [myEvents, setMyEvents] = useState<Array<SlotEvent>>(memoizedSlots);

  const [selectedEventForDelete, setSelectedEventForDelete] =
    useState<SlotEvent | null>(null);

  useEffect(() => {
    setMyEvents(memoizedSlots);
  }, [memoizedSlots]);

  const moveEvent = useCallback(
    ({ event, start, end }) => {
      setMyEvents((prev) => {
        const existing = prev.find((ev) => ev.id === event.id);
        const filtered = prev.filter((ev) => ev.id !== event.id);
        if (
          existing &&
          moment(start).diff(moment()) > 0 &&
          moment(start).diff(moment()) > 0
        ) {
          const list = [
            ...filtered,
            {
              ...existing,
              start,
              end,
              updated: true,
              id: existing.id,
              title: existing.title,
            },
          ];
          setEventsList(list);
          return list;
        } else return prev;
      });
    },
    [setEventsList],
  );

  const resizeEvent = useCallback(
    ({ event, start, end }) => {
      setMyEvents((prev) => {
        const existing = prev.find((ev) => ev.id === event.id);
        const filtered = prev.filter((ev) => ev.id !== event.id);
        if (
          existing &&
          moment(start).diff(moment()) > 0 &&
          moment(start).diff(moment()) > 0
        ) {
          const list = [
            ...filtered,
            {
              ...existing,
              start,
              end,
              updated: true,
              id: existing.id,
              title: existing.title,
            },
          ];
          setEventsList(list);
          return list;
        } else return prev;
      });
    },
    [setEventsList],
  );

  const { scrollToTime, views, components, min, max } = useMemo(
    () => ({
      scrollToTime: new Date(1972, 0, 1, 8),
      views: {
        week: CustomWeekView,
      },
      components: {
        // dateCellWrapper: Day,
        timeSlotWrapper: Timeslot,
        timeGutterHeader: TimeslotGutterText,
        timeGutterWrapper: TimeslotGutterWrapper,
        week: {
          header: Day,
          event: Event,
        },
      },
      min: new Date(1972, 0, 1, 7, 0, 0, 0),
      max: new Date(1972, 0, 1, 22, 0, 0, 0),
    }),
    [],
  );

  const eventPropGetter = useCallback(
    (event, start, end, isSelected) => ({
      style: {
        backgroundColor: "#F0E9FC",
        border: "1px solid #6B7478",
        borderRadius: 5,

        color: "#000",
        ...(moment(start).diff(moment()) < 0 && {
          backgroundColor: "#CCD3D5",
          color: "#707D84",
        }),
        ...(!event.isAvailable && {
          backgroundColor: "#CCD3D5",
          color: "#707D84",
        }),
      },
    }),
    [],
  );

  const handleSelectSlot = useCallback(
    ({ start, end, action }: SlotInfo) => {
      if (
        action === "select" &&
        moment(start).diff(moment()) > 0 &&
        moment(start).diff(moment()) > 0
      ) {
        const title =
          userAccess().firstName + " " + userAccess().lastName + "'s slot";
        if (title) {
          setMyEvents((prev) => {
            if (
              moment(start).diff(moment()) > 0 &&
              moment(start).diff(moment()) > 0
            ) {
              const list = [
                ...prev,
                {
                  start,
                  end,
                  title,
                  id: Date.now().toString(),
                  newSlot: true,
                  isAvailable: true,
                },
              ];

              setEventsList(list);
              return list;
            }
            return prev;
          });
        }
      }
    },
    [setEventsList],
  );

  const handleKeyPressEvent = useCallback(
    (event: SlotEvent, e: any) => {
      if ((e.key === "Backspace" || e.key === "Delete") && event.newSlot) {
        setMyEvents((prev) => {
          const indexOfSlot = prev.findIndex((e) => e.id === event.id);
          const newList = [...prev];
          newList.splice(indexOfSlot, 1);
          setEventsList(newList);
          return newList;
        });
      }
      if ((e.key === "Backspace" || e.key === "Delete") && !event.newSlot) {
        setSelectedEventForDelete(event);
      }
    },
    [setEventsList],
  );

  const closeModal = useCallback(() => {
    setSelectedEventForDelete(null);
  }, []);

  const accessorForCalendar = useCallback(
    (event: SlotEvent) => event.isAvailable,
    [],
  );

  return (
    <Grid mb={4} direction={"column"} container gap={2}>
      <DeleteEventModal
        event={selectedEventForDelete}
        closeModal={closeModal}
      ></DeleteEventModal>
      <div className="height600">
        <DragAndDropCalendar
          min={min}
          max={max}
          date={startDate.toDate()}
          defaultView={Views.WEEK}
          events={myEvents}
          localizer={localizer}
          eventPropGetter={eventPropGetter}
          onEventDrop={moveEvent}
          onEventResize={resizeEvent}
          resizable
          onSelectSlot={handleSelectSlot}
          scrollToTime={scrollToTime}
          showMultiDayTimes={true}
          onKeyPressEvent={handleKeyPressEvent}
          step={15}
          timeslots={4}
          selectable
          resizableAccessor={accessorForCalendar}
          draggableAccessor={accessorForCalendar}
          toolbar={false}
          views={views}
          components={components}
        />
      </div>
    </Grid>
  );
}
