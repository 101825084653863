import { createApi } from "@reduxjs/toolkit/dist/query/react";
import axiosBaseQuery from "../../services/service";

const BASE_URL_ONBOARDING = process.env.REACT_APP_ONBAORDING_URL;

type DefaultResponse<T> = {
  data: T;
  message: string;
  status: number;
  successful: boolean;
};

type GetUsersRequest = {
  pageNumber: number;
  pageSize: number;
  clientId: number;
  searchValue: string;
  roles: Array<string>;
  disabled: boolean;
};

type User = {
  userId: string;
  tokenId: string;
  userName: string;
  email: string;
  firstName: string;
  lastName: string;
  companyName: string;
  companyId: number;
  role: string;
  country: string;
  isRegistered: boolean;
  isActive: boolean;
  isInvitationNotAccepted: boolean;
  isInvitationExpired: boolean;
};

type UserProfile = {
  address: null;
  companyId: number;
  country: null | string;
  designation: null | string;
  firstName: null | string;
  lastName: null | string;
  phoneNumber: null | string;
  profilePicture: null | string;
  profilePictureFile: null | string;
  role: string;
  timeZone: null | string;
  userId: string;
};
type GetUsersResponse = DefaultResponse<{
  paginationFilter: {
    pageNumber: number;
    pageSize: number;
    count: number;
  };
  users: Array<User>;
}>;

type GetUserRequest = {
  userId: string;
};

type GetUserResponse = DefaultResponse<UserProfile>;
type GetRolesRequest = {
  clientId: number;
};
type GetRolesResponse = DefaultResponse<
  Array<{
    id: string;
    name: string;
  }>
>;

type UserInfo = {
  address: string;
  emailAddress: string;
  firstName: string;
  isActive: boolean;
  phoneNumber: string;
  roles: Array<string>;
  uId: string;
};

type AvailableRolesAndUsersRequest = {
  clientId: number;
};

type AvailableRolesAndUsersResponse = DefaultResponse<{
  roleList: Array<string>;
  teamMembersList: Array<UserInfo>;
  userList: Array<UserInfo>;
}>;

type GetRefRequest = {
  refId: string;
  operation?: string;
  routeName?: "";
};
type GetRefResponse = DefaultResponse<{
  data: string;
  isRg: boolean;
  message: string;
}>;

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: axiosBaseQuery({
    baseUrl: BASE_URL_ONBOARDING || "",
  }),

  endpoints: (builder) => ({
    getFilteredUsers: builder.query<GetUsersResponse, GetUsersRequest>({
      query: (req) => ({
        url: "getFilteredUsers",
        method: "POST",
        body: req,
      }),
    }),
    getProfile: builder.query<GetUserResponse, GetUserRequest>({
      query: (req) => ({
        url: "GetProfile",
        method: "POST",
        body: req,
      }),
    }),
    getAllRoles: builder.query<GetRolesResponse, GetRolesRequest>({
      query: (req) => ({
        url: "getAllRoles",
        method: "POST",
        body: req,
      }),
    }),
    getOnlyAvailableRolesAndUsersForClient: builder.query<
      AvailableRolesAndUsersResponse,
      AvailableRolesAndUsersRequest
    >({
      query: (req) => ({
        url: "getOnlyAvailableRolesAndUsersForClient",
        method: "post",
        body: req,
      }),
    }),
    getRef: builder.query<GetRefResponse, GetRefRequest>({
      query: (req) => ({
        url: "getEmailRef",
        method: "post",
        body: req,
      }),
    }),
  }),
});

export const {
  useGetFilteredUsersQuery,
  useGetProfileQuery,
  useGetAllRolesQuery,
  useGetOnlyAvailableRolesAndUsersForClientQuery,
  useGetRefQuery,
} = userApi;
