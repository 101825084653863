import {
  Box,
  Grid,
  MenuItem,
  Pagination,
  Select,
  Typography,
} from "@mui/material";
import { uuid4 } from "@sentry/core";
import React from "react";
import AccordionRow from "./AccordionRow";
import AccordionTableSkeleton from "./AccordionSkeleton";

type AccordionTableProps<T> = {
  headers?: string[];
  headersElement?: (header: string) => React.JSX.Element;
  dataRow: T[];
  rowDetail: (param: T) => React.JSX.Element;
  rowDetailExpand: (param: T) => React.JSX.Element;
  onExpand?: () => void;
  pagination?: {
    pageSize: number;
    pageNumber: number;
    totalRowCount: number;
    handlePageChange: (param) => void;
    handlePageSizeChange: (param) => void;
  };
  isLoading?: boolean;
};

const AccordionTable = <T,>({
  headers,
  headersElement,
  dataRow,
  rowDetail,
  rowDetailExpand,
  onExpand,
  pagination,
  isLoading,
}: AccordionTableProps<T>) => {
  if (dataRow.length === 0 && !isLoading)
    return (
      <Box>
        <Grid container direction={"column"}>
          <Grid
            container
            item
            sx={{
              height: "calc(100vh - 290px)",
              overflow: "auto",
              minWidth: 1080,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                bgcolor: "#fff",
                width: "100%",
              }}
            >
              No Data Available
            </Box>
          </Grid>
        </Grid>
      </Box>
    );

  return (
    <Box>
      <Grid container direction={"column"}>
        <Grid
          item
          sx={{
            height: "calc(100vh - 290px)",
            overflow: "auto",
            minWidth: 1080,
          }}
        >
          {headers ? (
            <Grid
              container
              item
              sx={{
                backgroundColor: "#fff",
                boxShadow: "0px 4px 8px 0px #0000000D",
                position: "sticky",
                zIndex: 5,
                top: 0,
              }}
              py={2}
              pl={6}
              pr={2}
              mb={2}
              gap={1}
            >
              {headersElement
                ? headers.map((header) => (
                    <Grid item xs={true} key={header}>
                      {headersElement(header)}
                    </Grid>
                  ))
                : headers.map((header) => (
                    <Grid item xs={true} key={uuid4()}>
                      <Typography
                        fontSize={"14px"}
                        lineHeight={"16px"}
                        fontWeight={"500"}
                        textOverflow={"ellipsis"}
                        overflow={"hidden"}
                        whiteSpace={"nowrap"}
                      >
                        {header}
                      </Typography>
                    </Grid>
                  ))}
            </Grid>
          ) : null}
          <Grid container item flexDirection={"column-reverse"} gap={1}>
            {!isLoading ? (
              dataRow?.map((data: any) => {
                const RowDetail = () => rowDetail(data);
                const RowDetailExpand = () => rowDetailExpand(data);
                return (
                  <AccordionRow
                    key={uuid4()}
                    rowDetail={<RowDetail />}
                    rowDetailExpand={<RowDetailExpand />}
                    onExpand={onExpand}
                  />
                );
              })
            ) : (
              <AccordionTableSkeleton />
            )}
          </Grid>
        </Grid>
      </Grid>
      {pagination ? (
        <Box>
          <AccordionPagination
            pageSize={pagination.pageSize}
            pageNumber={pagination.pageNumber}
            totalRowCount={pagination.totalRowCount}
            handlePageChange={pagination.handlePageChange}
            onPageSizeChange={pagination.handlePageSizeChange}
          />
        </Box>
      ) : null}
    </Box>
  );
};

const AccordionPagination: React.FC<{
  pageSize: number;
  onPageSizeChange: (e: any) => void;
  handlePageChange: (page: number) => void;
  totalRowCount: number;
  pageNumber: number;
}> = ({
  pageSize,
  onPageSizeChange,
  handlePageChange,
  pageNumber,
  totalRowCount,
}) => {
  return (
    <div className="customize-data-grid-pagination">
      <label>Rows Per Page:</label>
      <Select
        labelId="select-standard-label"
        value={pageSize}
        label="pageSize"
        variant="filled"
        onChange={(e: any) => onPageSizeChange(e.target.value)}
      >
        {Array.from({ length: 10 }, (_, index) => (index + 1) * 10).map(
          (item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ),
        )}
      </Select>
      <Pagination
        count={Math.ceil(totalRowCount / pageSize)}
        page={pageNumber}
        onChange={(e: any, page: number) => handlePageChange(page)}
      />
    </div>
  );
};

export default AccordionTable;
