import { Grid, Typography } from "@mui/material";
import { memo, useEffect } from "react";
import { useGetJobStatsQuery } from "../../redux/Hire/jobRequestApi";
import { styles } from "./HireStyle";

const CandidateListStageHeader = ({
  stageCandidateList,
  selectedStage,
  jobId,
  initialStage,
}) => {
  const { data: stagesData, isSuccess } = useGetJobStatsQuery({
    jobRequestId: jobId,
  });

  useEffect(() => {
    if (isSuccess && stagesData && selectedStage === null) {
      if (initialStage) {
        const findStageFromList = stagesData.data.find(
          (el) => el.code?.toLowerCase() === initialStage,
        );
        stageCandidateList(
          findStageFromList ? findStageFromList : stagesData.data[0],
        );
      } else {
        stageCandidateList(stagesData.data[0]);
      }
    }
  }, [initialStage, isSuccess, selectedStage, stageCandidateList, stagesData]);

  const classes = styles();
  return (
    <Grid
      container
      p={2}
      bgcolor={"#fff"}
      boxShadow="0px 4px 4px 0px #0000000D"
    >
      <Grid container display={"flex"} textAlign={"center"} gap={1}>
        {stagesData?.data.map((stage, index) => {
          return (
            <Grid
              container
              item
              flexDirection={"column"}
              justifyContent={"space-around"}
              key={index}
              flex={1}
              className={
                selectedStage?.name === stage?.name
                  ? classes.candidate_Selected_stage
                  : classes.candidate_stage
              }
              onClick={() => stageCandidateList(stage)}
            >
              <Grid item>
                <Typography variant="body1">{stage?.count}</Typography>
              </Grid>
              <Grid>
                <Typography variant="caption">{stage?.name}</Typography>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default memo(CandidateListStageHeader);
