import DeleteOutlined from "@mui/icons-material/DeleteOutlined";
import { Avatar, Box, Button, Grid, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { useEffect } from "react";
import { useAlert } from "react-alert";
import { useMarkJobReqAsCancelledMutation } from "../../../redux/Hire/hireApi";
import FormikInput from "../../../shared/components/FormikInput";
import { userAccess } from "../../../utils/CommonFunction";
const DetailCancelRequest = ({ jobReqId }) => {
  const alert = useAlert();
  const [markJobReqCancelled, { isLoading, isError, isSuccess }] =
    useMarkJobReqAsCancelledMutation();
  useEffect(() => {
    if (isSuccess) alert.success("Job Request Cancelled");

    if (isError) alert.error("Error Cancelling Job Request");
  }, [isError, isSuccess]);

  return (
    <Box p={2}>
      <Box mb={2} sx={{ display: "flex", gap: 2, alignItems: "center" }}>
        <Avatar sx={{ bgcolor: "#d3362a" }}>
          <DeleteOutlined fontSize="medium" />
        </Avatar>
        <Typography>
          Are you sure you want to cancel the job request? If yes, please
          mention the reason.
        </Typography>
      </Box>
      <Formik
        initialValues={{
          cancelReason: "",
        }}
        onSubmit={(values) => {
          markJobReqCancelled({
            jobRequestId: jobReqId,
            uId: userAccess().userId,
          });
        }}
      >
        {(formikProps) => (
          <Form>
            <Grid container alignItems={"center"} spacing={2}>
              <Grid item xs={10}>
                <FormikInput
                  label="Reason"
                  name="cancelReason"
                  formikProps={formikProps}
                  options={undefined}
                  fullWidth
                />
              </Grid>
              <Grid item xs={2} alignSelf={"center"}>
                <Button
                  variant="contained"
                  sx={{ mb: 2 }}
                  type="submit"
                  disabled={isLoading}
                >
                  Cancel request
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
export default DetailCancelRequest;
