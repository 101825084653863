import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { City, Country, State } from "country-state-city";
import { Form, Formik, FormikHelpers } from "formik";
import {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useAlert } from "react-alert";
import * as yup from "yup";
import { useCreateDocumentMutation } from "../../redux/Documents/documentSlice";
import { useGetJobTitlesQuery } from "../../redux/Hire/hireApi";
import { useAddCandidateMutation } from "../../redux/Hire/jobRequestApi";
import FormikInput from "../../shared/components/FormikInput";
import { CloseIcon } from "../../shared/components/Icons";
import { userAccess } from "../../utils/CommonFunction";
import UploadResume from "./UploadResume";

type AddNewCandidateModalProps = {
  closeForm: () => void;
  jobId: string;
  clientName: string;
  jobTitle: string;
  workableInternalCode: string;
};

export type ClearInputHandle = {
  clearInput: () => void;
};

const initialValuesForm = {
  firstName: "",
  lastName: "",
  email: "",
  contactNumber: "",
  companyName: "",
  experienceLevel: "",
  employmentType: "",
  countryCode: "",
  regionCode: "",
  jobTitle: "",
  stage: "",
  status: "",
  country: "",
  state: "",
  expectedSalaryFrom: "",
  expectedSalaryTo: "",
};

const validationSchema = yup.object({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  email: yup.string().email().required("Email is required"),
  stage: yup.string().required("Interview Stage Required"),
  phone: yup
    .string()
    .matches(/^[0-9]+$/, "Must only contain number")
    .min(8, "Must be greater than 8 digits")
    .max(14, "Must be less than 14 digits")
    .required("Required"),
});

const AddNewCandidateModal: FC<AddNewCandidateModalProps> = ({
  closeForm,
  jobId,
  clientName,
  jobTitle,
  workableInternalCode,
}) => {
  const [addCandidateApi, addCandidateMutating] = useAddCandidateMutation();
  const [createDocumentApi] = useCreateDocumentMutation();

  const [textCv, setTextCv] = useState("");
  const [fileType, setFileType] = useState("");
  const [noFile, setNoFile] = useState(true);
  const alert = useAlert();

  const ref = useRef<ClearInputHandle>(null);

  const closeFormAndClearInput = useCallback(() => {
    closeForm();
    ref.current?.clearInput();
    setTextCv("");
  }, [closeForm]);

  const { data: jobTitleData } = useGetJobTitlesQuery(
    {
      titleSearch: "",
    },
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    },
  );

  const memoizedOptions = useMemo(() => {
    if (jobTitleData?.data) {
      return jobTitleData?.data.map((el) => ({
        label: el.jobTitle,
      }));
    }
    return [];
  }, [jobTitleData]);

  const createDocument = useCallback(
    (data) => {
      if (textCv) {
        createDocumentApi({
          fileType: fileType,
          ownerType: "Client_Admin",
          ownerId: "candidate",
          ownerClientId: userAccess().ClientId,
          createdBy: userAccess().userId,
          createdAt: new Date(),
          userId: "candidate",
          title: `Resume-${data.fullName}`,
          url: textCv.split("?")[0],
          type: "client",
          roleCanView:
            "TMF_Admin,Teamified_Admin,Employer,Employer_Hiring_Manager,Employer_Finance,Client_Admin,Patona_Admin",
          roleCanEdit: "TMF_Admin",
          roleCanDelete: "TMF_Admin,Patona_Admin,Teamified_Admin",
          userName: data.fullName,
        });
      }
    },
    [createDocumentApi, fileType, textCv],
  );

  useEffect(() => {
    if (addCandidateMutating.isSuccess) {
      alert.success("Candidate added successfully");
      closeFormAndClearInput();
    }
    if (addCandidateMutating.isError) {
      alert.error("Error while adding candidate");
    }
  }, [addCandidateMutating, alert, closeFormAndClearInput]);

  const setTextCVLink = useCallback((link, type) => {
    setTextCv(link);
    setFileType(type);
  }, []);

  useEffect(() => {
    setNoFile(textCv === "");
  }, [textCv]);

  const submitForm = useCallback(
    (data, formikHelper: FormikHelpers<any>) => {
      addCandidateApi({
        ...data,
        name: data.firstName + " " + data.lastName,
        fullname: data.firstName + " " + data.lastName,
        jobId,
        jobDepartment: clientName,
        jobTitle: jobTitle,
        jobShortCode: workableInternalCode,
        source: "Teamified",
        domain: "https://app.teamified.com.au",
        country: Country.getCountryByCode(data.countryCode)?.name,
        stage: data.stage,
        wResumeURL: textCv,
        wId: "NA",
        rId: "NA",
        wEventId: "NA",
        disqualified: false,

        candidateWorkExperiences: [
          {
            current: true,
            company: data.companyName,
            title: data.jobTitle,
            startDate: data.startDate,
            endDate: data.endDate,
          },
        ],
      });
      createDocument(data);
      formikHelper.resetForm();
    },

    [
      addCandidateApi,
      clientName,
      createDocument,
      jobId,
      jobTitle,
      textCv,
      workableInternalCode,
    ],
  );

  return (
    <Box>
      <Box
        sx={{
          backgroundColor: "#fff",
          display: "flex",
          px: 2,
          py: 1,
          justifyContent: "space-between",
        }}
      >
        <Typography fontSize={"18px"} lineHeight={"32px"} fontWeight={400}>
          Add new candidate
        </Typography>
        <IconButton
          aria-label="close"
          onClick={closeFormAndClearInput}
          size="small"
          sx={{
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Paper sx={{ padding: 4, mt: 4 }} elevation={1}>
        <Formik
          initialValues={initialValuesForm}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={submitForm}
        >
          {(formikProps) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Typography
                    fontSize={"18px"}
                    lineHeight={"32px"}
                    fontWeight={600}
                    mb={3}
                  >
                    Personal Information
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormikInput
                    label="First Name*"
                    name="firstName"
                    formikProps={formikProps}
                    options={undefined}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormikInput
                    label="Last Name*"
                    name="lastName"
                    formikProps={formikProps}
                    options={undefined}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormikInput
                    label="Email*"
                    name="email"
                    formikProps={formikProps}
                    options={undefined}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormikInput
                    label="Contact number*"
                    name="phone"
                    formikProps={formikProps}
                    options={undefined}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormikInput
                    label="Country"
                    name="countryCode"
                    className="common-select-dropdown"
                    formikProps={formikProps}
                    options={Country.getAllCountries().map(
                      ({ name, isoCode }) => ({ name, value: isoCode }),
                    )}
                    inputType="select"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormikInput
                    label="State"
                    name="region"
                    inputType="autocomplete"
                    formikProps={formikProps}
                    schemaChange
                    schemaOnChangeFun={(value) => {
                      formikProps.setFieldValue("region", value?.label || "");
                      formikProps.setFieldValue(
                        "regionCode",
                        value?.value || "",
                      );
                    }}
                    options={State.getStatesOfCountry(
                      formikProps.values.countryCode,
                    ).map(({ name, isoCode }) => ({
                      label: name,
                      value: isoCode,
                    }))}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormikInput
                    label="City"
                    name="city"
                    inputType="autocomplete"
                    formikProps={formikProps}
                    schemaChange
                    schemaOnChangeFun={(value) => {
                      formikProps.setFieldValue("city", value?.label || "");
                    }}
                    options={City.getCitiesOfState(
                      formikProps.values.countryCode,
                      formikProps.values.regionCode,
                    ).map(({ name }) => ({
                      label: name,
                    }))}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormikInput
                    label="Pincode"
                    name="zipCode"
                    formikProps={formikProps}
                    options={undefined}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <UploadResume
                    id={""}
                    setTextCVLink={setTextCVLink}
                    ref={ref}
                  ></UploadResume>
                  {noFile ? (
                    <Typography sx={{ color: "red", mt: 1, fontSize: "14px" }}>
                      File is required
                    </Typography>
                  ) : null}
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Typography
                    fontSize={"18px"}
                    lineHeight={"32px"}
                    fontWeight={500}
                    mt={4}
                  >
                    Candidate's current work information
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormikInput
                    label="Current company name*"
                    name="companyName"
                    formikProps={formikProps}
                    options={undefined}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormikInput
                    label="Job title"
                    name="jobTitle"
                    inputType="autocomplete"
                    formikProps={formikProps}
                    schemaChange
                    schemaOnChangeFun={(value) => {
                      formikProps.setFieldValue("jobTitle", value?.label || "");
                    }}
                    options={memoizedOptions}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormikInput
                    inputType="date-picker"
                    label="Start Date"
                    inputFormat="MM/DD/YYYY"
                    formikProps={formikProps}
                    name="startDate"
                    options={undefined}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormikInput
                    inputType="date-picker"
                    label="End Date"
                    inputFormat="MM/DD/YYYY"
                    formikProps={formikProps}
                    name="endDate"
                    options={undefined}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sx={{ mt: 2 }}>
                  <Typography
                    fontSize={"18px"}
                    lineHeight={"32px"}
                    fontWeight={500}
                  >
                    Add candidate at stage
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormikInput
                    label="Interview Stage*"
                    name="stage"
                    className="common-select-dropdown"
                    formikProps={formikProps}
                    options={[
                      {
                        name: "Screened by Talent Team",
                        value: "screeningByTalentTeam",
                      },
                      {
                        name: "Shortlist",
                        value: "shortlist",
                      },
                      {
                        name: "Client Endorsement",
                        value: "clientEndorsement",
                      },
                      {
                        name: "Client Interview",
                        value: "clientInterview",
                      },
                    ]}
                    inputType="select"
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Box
                display="flex"
                justifyContent="flex-end"
                sx={{ mt: 3, mb: 2 }}
              >
                <Button
                  variant="text"
                  sx={{ mt: 2 }}
                  disabled={addCandidateMutating.isLoading}
                  onClick={() => {
                    closeFormAndClearInput();
                    formikProps.resetForm();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  disabled={addCandidateMutating.isLoading || noFile}
                  variant="contained"
                  sx={{ mt: 2 }}
                >
                  {addCandidateMutating.isLoading ? (
                    <CircularProgress
                      color="inherit"
                      size={20}
                    ></CircularProgress>
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Paper>
    </Box>
  );
};

export default memo(AddNewCandidateModal);
