import { Button, TableCell, TableRow, Typography } from "@mui/material";
import moment from "moment";
import { FC, memo, useCallback } from "react";
import { GetAvailableTimeslotsData } from "../../redux/Hire/hireApi";

type Props = {
  slot: GetAvailableTimeslotsData;
  clicked: (slot: GetAvailableTimeslotsData) => void;
  disabled: boolean;
};

const headerStyle = {
  backgroundColor: "#fff",
  height: "72px",
  width: "120px",
  textAlign: "center",
  borderBottom: "none",
};

const TimeSlotRow: FC<Props> = ({ slot, clicked, disabled }) => {
  const onClick = useCallback(() => {
    clicked(slot);
  }, [clicked, slot]);
  return (
    <TableRow>
      <TableCell sx={headerStyle}>
        <Typography variant="body1">
          {moment.utc(slot.slotSetOn).local().format("dddd (DD-MM-YYYY)")}
        </Typography>
      </TableCell>
      <TableCell sx={headerStyle}>
        <Typography variant="body1">
          {moment.utc(slot.slotSetOn).local().format("hh:mm a")}
        </Typography>
      </TableCell>
      <TableCell sx={headerStyle}>
        <Typography variant="body1">
          {moment
            .utc(slot.slotSetOn)
            .local()
            .add({ minute: slot.durationInMin })
            .format("hh:mm a")}
        </Typography>
      </TableCell>
      <TableCell sx={headerStyle}>
        <Button variant="contained" onClick={onClick} disabled={disabled}>
          Book timeslot
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default memo(TimeSlotRow);
