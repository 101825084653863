export enum QuestionCategories  {
    TechnicalSkills = "Technical Skills",
    BehavioralSkills = "Behavioral Skills",
    LeadershipSkills = "Leadership Skills",
    CommunicationSkills = "Communication Skills"
}

export enum JobRequestDetailPages {
  ViewRequest = "View Request",
  DefaultInterViewQuestion = "Default Interview Questions",
  CancelRequest = "Cancel Request"
}

export type JobRequest = {
  jobId:string,
  companyId:string,
  jobTitle:string,
  status:string
  companyName:string,
  internalCode:string,
  countryCode?:string,
  firstPublishedAt?:string,
  shortCode?:string
  candidatesCount?:number,
  expectedJoiningDate?:string ,
  expectedSalary?:string,
  uId:string,
  experience?:string,
  isJobPosted:boolean,
  remoteWorking:boolean,
  stages:{
      name:string,
      code:string,
      count:number
    }[],
  candidates?:object[],
  createdAt:string,
}

export type InterviewQuestion = {
  interviewQuestionId:number,
  question:string,
  hint:string,
  jobRequestRefId:number
}