import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";

import { useGetInterviewQuery } from "../../../redux/Hire/interviewQuestionSlice";
import CandidateHeader from "../CandidateHeader";
import CandidateMoveDialog from "../CandidateMoveDialog";
import ClientSummary from "./ClientSummary";

const CandiateInterviewClientDetails = ({
  jobId,
  candidateId,
  selectedCandidate,
  candidateDetail,
  selectedStage,
  clientName,
  roleName,
  recruiterEmail,
  clientId,
  recruiterName,
  setSelectedStage,
}) => {
  const [pollingInterval, setPollingInterval] = useState<number | undefined>(
    5000,
  );

  const { data: interviewData } = useGetInterviewQuery(
    {
      candidateId,
      jobId: jobId,
    },
    {
      pollingInterval: pollingInterval,
      skip: !candidateId,
      refetchOnMountOrArgChange: true,
    },
  );

  useEffect(() => {
    setPollingInterval(5000);
  }, [jobId, candidateId]);

  return (
    <Grid item xs={12}>
      <CandidateHeader
        candidateDetail={candidateDetail}
        selectedCandidate={selectedCandidate}
        selectedStage={selectedStage}
        clientId={clientId}
        clientName={clientName}
        jobId={jobId}
        recruiterEmail={recruiterEmail}
        recruiterName={recruiterName}
        roleName={roleName}
        setSelectedStage={setSelectedStage}
        candidateId={candidateId}
        tabValue={2}
      >
        {selectedStage?.code === "clientInterview" &&
          interviewData?.draftSummary && (
            <CandidateMoveDialog
              candidateId={candidateId}
              jobId={jobId}
              bgColor="#FFFAEA"
              preText={
                "Read the summary before moving candidate to next stage or disqualifying."
              }
              text="Do you like the candidate and want to send him to offer stage?"
              selectedStage={selectedStage}
              setSelectedStage={setSelectedStage}
            />
          )}
        <ClientSummary
          interviewData={interviewData}
          candidateId={candidateId}
        />
      </CandidateHeader>
    </Grid>
  );
};

export default CandiateInterviewClientDetails;
