export const OPEN_MENU = [
  {
    id: "clone",
    name: "Clone Request",
  },
];

export const CLOSED_MENU = [
  {
    id: "clone",
    name: "Clone Request",
  },
];

export const TASK_MENU = [
  {
    id: "view",
    name: "View",
  },
  {
    id: "addComment",
    name: "Add Comment",
  },
  {
    id: "delete",
    name: "Delete",
  },
];

export const CONDITIONAL_TASK_MENU = [
  {
    id: "view",
    name: "View",
  },
  {
    id: "addComment",
    name: "Add Comment",
  },
];

export const getHeadersFromStage = [
  [
    "Request For",
    "Screened by TT",
    "Shortlist",
    "Client endorsement",
    "Client interview",
    "Offer",
    "Candidate started",
    "",
  ],
  [
    "Request For",
    "Screened by TT",
    "Shortlist",
    "Client endorsement",
    "Client interview",
    "Offer sent",
    "Offer accepted",
    "",
  ],
  [
    "Request For",
    "Screened by TT",
    "Shortlist",
    "Client endorsement",
    "Client interview",
    "Offer sent",
    "Offer accepted",
    "",
  ],
  [
    "Request For",
    "Reason of cancellation",
    "Client name",
    "Cancelled on",
    "Cancelled by",
    "Interview stage",
    "",
  ],
  ["Request For", "Experience", "Candidate name", "Completed date", ""],
];
