import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import { FC, useCallback, useEffect, useState } from "react";
import { useGetJobStatsQuery } from "../../redux/Hire/jobRequestApi";
import {
  useGetTaskForCandidateAtStageQuery,
  useUpdateTaskMutation,
} from "../../redux/Task/TaskApi";
import FormikInput from "../../shared/components/FormikInput";
import { userAccess } from "../../utils/CommonFunction";

type Props = {
  candidateId: string;
  jobId: string;
  bgColor?: string;
  text?: string;
  selectedStage: any;
  setSelectedStage: (stage: any) => void;
  preText?: string;
};

const initialValues = {
  status: "like",
  reason: "",
};

const CandidateMoveDialog: FC<Props> = ({
  candidateId,
  jobId,
  bgColor = "#fff",
  text = "Would you like to interview this candidate?",
  selectedStage,
  setSelectedStage,
  preText,
}) => {
  const [pollingInterval, setPollingInterval] = useState<number>(5000);
  const { data: taskData } = useGetTaskForCandidateAtStageQuery(
    {
      CandidateId: candidateId,
      JobId: jobId,
      StageCode:
        selectedStage?.code === "shortlist"
          ? "internalInterview"
          : selectedStage?.code,
    },
    {
      skip: !candidateId || !jobId || !selectedStage,
      pollingInterval: pollingInterval,
    },
  );

  const { data: stagesData, isSuccess } = useGetJobStatsQuery({
    jobRequestId: parseInt(jobId),
  });

  useEffect(() => {
    if (taskData?.data.result) {
      setPollingInterval(0);
    }
  }, [taskData]);

  useEffect(() => {
    setPollingInterval(5000);
  }, [candidateId, jobId, selectedStage]);

  const [updateTask, updateTaskMutating] = useUpdateTaskMutation();

  const setStatus = useCallback(
    (formikProps, value) => () => {
      formikProps.setFieldValue("status", value);
    },
    [],
  );

  const submitForm = useCallback(
    (data) => {
      if (data.status === "like" && taskData?.data.result) {
        updateTask({
          ...taskData?.data.result,
          completedAction: JSON.parse(taskData.data.result.actionsJson)[0].id,
          id: taskData.data.result.taskId,
          completedBy: userAccess().userId,
          completedByName: userAccess().firstName + " " + userAccess().lastName,
        });
      }
      if (data.status === "unlike" && taskData?.data.result) {
        updateTask({
          ...taskData?.data.result,
          id: taskData.data.result.taskId,
          completedAction: JSON.parse(taskData?.data.result.actionsJson)[1].id,
          completedBy: userAccess().userId,
          completedByName: userAccess().firstName + " " + userAccess().lastName,
        });
      }
    },
    [taskData, updateTask],
  );

  useEffect(() => {
    if (
      updateTaskMutating.isSuccess &&
      updateTaskMutating.originalArgs?.completedAction === "accept" &&
      isSuccess
    ) {
      if (selectedStage.code === "shortlist") {
        setSelectedStage(
          stagesData.data.find((el) => el.code === "clientEndorsement"),
        );
      }
      if (selectedStage.code === "clientEndorsement") {
        setSelectedStage(
          stagesData.data.find((el) => el.code === "clientInterview"),
        );
      }
      if (selectedStage.code === "clientInterview") {
        setSelectedStage(stagesData.data.find((el) => el.code === "offer"));
      }
    }
  }, [
    isSuccess,
    selectedStage,
    setSelectedStage,
    stagesData,
    updateTaskMutating,
  ]);

  if (taskData?.data?.result?.completedAction === null)
    return (
      <Formik initialValues={initialValues} onSubmit={submitForm}>
        {(formikProps) => (
          <Form>
            <Grid container flexDirection={"column"} gap={1} my={2}>
              {preText && (
                <Grid bgcolor={"#FFDE80"} px={2}>
                  <Typography
                    letterSpacing={"0px"}
                    fontSize={"18px"}
                    lineHeight={"32px"}
                    fontWeight={"500"}
                  >
                    {preText}
                  </Typography>
                </Grid>
              )}
              <Grid
                container
                item
                bgcolor={bgColor}
                p={2}
                gap={2}
                borderRadius={"10px"}
              >
                <Grid
                  container
                  item
                  justifyContent={"space-between"}
                  wrap="nowrap"
                  alignItems={"center"}
                  xs={12}
                >
                  <Grid item flex={1}>
                    <Typography
                      fontSize={"16px"}
                      lineHeight={"32px"}
                      fontWeight={"600"}
                    >
                      {text}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    gap={1}
                    wrap="nowrap"
                    justifyContent={"flex-end"}
                    flex={1}
                  >
                    <Grid
                      item
                      border={
                        formikProps.values.status === "like"
                          ? "1px solid #A16AE8"
                          : "none"
                      }
                      borderRadius={"50%"}
                    >
                      <Grid
                        bgcolor={"#00e08c"}
                        borderRadius={"50%"}
                        m={0.5}
                        color={"#fff"}
                      >
                        <IconButton
                          className="like-button"
                          onClick={setStatus(formikProps, "like")}
                          size="large"
                          color="inherit"
                        >
                          <ThumbUpOffAltIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      border={
                        formikProps.values.status === "unlike"
                          ? "1px solid #A16AE8"
                          : "none"
                      }
                      borderRadius={"50%"}
                    >
                      <Grid
                        bgcolor={"#e61a1a"}
                        borderRadius={"50%"}
                        m={0.5}
                        color={"#fff"}
                      >
                        <IconButton
                          color="inherit"
                          className="unlike-button"
                          onClick={setStatus(formikProps, "unlike")}
                          size="large"
                        >
                          <ThumbDownOffAltIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  gap={2}
                >
                  <Grid item flex={1}>
                    {formikProps.values.status === "unlike" && (
                      <FormikInput
                        label="If rejected, mention the reason"
                        name="reason"
                        inputType="select"
                        className="common-select-dropdown"
                        options={[
                          {
                            name: "Poor verbal communication skills",
                            value: "Poor verbal communication skills",
                          },
                          {
                            name: "Unable to respond to questions clearly and concisely",
                            value:
                              "Unable to respond to questions clearly and concisely",
                          },
                          {
                            name: "Less enthusiasm for the role or industry",
                            value: "Less enthusiasm for the role or industry",
                          },
                          {
                            name: "Work style don't align with the company's culture",
                            value:
                              "Work style don't align with the company's culture",
                          },
                          {
                            name: "Salary expectations are too high",
                            value: "Salary expectations are too high",
                          },
                          {
                            name: "Lacks in technical skills",
                            value: "Lacks in technical skills",
                          },
                        ]}
                        formikProps={formikProps}
                        fullWidth
                      />
                    )}
                  </Grid>
                  <Grid item marginBottom={2}>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={updateTaskMutating.isLoading}
                    >
                      {updateTaskMutating.isLoading ? (
                        <CircularProgress size={20} />
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    );
  return null;
};

export default CandidateMoveDialog;
