import { all, put, takeLatest } from "redux-saga/effects";
import { Status } from "../../services/ApiStatus";
import { generateChatGptJobDescription } from "../../services/Hire";
import { sagaActions } from "../sagaActions";
import {
  chatGptJobError,
  chatGptJobPending,
  chatGptJobSuccess,
} from "./hireSlice";

function* generateChatGptDescription(payload) {
  try {
    yield put(chatGptJobPending());
    const response = yield generateChatGptJobDescription(payload.payload);
    if (!!response && response?.status === Status.Success) {
      yield put(chatGptJobSuccess(response?.data));
    } else {
      if (!!response && response?.code !== Status.Unauthorized) {
        yield put(chatGptJobError());
      }
    }
  } catch (error: any) {
    yield put(chatGptJobError());
  }
}

export default function* watcherSaga() {
  yield all([
    yield takeLatest(
      sagaActions.CHAT_GPT_DESCRIPTION,
      generateChatGptDescription,
    ),
  ]);
}
