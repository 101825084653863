import { createApi } from "@reduxjs/toolkit/dist/query/react";
import axiosBaseQuery from "../../services/service";
const BASE_URL = process.env.REACT_APP_BASE_API_URL;

export enum Round {
  Round1 = 1,
  Round2 = 2,
}

export type AddOrUpdateMeetingRequest = {
  id?: number;
  title: string;
  description: string;
  meetingStartDate: string;
  candidateEmail: string;
  link?: string;
  meetingEndDate: string;
  role: string;
  attendeesEmail: string[];
  clientId: string;
  userId: string;
  candidateId: string;
  eventId?: string;
  updatedBy?: string;
  interviewerName: string;
  interviewerEmail: string;
  recruiterEmail: string;
  recruiterName: string;
  employerEmail?: string;
  employerName?: string;
  clientName: string;
  candidateName: string;
  jobId: string;
  scheduledBy: string;
  slotId?: string;
  accessToken?: string | null;
  round: Round;
};
type AddMeetingResponse = {};

export type EventObject = {
  id: number;
  title: string;
  description: string;
  meetingStartedOn: string;
  meetingEndedOn: string;
  role: string;
  attendeesEmail: string;
  link: string;
  deletedAt: string;
  deletedBy: string;
  deletedReason: string;
  clientId: string;
  userId: string;
  eventId?: string;
  updatedBy?: string;
  interviewerName: string;
  interviewerEmail: string;
  employerEmail?: string;
  employerName?: string;
  clientName: string;
  candidateName: string;
  jobId: string;
  scheduledBy: string;
  round: Round;
};

type GetMeetingsRequest = {
  userId?: string;
  candidateId?: string;
  candidateStage?: string;
  clientId?: string;
  round?: Round;
  startDate?: string;
  endDate?: string;
};
type GetMeetingsResponse = {
  data: Array<EventObject>;
  message: string;
  status: number;
  success: boolean;
};

type DeleteMeetingRequest = {
  interviewId: number;
  eventId: string;
  deletedReason: string;
  deletedAt: string;
  deletedBy: string;
  round: Round;
  candidateId: string;
};

type ReserveCandidateSlotRequest = {
  slotId: number;
  candidateId: string;
  interviewerId: string;
};
type ReserveCandidateSlotResponse = {
  data: any;
  message: string;
  success: boolean;
  status: number;
};

type DeleteMeetingResponse = any;

export const candidateMeetingApi = createApi({
  reducerPath: "candidateMeetings",
  baseQuery: axiosBaseQuery({
    baseUrl: BASE_URL || "",
  }),
  tagTypes: ["interviewList"],
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    addInterviewMeeting: builder.mutation<
      AddMeetingResponse,
      AddOrUpdateMeetingRequest
    >({
      query: (req) => ({
        method: "POST",
        url: "addInterviewMeeting",
        body: req,
      }),
      invalidatesTags: ["interviewList"],
    }),
    addInterviewMeetingAuth: builder.mutation<
      AddMeetingResponse,
      AddOrUpdateMeetingRequest
    >({
      query: (req) => {
        const accessToken = req.accessToken;
        delete req.accessToken;
        return {
          method: "POST",
          url: "addInterviewMeetingCandidate",
          body: req,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["interviewList"],
    }),

    getAllMeetings: builder.query<GetMeetingsResponse, GetMeetingsRequest>({
      query: (req) => ({
        method: "POST",
        url: "getAllMeetings",
        body: req,
      }),

      providesTags: ["interviewList"],
    }),

    getAllMeetingsForCandidate: builder.query<
      GetMeetingsResponse,
      { request: GetMeetingsRequest; token: string }
    >({
      query: ({ request, token }) => ({
        method: "POST",
        url: "getAllMeetings",
        body: request,
        token,
      }),
      providesTags: ["interviewList"],
    }),
    updateInterviewMeeting: builder.mutation<
      AddMeetingResponse,
      AddOrUpdateMeetingRequest
    >({
      query: (req) => ({
        method: "POST",
        url: "updateInterviewMeeting",
        body: req,
      }),
      invalidatesTags: ["interviewList"],
    }),
    deleteInterviewMeeting: builder.mutation<
      DeleteMeetingResponse,
      DeleteMeetingRequest
    >({
      query: (req) => ({
        method: "POST",
        url: "deleteInterview",
        body: req,
      }),
      invalidatesTags: ["interviewList"],
    }),
    reserveCandidateSlots: builder.mutation<
      ReserveCandidateSlotResponse,
      ReserveCandidateSlotRequest
    >({
      query: (req) => ({
        url: "reserveCandidateSlots",
        method: "POST",
        body: req,
      }),
    }),
  }),
});

export const {
  useAddInterviewMeetingMutation,
  useAddInterviewMeetingAuthMutation,
  useGetAllMeetingsQuery,
  useGetAllMeetingsForCandidateQuery,
  useUpdateInterviewMeetingMutation,
  useDeleteInterviewMeetingMutation,
  useReserveCandidateSlotsMutation,
} = candidateMeetingApi;
