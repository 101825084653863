import { Box, Button, Grid, Typography } from "@mui/material";
import moment from "moment";
import { FC, memo, useCallback, useState } from "react";
import {
  Round,
  useGetAllMeetingsQuery,
} from "../../../redux/Hire/candidateMeetingApi";
import { InterviewResponse } from "../../../redux/Hire/interviewQuestionSlice";

type Props = {
  interviewData?: InterviewResponse;
  candidateId: string;
};

const ClientSummary: FC<Props> = ({ interviewData, candidateId }) => {
  const { data } = useGetAllMeetingsQuery({
    candidateId,
    round: Round.Round2,
  });

  const [showOverview, setShowOverview] = useState(true);

  const meetingData = interviewData?.meetings?.find(
    (el) => el?.link === data?.data[0]?.link,
  );

  const showOverviewView = useCallback(() => {
    setShowOverview(true);
  }, []);

  const showTranscriptView = useCallback(() => {
    setShowOverview(false);
  }, []);

  return (
    <>
      <Box className="interview-list-page-sub-header">
        <h3>Summary of the interview</h3>
      </Box>

      {meetingData?.firefliesSummaryStatus === "COMPLETED" ? (
        <Box className="summary-candiate-box" flexDirection={"column"} gap={1}>
          <Grid>
            <Button
              variant="text"
              onClick={showOverviewView}
              disabled={showOverview}
            >
              Overview
            </Button>
            <Button
              variant="text"
              onClick={showTranscriptView}
              disabled={!showOverview}
            >
              Transcript
            </Button>
          </Grid>
          <Grid
            direction={"column"}
            container
            justifyContent={"center"}
            alignItems={"center"}
          >
            {showOverview ? (
              <Typography variant="h3" whiteSpace="pre-line">
                {meetingData?.summaryGenerated}
              </Typography>
            ) : (
              <Grid
                container
                direction={"column"}
                maxHeight={"250px"}
                overflow={"auto"}
                wrap="nowrap"
                gap={2}
              >
                {meetingData?.transcript.map((t) => (
                  <Grid>
                    <Typography
                      variant="body1"
                      whiteSpace="pre-line"
                      fontWeight={"500"}
                      fontSize={"14px"}
                    >
                      {t.speaker}
                    </Typography>
                    <Typography variant="body2" whiteSpace="pre-line">
                      {t.text}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
        </Box>
      ) : (
        <Box className="summary-candiate-box" flexDirection={"column"} gap={1}>
          <Grid
            direction={"column"}
            container
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography variant="h3" whiteSpace="pre-line">
              You will receive a summary after a call with the candidate
            </Typography>

            <Typography
              variant="h3"
              whiteSpace="pre-line"
              color={"#00E08C"}
              fontSize={"14px"}
              lineHeight={"32px"}
              fontWeight={"500"}
            >
              {data?.data.length === 0 &&
                "Please schedule a call with candidate"}
              {(data?.data.length || 0) > 0 &&
                `The interview call is schedule at ${moment.utc(data?.data[0].meetingStartedOn).local().format("ddd Do yy | hh:mm a")} `}
            </Typography>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default memo(ClientSummary);
