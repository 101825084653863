import { Box, Grid, Typography } from "@mui/material";
import Moment from "moment";
import { useGetJobRequestByIdQuery } from "../../../redux/Hire/jobRequestApi";
import { JobReqDetailSkeleton } from "./JobReqSkeleton";

const SummaryItem = ({ label, value }) => {
  return (
    <>
      <Grid item xs={6}>
        <Typography variant="body1">{label}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body1">{value}</Typography>
      </Grid>
    </>
  );
};

const DetailRequestPage: React.FC<{
  jobId: number;
}> = ({ jobId }) => {
  const { data, isSuccess } = useGetJobRequestByIdQuery({
    jobRequestId: jobId,
  });
  if (!isSuccess)
    return (
      <Box
        sx={{
          px: 4,
          py: 2,
        }}
      >
        <JobReqDetailSkeleton />
      </Box>
    );

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        gap: 1,
      }}
    >
      <Box sx={{ minWidth: "35%" }}>
        <Box
          sx={{
            backgroundColor: "#faf9f9",
            p: 2,
          }}
        >
          <Typography variant="body1" fontWeight={"bold"}>
            Request Summary
          </Typography>
        </Box>
        <Grid container rowGap={2} p={2}>
          <SummaryItem label="Client" value={data.data.client} />
          <SummaryItem label="Request raised by" value={data.data.client} />
          <SummaryItem
            label="Created"
            value={
              data.data.createdDate
                ? Moment(data.data?.createdDate).format("DD-MM-YYYY")
                : ""
            }
          />
          <SummaryItem label="Job Title" value={data.data?.title} />
          <SummaryItem
            label="Experience Client"
            value={data.data?.experienceLevel}
          />
          <SummaryItem label="Employment type" value={data.data.employeeType} />
          <SummaryItem label="Exp. Salary" value={data.data?.expectedSalary} />
          <SummaryItem
            label="Exp. Joining date"
            value={
              data.data?.expectedJoiningDate
                ? Moment(data.data?.expectedJoiningDate).format("DD-MM-YYYY")
                : ""
            }
          />
          <SummaryItem label="Location" value={data.data?.location} />
          <SummaryItem label="Attachment" value="" />
          <SummaryItem label="Owner" value={data.data.recruiter} />
        </Grid>
      </Box>
      <Box sx={{ minWidth: "65%" }}>
        <Box
          sx={{
            backgroundColor: "#faf9f9",
            p: 2,
          }}
        >
          <Typography fontWeight={"bold"} variant="body1">
            About the role
          </Typography>
        </Box>
        <Box p={2}>
          {
            <div
              dangerouslySetInnerHTML={{ __html: data.data.aboutTheRole }}
            ></div>
          }
        </Box>
      </Box>
    </Box>
  );
};

export default DetailRequestPage;
