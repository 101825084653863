import moment from "moment";
import { teamMember } from "../Constants";
import { store } from "../redux";

export enum AdminRole {
  ClientUser = "Client_User",
  ClientAdmin = "Client_Admin",
  TMFAdmin = "TMF_Admin",
  PatonaAdmin = "Patona_Admin",
  TeamMember = "Team_Member",
}

export const EmployerRoles = [
  "Employer",
  "Employer_Hiring_Manager",
  "Employer_Leave_Approver",
  "Employer_Finance",
  "Employer_Performance_Manager",
  "Client_Admin",
];
export const TeamifiedRoles = [
  "Teamified",
  "Teamified_Developer",
  "Teamified_Admin",
  "TMF_Admin",
  "Patona_Admin",
];
export const AdminRoleArray = [
  { name: "Super Admin", id: "TMF_Admin" },
  { name: "Client Admin", id: "Client_Admin" },
  // { name: "Client User", id: "Client_User" },
];

export const SalaryIncreaseTypes = [
  { name: "Fixed amount", value: "1" },
  { name: "Percentage", value: "2" },
  { name: "No raise", value: "3" },
];
export const SalaryIncreaseNominationTypes = [
  { name: "Monthly", value: "1" },
  { name: "Annually", value: "2" },
];

export const MonthsArray = [
  { name: "January", value: "1" },
  { name: "February", value: "2" },
  { name: "March", value: "3" },
  { name: "April", value: "4" },
  { name: "May", value: "5" },
  { name: "June", value: "6" },
  { name: "July", value: "7" },
  { name: "August", value: "8" },
  { name: "September", value: "9" },
  { name: "October", value: "10" },
  { name: "November", value: "11" },
  { name: "December", value: "12" },
];

export const userAccess = (ClientId = 0) => {
  const { userInfo } = store.getState().login;
  const { clientCode, clientName, role } = store.getState().client;
  // @ts-ignore
  const {
    // @ts-ignore
    companyId = 0,
    // @ts-ignore
    userId,
    // @ts-ignore
    email,
    // @ts-ignore
    companyName,
    // @ts-ignore
    firstName,
    // @ts-ignore
    lastName,
    // @ts-ignore
    companyLogoImageURL = "",
    // @ts-ignore
    zohoId,
    // @ts-ignore
    empId,
  } = userInfo || {};
  let request = {
    searchDisable: false,
    clientFilterDisable: false,
    AddDisable: false,
    updateDisable: false,
    deleteDisable: false,
    ClientId: ClientId > 0 ? ClientId : 0,
    client: false,
    userId: userId,
    companyId: companyId,
    // @ts-ignore
    role: userInfo.role,
    email: email,
    companyName: companyName,
    clientCode: clientCode,
    clientName: clientName,
    firstName,
    lastName,
    logo: companyLogoImageURL,
    isTeamMember: false,
    zohoId: zohoId,
    empId: empId,
  };
  if (!!role && role !== AdminRole.TMFAdmin && role !== AdminRole.PatonaAdmin) {
    request.ClientId = ClientId > 0 ? ClientId : companyId;
    request.clientFilterDisable = true;
    request.AddDisable = true;
    request.updateDisable = true;
    request.deleteDisable = true;
    request.client = true;
  }
  // @ts-ignore
  if (userInfo.role === teamMember) {
    request.isTeamMember = true;
  }
  return request;
};

export const daysAgoFromDate = (fromDate) => {
  var date1 = new Date();
  var date2 = new Date(fromDate);
  //@ts-ignore
  var diffDays = parseInt((date1 - date2) / (1000 * 60 * 60 * 24), 10);
  if (diffDays === 0) {
    return "today";
  } else if (diffDays === 1) {
    return `Due ${diffDays} day ago`;
  } else if (diffDays > 1) {
    return `Due ${diffDays} days ago`;
  } else {
    return moment(fromDate).format("DD-MM-YYYY");
  }
  //
};

export const getCurrentFiscalYear = () => {
  var today = new Date();
  var curMonth = today.getMonth();
  let lsDate = moment(today).format("YYYY-MM-DD");
  let sDate = `${(today.getFullYear() - 1).toString()}-${curMonth + 1}-01`;

  var fiscalYr: any = {};
  fiscalYr = {
    startDate: sDate,
    endDate: lsDate,
  };
  return fiscalYr;
};

export const capitalize = (string) => {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1)?.toLowerCase();
};

export const getCurrentFiscalYear2 = () => {
  var today = new Date();
  var curMonth = today.getMonth();

  var fiscalYr: any = {};
  if (curMonth > 5) {
    var nextYr1 = (today.getFullYear() + 1).toString();
    fiscalYr = {
      startDate: `${today.getFullYear().toString()}-07-01`,
      endDate: `${nextYr1}-06-30`,
    };
  } else {
    var nextYr2 = today.getFullYear().toString();
    fiscalYr = {
      startDate: `${(today.getFullYear() - 1).toString()}-07-01`,
      endDate: `${nextYr2}-06-30`,
    };
  }
  return fiscalYr;
};

export const containsDeviceId = (value) => {
  if (
    userAccess().client &&
    (/@patona.com.au\s*$/.test(value) || /@patona.com\s*$/.test(value))
  ) {
    return false;
  }
  return true;
};

export const getWeekStartAndEndDate = (date: Date) => {
  var startOfWeek = moment(date).startOf("week").toDate();
  var endOfWeek = moment(date).endOf("week").toDate();
  var firstday = new Date(startOfWeek).toUTCString();
  var lastday = new Date(endOfWeek).toUTCString();
  return { start: firstday, end: lastday };
};

const invalidRedirects = ["/logout"];
export const isValidRedirect = (path: string) => {
  // Check if path is in the list of invalid redirects
  if (invalidRedirects.includes(path)) {
    return false;
  }
  return true;
};

export const truncate = (str, n) => {
  return str.length > n ? str.slice(0, n - 1) + "..." : str;
};
