import { Grid, Typography } from "@mui/material";
import moment from "moment";

type Props = {
  date: Date;
  label: string;
};

const Day = (props: Props) => {
  return (
    <Grid container direction={"column"} gap={0.5}>
      <Typography variant="body1" fontWeight={"500"}>
        {moment(props.date).format("ddd")}
      </Typography>
      <Typography
        fontSize={"24px"}
        lineHeight={"20px"}
        fontWeight={"700"}
        sx={{
          backgroundColor: "#F0E9FC",
          borderRadius: "50%",
          padding: "10px",
        }}
      >
        {moment(props.date).format("DD")}
      </Typography>
    </Grid>
  );
};

export default Day;
