import { Grid } from "@mui/material";
import { memo } from "react";
import CandidateExpeience from "./CandidateExpeience";
import CandidateHeader from "./CandidateHeader";
import CandidateResume from "./CandidateResume";
import ViewCandidateWorkExperience from "./ViewCandidateWorkExperience";

const CandidateInfo = ({
  candidateId,
  candidateDetail,
  selectedStage,
  setSelectedStage,
  selectedCandidate,
  candidateDetailPending,
  clientName,
  jobId,
  roleName,
  recruiterEmail,
  recruiterName,
  cvUrl,
  clientId,
}) => {
  const candidateDetails = (candidate) => {
    return {
      fileName: "Download Resume",
      file: candidate?.resumeURL,
      joiningDate: candidate?.expectedJoiningDate,
      salaryPA: candidate?.salary,
      experience: candidate?.experienceLevel,
      jobTitle: candidate?.jobTitle,
    };
  };

  return (
    <Grid container item xs={12} flexDirection={"column"}>
      <CandidateHeader
        candidateId={candidateId}
        candidateDetail={candidateDetail}
        selectedCandidate={selectedCandidate}
        selectedStage={selectedStage}
        clientId={clientId}
        clientName={clientName}
        jobId={jobId}
        recruiterEmail={recruiterEmail}
        recruiterName={recruiterName}
        roleName={roleName}
        tabValue={0}
        setSelectedStage={setSelectedStage}
      >
        <ViewCandidateWorkExperience
          headerColor={"#ffffff"}
          details={candidateDetails(candidateDetail)}
          viewSkills={candidateDetail?.skills?.join(", ")}
          isMemLoading={candidateDetailPending}
        />

        <CandidateExpeience
          headerColor={"#ffffff"}
          details={candidateDetails(candidateDetail)}
          isMemLoading={candidateDetailPending}
          experience={candidateDetail?.candidateWorkExperiences}
        />

        {candidateDetail?.wResumeURL && cvUrl && (
          <CandidateResume
            headerColor={"#fff"}
            url={
              candidateDetail &&
              candidateDetail.wResumeURL &&
              candidateDetail.wResumeURL.includes("workable")
                ? candidateDetail.wResumeURL
                : cvUrl
            }
          />
        )}
      </CandidateHeader>
    </Grid>
  );
};

export default memo(CandidateInfo);
