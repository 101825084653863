import { Box } from "@mui/material";
import { JobRequest } from "../../../redux/Hire/jobRequestApi";
import DetailCancelRequest from "./DetailCancelRequestPage";
import DetailInterviewQuestionPage from "./DetailQuestionPage";
import DetailRequestPage from "./DetailRequestPage";
import { JobRequestDetailPages } from "./types";

const DetailBody: React.FC<{
  currentPage: JobRequestDetailPages;
  jobRequest: JobRequest;
}> = ({ currentPage, jobRequest }) => {
  let activePage;

  switch (currentPage) {
    case JobRequestDetailPages.ViewRequest:
      activePage = <DetailRequestPage jobId={jobRequest.jobRequestId} />;
      break;
    case JobRequestDetailPages.DefaultInterViewQuestion:
      activePage = (
        <DetailInterviewQuestionPage
          internalCode={jobRequest.workableInternalCode}
        />
      );
      break;
    case JobRequestDetailPages.CancelRequest:
      activePage = <DetailCancelRequest jobReqId={jobRequest.jobRequestId} />;
      break;
    default:
      activePage = <Box>Page does not exist</Box>;
  }

  return activePage;
};

export default DetailBody;
